import parseJwt from "../../../common/lib/parse-jwt";

export default class LoginData {
  constructor(data) {
    this._accessToken = data.accessToken;
    this._parsedJwt = parseJwt(data.accessToken);
  }

  get accessToken() {
    return this._accessToken;
  }

  get user() {
    const { uid, firstName, lastName, email, role } = this._parsedJwt;
    return {
      uid,
      firstName,
      lastName,
      email,
      role,
    };
  }
}
