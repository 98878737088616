import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import FormGroup from '../../../ui/FormGroup';
import Label from '../../../ui/Label';
import FormikErrorMessage from '../../../ui/FormikErrorMessage';
import SubmitButton from '../../../ui/SubmitButton';
import FormikDatetimePicker from '../../../ui/FormikDatetimePicker';
import FormDialog from '../../../ui/FormDialog';
import { submitOnce } from '../../../common/lib/formik-helpers';
import SectionLoader from '../../../ui/SectionLoader';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const schema = yup.object({
  activationDate: yup.date().required('Please enter an activation date'),
  expirationDate: yup.date().required('Please enter an expiration date')
    .test('activation date', 'subscription expiration date cannot be before the activation date', function (value) {
      return this.parent.activationDate.getTime() < value.getTime();
    }),
});

const UpdateSubscriptionDialog = ({ processing, subscription, updateSubscription, cancel }) => {
  const onSubmit = React.useCallback(submitOnce(updateSubscription), [updateSubscription]);

  return (
    <Formik
      validationSchema={schema}
      initialValues={{
        activationDate: subscription.activationDate,
        expirationDate: subscription?.expirationDate || new Date(),
      }}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {
        ({ handleSubmit }) => (
          <FormDialog
            open
            title="Edit Subscription"
            closeDialog={cancel}
            buttons={[
              { type: 'ok', text: 'confirm', onClick: handleSubmit },
              { type: 'cancel', text: 'cancel' },
            ]}
          >
            { processing && <SectionLoader /> }
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label htmlFor="expirationDate">
                  Expiration Date
                </Label>
                <FormikDatetimePicker name="expirationDate" />
                <FormikErrorMessage name="expirationDate" />
              </FormGroup>
            </Form>
          </FormDialog>
        )
      }
    </Formik>
  );
};

export default UpdateSubscriptionDialog;
