export const submitOnce = (submit) => {
  let submitting = false;
  return async (values, form) => {

    if (!submitting) {
      submitting = true;
      try {
        return await submit(values, form);
      } finally {
        submitting = false;
      }
    }
  };
};
