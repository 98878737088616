import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NotificationManager } from 'react-notifications';
import { apiGetSubscriberSpacesDetails } from '../api';
import { Table, TableFooter } from '../../../ui/Table';

const Total = styled.div`
font-family: "Open Sans", sans-serif;
`;

const SpacesDetails = ({ subscriberId }) => {

    const [
        spaces,
        setSpaces
    ] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const spaces = await apiGetSubscriberSpacesDetails(subscriberId);

                setSpaces(spaces);
            } catch (err) {
                NotificationManager.error('Failed to load spaces. Please refresh or try again later');
            }
        };

        fetchData();
    }, [subscriberId]);

    return (
        <>
            {
                <>
                    <Table isEmpty={spaces.length === 0} isEmptyComponent="There are no spaces for this subscriber">
                        <thead>
                            <tr>
                                <th className="text-cell">Name</th>
                                <th className="number-cell">Activities</th>
                                <th className="number-cell">Workflows</th>
                                <th className="number-cell">Actions</th>
                                <th className="number-cell">States</th>
                            </tr>
                        </thead>
                        <tbody>
                            {spaces.map(space => <tr>
                                <td className="text-cell">{space.spaceName}</td>
                                <td className="number-cell">{space.activities}</td>
                                <td className="number-cell">{space.workflows}</td>
                                <td className="number-cell">{space.actions}</td>
                                <td className="number-cell">{space.states}</td>
                            </tr>)}
                        </tbody>
                    </Table>
                    <TableFooter>
                        <Total>{spaces.length} space(s)</Total>
                    </TableFooter>
                </>
            }
        </>
    );
};

export default SpacesDetails;
