import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import UsersList from '../components/UsersList';
import { usePaginate } from '../../../common/hooks/use-paginate';
import { NotificationManager } from 'react-notifications';
import { apiGetSubscriberUsers } from '../../SubscriptionPlansList/api';

const UsersListContainer = () => {
  const [
    currentPage,
    currentItemsPerPage,
    goToPage
  ] = usePaginate();

  const [
    users,
    setUsers
  ] = useState([]);

  const [
    total,
    setTotal
  ] = useState(0);

  const params = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { users, total } = await apiGetSubscriberUsers(params.subscriberId, {
          page: currentPage,
          itemsPerPage: currentItemsPerPage
        });

        setUsers(users);
        setTotal(total);
      } catch (err) {
        NotificationManager.error('Failed to load users. Please refresh or try again later');
      }
    };

    fetchData();
  }, [currentPage, currentItemsPerPage, params.subscriberId]);

  return (
    <UsersList
      users={users}
      total={total}
      currentItemsPerPage={currentItemsPerPage}
      currentPage={currentPage}
      goToPage={goToPage}
      subscriberId={params.subscriberId}
    />
  );
};

export default UsersListContainer;
