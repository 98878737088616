import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import RemoveSuperUser from '../components/RemoveSuperUser';
import { NotificationManager } from 'react-notifications';
import { apiGetSuperUserById, apiRemoveSuperUserById } from '../api';

const RemoveSuperUserContainer = () => {
  const [superUser, setSuperUser] = React.useState(null);
  const params = useParams();
  const history = useHistory();

  const cancel = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const removeSuperUser = React.useCallback(async () => {
    try {
      await apiRemoveSuperUserById(params.id);
      NotificationManager.success(`${superUser.email} has been successfully removed`);
      cancel();
    } catch (err) {
      if (err.status === 404 && err.data.name === 'OBJECT_NOT_FOUND_EXCEPTION') {
        NotificationManager.error(`${superUser.email} no longer exists. Please refresh the page`);
      } else {
        NotificationManager.error(`Failed to remove super user. Please refresh or try again later`);
      }
    }
  }, [params.id, superUser, cancel]);
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await apiGetSuperUserById(params.id);
        setSuperUser(result);
      } catch (err) {
        if (err.status === 404 && err.data.name === 'OBJECT_NOT_FOUND_EXCEPTION') {
          NotificationManager.error('this user no longer exists. Please refresh the page');
        } else {
          NotificationManager.error(`Failed to fetch super user. Please refresh or try again later`);
        }        cancel();
      }
    };

    fetchData();
  }, [params.id, cancel]);

  if (superUser) {
    return (
      <RemoveSuperUser
        superUser={superUser}
        removeSuperUser={removeSuperUser}
        cancel={cancel}
      />
    );
  }

  return null;
};

export default RemoveSuperUserContainer;
