import React from 'react';
import { Field } from 'formik';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import Icon from '../../../../ui/Icon';
import CustomFieldDialog from './CustomFieldDialog';

const CustomFieldListField = ({ name }) => {
  const [createVisible, setCreateVisible] = React.useState(false);
  const [customFieldIndexToEdit, setCustomFieldIndexToEdit] = React.useState(null);
  return (
    <Field name={name}>
      {({ field, form }) => {
        const createCustomField = (customField) => {
          form.setFieldValue(name, field.value.concat(customField));
          setCreateVisible(false);
        };

        const removeCustomField = (id) => {
          form.setFieldValue(name, field.value.filter((v, index) => index !== id));
        };

        const updateCustomField = (customField) => {
          form.setFieldValue(name, field.value.map((v, index) => {
            if (customFieldIndexToEdit === index) {
              return customField;
            }

            return v;
          }));
          setCustomFieldIndexToEdit(null);
        };

        const handleEditClick = (id) => {
          setCustomFieldIndexToEdit(id);
        };

        return (
          <div>
            {customFieldIndexToEdit !== null && (
              <CustomFieldDialog
                customField={field.value[customFieldIndexToEdit]}
                onSave={updateCustomField}
                onCancel={() => setCustomFieldIndexToEdit(null)}
              />
            )}
            {createVisible && (
              <CustomFieldDialog
                onSave={createCustomField}
                onCancel={() => setCreateVisible(false)}
              />
            )}
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
              <IconButton onClick={() => setCreateVisible(true)}>
                <Icon icon="add" />
              </IconButton>
            </Box>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Content</TableCell>
                    <TableCell>Required</TableCell>
                    <TableCell>Validation rule</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {field.value.map((customField, index) => (
                    <TableRow key={index}>
                      <TableCell>{customField.name}</TableCell>
                      <TableCell>{customField.type}</TableCell>
                      <TableCell>{customField.content}</TableCell>
                      <TableCell>{customField.required ? 'required' : '-'}</TableCell>
                      <TableCell>{customField.validationRule}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleEditClick(index)}>
                          <Icon icon="edit" />
                        </IconButton>
                        <IconButton onClick={() => removeCustomField(index)}>
                          <Icon icon="close" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )
      }}
    </Field>
  )
};

export default CustomFieldListField;
