import React, { useGlobal } from "reactn";
import { useHistory } from 'react-router-dom';
import AppLayout from "../components/AppLayout";
import logoutOnJwtExpiration from '../../../common/lib/logout-on-jwt-expiration';

const AppLayoutContainer = props => {
  const [loginData] = useGlobal("loginData");
  const history = useHistory();
  React.useEffect(() => {
    if (loginData) {
      return history.listen(() => {
        logoutOnJwtExpiration();
      });
    }
  }, [history, loginData]);

  if (loginData) {
    return <AppLayout {...props} />;
  } else {
    return null;
  }
};

export default AppLayoutContainer;
