export const MQ_SMALL_ONLY = 'only screen and (max-width: 667px)';

export const MQ_MEDIUM_ONLY = 'only screen and (min-width: 668px) and (max-width: 1024px)';

export const MQ_LARGE_ONLY = 'only screen and (min-width: 1025px) and (max-width: 1600px)';

export const MQ_LARGE_UP = 'only screen and (min-width: 1025px)';

export const MQ_LARGE_DOWN = 'only screen and (max-width: 1024px)';

export const MQ_MEDIUM_DOWN = 'only screen and (max-width: 768px)';
