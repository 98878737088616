import { apiGetSystemFixes } from '../api';
import { NotificationManager } from 'react-notifications';

export const FETCH_SYSTEM_FIXES = 'FETCH_SYSTEM_FIXES';

export const fetchSystemFixesReducer = async (global, dispatch) => {
  try {
    const systemFixes = await apiGetSystemFixes();
    return {
      systemFixes,
    };
  } catch (err) {
    NotificationManager.error('Something wrong happened. Please refresh or try again later');
  }
};
