import apiClientRequest from '../../common/apiclient';

export const apiGetSettingById = (spaceId) => apiClientRequest(`/settings/${spaceId}`, {
    method: 'GET',
});

export const apiSaveSettings = async (name, settings) => {
    return apiClientRequest(`/settings`, {
        method: 'POST',
        data: { name, settings },
    });
};
