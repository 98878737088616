import React from 'react';
import { useHistory } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { ContentNavBar, NavBarActions } from '../../../ui/ContentNavBar';
import { Table, TableActionCell, TableFooter } from '../../../ui/Table';
import LargeBox from '../../../ui/LargeBox';
import Pagination from '../../../ui/Pagination';
import TooltipButton from '../../../ui/TooltipButton';
import SearchClearableInput from '../../../common/components/SearchClearableInput';
import moment from 'moment';
import MessageDialog from '../../../ui/MessageDialog';
import EmailTemplateCategoryFormDialog from './EmailTemplateCategoryFormDialog';
import TableHeader from '../../../ui/TableHeader';

const EmailTemplateCategoriesList = ({
    category,
    setCategory,
    categories,
    total,
    currentPage,
    currentItemsPerPage,
    goToPage,
    onRefresh,
    onSearchChange,
    onEdit,
    save,
    onDelete,
    deleteCategory,
    open,
    setOpen,
    openDeleteDialog,
    setOpenDeleteDialog,
    loading,
}) => {
    const history = useHistory();
    const handleSearchChange = React.useCallback(debounce(onSearchChange, 500), [onSearchChange]);

    return (
        <LargeBox heading="Email Template Categories">
            <ContentNavBar>
                <NavBarActions>
                    <TooltipButton icon="refresh" text="refresh" onClick={onRefresh} />
                    <TooltipButton
                        icon="add"
                        onClick={() => { setCategory({}); setOpen(true); }}
                        text="Add a new e-mail templates"
                    />
                </NavBarActions>
                <NavBarActions>
                    <SearchClearableInput placeholder="Search ..." searchProperty="search" onChange={handleSearchChange} />
                </NavBarActions>
            </ContentNavBar>
            <Table isEmpty={total === 0} isEmptyComponent="There are no email templates.">
                <thead>
                    <tr>
                        <TableHeader header={[
                                { column: 'Name', orderBy: "name", textAlign: "left" },
                                { column: 'Description', orderBy: "description", textAlign: "left" },
                                { column: 'Created At', orderBy: "createdAt", textAlign: "left" },
                            ]} history={history}
                        />
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        categories.map((category, key) => (
                            <tr key={key}>
                                <td className="text-cell">{category.name}</td>
                                <td className="text-cell">{category.description}</td>
                                <td className="text-cell">{moment(category.createdAt).format('YYYY-DD-MM HH:mm')}</td>
                                <TableActionCell>
                                    <TooltipButton icon="edit" text="Edit" onClick={()=>onEdit(category._id)}/>
                                    <TooltipButton icon="delete" text="remove" onClick={()=>onDelete(category._id)}/>
                                </TableActionCell>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
            <TableFooter>
                <Pagination
                    currentPage={currentPage}
                    currentItemsPerPage={currentItemsPerPage}
                    onPageClick={goToPage}
                    total={total}
                />
            </TableFooter>
            <MessageDialog
                open={openDeleteDialog}
                title="Remove Email Template category"
                centertitle="true"
                closeDialog={()=> setOpenDeleteDialog(false)}
                buttons={[{ text: 'Yes', type: 'ok', onClick: deleteCategory },{ text: 'No', type: 'cancel' }]}
            >
                <p>You are about to delete this category, this action is irreversible are you sure?</p>
            </MessageDialog>
            <EmailTemplateCategoryFormDialog
                title="Email Template Category pp"
                category={category}
                save={save}
                open={open}
                cancel={() => setOpen(false)}
                loading={loading}
            />
        </LargeBox>
    );
};

export default EmailTemplateCategoriesList;
