import React, { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import UpdateSubscriptionDialog from '../components/UpdateSubscriptionDialog';
import { apiGetSubscriptionById, apiUpdateSubscription } from '../api';
import { useQueryMap } from '../../../common/hooks/use-query';
import useGoBack from '../../../common/hooks/use-go-back';
import useAsyncOperation from '../../../common/hooks/use-async-operation';

const UpdateSubscriptionContainer = () => {
  const params = useQueryMap();
  const goBack = useGoBack();
  const [subscription, setSubscription] = useState(null);
  const [asyncUpdateSubscription, update] = useAsyncOperation(apiUpdateSubscription);

  const updateSubscription = React.useCallback(async ({ expirationDate }) => {
    try {
      await asyncUpdateSubscription(params.subscriptionId, { expirationDate });
      goBack();
    } catch (err) {
      NotificationManager.error('Failed to update subscription. Please refresh or try again later');
    }
  }, [params.subscriptionId, goBack]);

  React.useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const result = await apiGetSubscriptionById(params.subscriptionId);
        setSubscription(result);
      } catch (err) {
        NotificationManager.error('Failed to load subscription. Please refresh or retry again later');
        goBack();
      }
    };

    fetchSubscription();
  }, [params.subscriptionId, goBack]);

  if (subscription) {
    return (
      <UpdateSubscriptionDialog
        cancel={goBack}
        processing={update.loading}
        subscription={subscription}
        updateSubscription={updateSubscription}
      />
    );
  }

  return null;
};

export default UpdateSubscriptionContainer;
