import React from 'react';
import Breadcrumb from '../../ui/Breadcrumb';
import SystemFixesListContainer from './containers/SystemFixesListContainer';
import LargeBox from '../../ui/LargeBox';
import systemFixesListGState from './gstate/init';

systemFixesListGState();

const SystemFixesList = () => (
  <>
    <Breadcrumb
      items={[
        { title: 'System Fixes' },
        { title: 'List' },
      ]}
    />
    <LargeBox heading="System Fixes">
      <SystemFixesListContainer />
    </LargeBox>
  </>
);

export default SystemFixesList;
