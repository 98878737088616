import React from 'react';
import { useQueryMap } from '../hooks/use-query';

const QueryParamRoute = ({ property, value, requiredParams = [], children }) => {
  const queryMap = useQueryMap();
  const child = React.Children.only(children);
  const allParamsExists = requiredParams.reduce((acc, param) => acc && !!queryMap[param], true);

  if (queryMap[property] === value && allParamsExists) {
    return child;
  }

  return null;
};

export default QueryParamRoute;
