import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import EditSuperUser from '../components/EditSuperUser';
import { NotificationManager } from 'react-notifications';
import { apiGetSuperUserById, apiUpdateSuperUserById } from '../api';
import useGoBack from '../../../common/hooks/use-go-back';
import useAsyncOperation from '../../../common/hooks/use-async-operation';

const EditSuperUserContainer = () => {
  const [superUser, setSuperUser] = React.useState();
  const params = useParams();
  const goBack = useGoBack();
  const [updateSuperUser, superUserUpdate] = useAsyncOperation(apiUpdateSuperUserById);

  const saveSuperUser = React.useCallback(async ({
    firstName, lastName, email, role,
  }) => {
    try {
      await updateSuperUser(params.id, {
        firstName,
        lastName,
        email,
        role,
      });
      NotificationManager.success(`${email} has been successfully updated`);
      goBack();
    } catch (err) {
      NotificationManager.error('Failed to update super user. Please refresh or try again later');
    }
  }, [params.id, goBack]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await apiGetSuperUserById(params.id);
        setSuperUser(result);
      } catch (err) {
        NotificationManager.error('Failed to load super user. Please refresh or try again later');
        goBack();
      }
    };

    fetchData();
  }, [params.id, goBack]);

  if (superUser) {
    return (
      <EditSuperUser
        superUser={superUser}
        cancel={goBack}
        saveSuperUser={saveSuperUser}
        loading={superUserUpdate.loading}
      />
    );
  }

  return null;
};

export default EditSuperUserContainer;
