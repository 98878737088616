import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { usePaginate } from '../../../common/hooks/use-paginate';
import Breadcrumb from '../../../ui/Breadcrumb';
import { NotificationManager } from 'react-notifications';
import {
  useAddPropertyToQueryString,
  useQueryMap
} from '../../../common/hooks/use-query';
import useAsyncOperation from '../../../common/hooks/use-async-operation';
import {
  apiGetTemplates,
  apiSaveTemplate,
  apiDeleteEmailTemplate,
  apiGetAllTemplateCategories,
  apiGetAllTemplateTags,
  apiGetTemplateById,

} from '../api';
import EmailTemplatesList from '../components/EmailTemplatesList';

const EmailTemplatesContainer = () => {
  const [template, setTemplate] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [openEditor, setOpenEditor] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const [tags, setTags] = React.useState([]);
  const [asyncSaveTemplate, templateCreation] = useAsyncOperation(apiSaveTemplate);
  const [asyncDeleteTemplate, templateDeletion] = useAsyncOperation(apiDeleteEmailTemplate);

  const [
    currentPage,
    currentItemsPerPage,
    goToPage
  ] = usePaginate();

  const [
    templates,
    setTemplates
  ] = useState([]);

  const [
    total,
    setTotal
  ] = useState(0);

  const addPropertyToQueryString = useAddPropertyToQueryString();
  const history = useHistory();
  const match = useRouteMatch();

  const queryMap = useQueryMap();

  const { search: currentSearch, order, orderBy } = queryMap;

  const fetchData = async () => {
    try {
      const { templates, total } = await apiGetTemplates({
        page: currentPage,
        itemsPerPage: currentItemsPerPage,
        order,
        orderBy,
        search: currentSearch,
      });

      setTemplates(templates);
      setTotal(total);
    } catch (err) {
      NotificationManager.error('Failed to load email templates. Please refresh or try again later');
    }
  };

  const fetchCategories = async () => {
    try {
      setCategories(await apiGetAllTemplateCategories());
      setTags(await apiGetAllTemplateTags());
    } catch (err) {
      NotificationManager.error('Failed to load email template categories. Please refresh or try again later');
    }
  };

  useEffect(() => {
    fetchData();
    fetchCategories();

    return history.listen((location, action) => { setTemplate({});
      if (action === 'POP') {
        fetchData();
      }
    });
  }, [currentPage, currentItemsPerPage, currentSearch, order, orderBy, history]);

  const onSearchChange = React.useCallback((search) => {
    history.push(`${match.url}?${addPropertyToQueryString(['search', search])}`);
  }, [history, match.url, addPropertyToQueryString]);


  const onRefresh = React.useCallback(() => {
    fetchData();
  }, []);

  const save = React.useCallback(async (data, { resetForm }) => {
    try {
      const form = new FormData();
      if (data.id) {
        form.append('id', data.id );
      }
      form.append('name', data.name);
      form.append('category', data.category);
      form.append('tags', data.tags);
      if (data.template) {
        form.append('files', data.template[0]);
      }
      if (data.templateThumbnail) {
        form.append('files', data.templateThumbnail[0], `[thumbnail]-${data.templateThumbnail[0]?.name}`);
      }
      await asyncSaveTemplate(form);
      history.push('templates')
      onRefresh();
      setOpen(false);
      setOpenEditor(false);
      resetForm();
    } catch (err) { console.log({ err })
      NotificationManager.error('Failed to save email template. Please refresh or try again later');
    }
  }, []);

  const loadTemplate = React.useCallback(async (id) => {
    try {
      if (id) {
        setTemplate(await apiGetTemplateById(id));
      } else {
        setTemplate({});
      }
    } catch (err) {
      NotificationManager.error('Failed to loademail template. Please refresh or try again later');
    }
  }, []);

  const onDelete = React.useCallback(async (id) => {
    setTemplate({ id });
    setOpenDeleteDialog(true);
  }, [template]);

  const deleteTemplate = React.useCallback(async () => {
    try {
      await asyncDeleteTemplate(template.id);
      fetchData();
      setOpenDeleteDialog(false);
    } catch (err) {
      NotificationManager.error('Failed to delete email template. Please refresh or try again later');
    }
  }, [template]);

  return (
    <>
      <Breadcrumb
        items={[
          { title: 'Email Templates' }
        ]}
      />
      <EmailTemplatesList
        template={template}
        setTemplate={setTemplate}
        templates={templates}
        categories={categories}
        tags={tags}
        currentPage={currentPage}
        currentItemsPerPage={currentItemsPerPage}
        currentSearch={currentSearch}
        total={total}
        goToPage={goToPage}
        onRefresh={onRefresh}
        onSearchChange={onSearchChange}
        save={save}
        onDelete={onDelete}
        deleteTemplate={deleteTemplate}
        setOpen={setOpen} open={open}
        openDeleteDialog={openDeleteDialog} setOpenDeleteDialog={setOpenDeleteDialog}
        loading={templateCreation.loading || templateDeletion.loading}
        loadTemplate={loadTemplate}
        openEditor={openEditor}
        setOpenEditor={setOpenEditor}
      />
    </>
  );
};

export default EmailTemplatesContainer;
