import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import FormDialog from '../../ui/FormDialog';
import { Box, FormGroup } from '@material-ui/core';
import Label from '../../ui/Label';
import FormikTextInput from '../../ui/FormikTextInput';
import { CustomErrorMessage } from '../../ui/formik/CustomErrorMessage';
import SectionLoader from '../../ui/SectionLoader';

const validationSchema = yup.object({
  activityId: yup.string().required(),
  backupURL: yup.string().required(),
  contactId: yup.string().required(),
});

const RestorationDialog = ({ isLoading, idToRestore, contactId, lastName, firstName, email, onClose, onPreviewRestoredContact }) => {

  return (
    <Formik
      onSubmit={onPreviewRestoredContact}
      validationSchema={validationSchema}
      initialValues={{
        activityId: idToRestore,
        oldContactId: contactId,
        lastName,
        firstName,
        email,
        contactId: '',
        backupURL: 'mongodb://devcrm:pointrd202001@cluster0-shard-00-00-rm97b.mongodb.net:27017,cluster0-shard-00-01-rm97b.mongodb.net:27017,cluster0-shard-00-02-rm97b.mongodb.net:27017/erp-2021-10-01?ssl=true&replicaSet=Cluster0-shard-0&authSource=admin&retryWrites=true',
      }}
    >
      {({ handleSubmit }) => (
        <FormDialog
          open
          title="Restoration"
          closeDialog={onClose}
          buttons={[
            { type: 'ok', text: 'Restore', onClick: handleSubmit },
            { type: 'cancel', text: 'Cancel' },
          ]}
        >
          {isLoading && <SectionLoader />}
          <p>
            Find the backup that has this contact, the activity doesn't matter as the user probably <br />
            didn't update the contacts information. This restoration dialog will only create the deleted contact row <br />
            and link it to the activity pages and will create another row in the denormalized collection.
          </p>
          <FormGroup>
            <Label>Id to restore</Label>
            <FormikTextInput name="activityId" disabled />
          </FormGroup>
          <FormGroup>
            <Label>Contact Id</Label>
            <FormikTextInput name="oldContactId" disabled />
          </FormGroup>
          <FormGroup>
            <Label>Last name</Label>
            <FormikTextInput name="lastName" disabled />
          </FormGroup>
          <FormGroup>
            <Label>First name</Label>
            <FormikTextInput name="firstName" disabled />
          </FormGroup>
          <FormGroup>
            <Label>Email</Label>
            <FormikTextInput name="email" disabled />
          </FormGroup>
          <Box mt={4} mb={4} />
          <FormGroup>
            <Label>Enter the MongoDB backup database URL: (I used the 10-01-2021 and it worked)</Label>
            <FormikTextInput name="backupURL" />
            <CustomErrorMessage name="backupURL" />
          </FormGroup>
          <FormGroup>
            <Label>Enter the contact id that exists in the backup that matches the information above</Label>
            <FormikTextInput name="contactId" />
            <CustomErrorMessage name="contactId" />
          </FormGroup>
        </FormDialog>
      )}
    </Formik>
  );
};

export default RestorationDialog;
