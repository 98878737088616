import React, { useCallback } from 'react';
import { NotificationManager } from 'react-notifications';
import { apiCreateSubscriptionPlan } from '../api';
import SubscriptionPlanDialog from '../../../common/components/SubscriptionPlanDialog';
import useGoBack from '../../../common/hooks/use-go-back';
import useAsyncOperation from '../../../common/hooks/use-async-operation';

const AddSubscriptionPlanContainer = () => {
  const goBack = useGoBack();
  const [asyncCreateSubscriptionPlan, subscriptionPlanCreation] = useAsyncOperation(apiCreateSubscriptionPlan);

  const createSubscription = useCallback(async (data) => {
    try {

      await asyncCreateSubscriptionPlan(data);

      NotificationManager.success(`${data.name} has been successfully created`);
      goBack();
    } catch (err) {
      if (err.data) {
        switch (err.data.name) {
          case 'NAME_ALREADY_EXISTS_EXCEPTION':
            NotificationManager.error('Name already exists');
            break;
          default:
            NotificationManager.error('Failed to create subscription. Please refresh or try again');
            break;
        }
      } else {
        NotificationManager.error('Failed to create subscription. Please refresh or try again');
      }
    }
  }, [goBack, asyncCreateSubscriptionPlan]);

  return (
    <SubscriptionPlanDialog
      title="Add Subscription Plan"
      onCancel={goBack}
      onSave={createSubscription}
      loading={subscriptionPlanCreation.loading}
    />
  );
};

export default AddSubscriptionPlanContainer;
