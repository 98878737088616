import { API_ACCESS_TOKEN } from "../../../common/constants";
import LoginData from "../models/login-data";
import { registerJwt } from "./callbacks";
import { loginReducer } from "./reducers";
import makeGlobalState from "../../../common/lib/make-global-state";

const loginPageGlobal = makeGlobalState(
  {
    loginData: getInitialLoginData()
  },
  [registerJwt],
  [loginReducer]
);

export default loginPageGlobal;

function getInitialLoginData() {
  const accessToken = localStorage.getItem(API_ACCESS_TOKEN);
  if (accessToken) {
    return new LoginData({ accessToken });
  }

  return null;
}
