import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import SpacesListContainer from '../pages/SpacesList/containers/SpacesListContainer';
import SpaceUsersList from '../pages/SpacesList/containers/SpaceUsersListContainer';
import AppAccessSwitch from '../common/components/AppAccessSwitch';
import EditSubscriberDialog from '../pages/EditSubscriber/EditSubscriber';
import QueryParamRoute from '../common/components/QueryParamRoute';
import CanSeeUsingRole from '../common/components/CanSeeUsingRole';

const SpacesRoutes = () => (
  <>
    <AppAccessSwitch role="SALESMAN">
      <Route path="/spaces/list">
        <SpacesListContainer />
      </Route>
      <Route path="/spaces/:spaceId/users/list">
        <SpaceUsersList />
      </Route>
      <Redirect from="/spaces" to="/spaces/list" />
    </AppAccessSwitch>
    <CanSeeUsingRole role="SALESMAN">
      <QueryParamRoute property="action" value="change-space-owner" requiredParams={['id']}>
        <EditSubscriberDialog />
      </QueryParamRoute>
    </CanSeeUsingRole>
  </>
);

export default SpacesRoutes;
