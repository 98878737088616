import apiClientRequest from '../../common/apiclient';

export const apiGetCorruptedActivities = () =>
  apiClientRequest('/corrupted-activities');

export const apiRestoreCorruptedActivities = () =>
  apiClientRequest('/corrupted-activities/restore', {
    method: 'POST',
  });

export const apiGetCorruptedActivitiesResult = () =>
  apiClientRequest('/corrupted-activities/result');
