import React from 'react';
import { Field } from 'formik';
import Select2 from './Select2';

const getValue = (options, value) => {
  if (Array.isArray(value)) {
    return value.map(v => options.find(option => option.value === v));
  } else {
    return options.find(option => option.value === value);
  }
};

const FormikSelect2 = ({ name, ...props }) => (
  <Field name={name}>
    {
      ({ field, form }) => (
        <Select2
          {...field}
          value={getValue(props.options, field.value)}
          onChange={(selectedOptions) => {
            if (props.isMulti) {
              const value = selectedOptions ?
                selectedOptions.map(option => option.value) : [];
              form.setFieldValue(field.name, value);
            } else {
              form.setFieldValue(field.name, selectedOptions);
            }
          }}
          onBlur={() => {
            form.setFieldTouched(field.name, true);
          }}
          {...props}
        />
      )
    }
  </Field>
);

export default FormikSelect2;
