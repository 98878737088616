import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Dialog from '../../ui/Dialog';

const Message = styled.div`
  width: 400px;
`;

const ForbiddenAccess = () => {
  const history = useHistory();
   const goBack = React.useCallback(() => {
      history.goBack();
   }, [history]);

   return (
     <Dialog
       open
       title="Forbidden Access"
       closeDialog={goBack}
       buttons={[
          { type: 'ok', text: 'Back', onClick: goBack }
       ]}
     >
        <Message>Sorry, but you can't access this page. Please contact the admin of your space.</Message>
     </Dialog>
   );
};

export default ForbiddenAccess;
