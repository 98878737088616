import React from 'react';
import SubscriptionCancellation from '../components/SubscriptionCancellation';
import { NotificationManager } from 'react-notifications';
import { apiGetSubscriptionById, apiTerminateSubscription } from '../api';
import { useQueryMap } from '../../../common/hooks/use-query';
import useAsyncOperation from '../../../common/hooks/use-async-operation';
import useGoBack from '../../../common/hooks/use-go-back';

const SubscriptionCancellationContainer = () => {
  const goBack = useGoBack();
  const queryMap = useQueryMap();
  const [subscription, setSubscription] = React.useState(null);
  const [asyncTerminateSubscription, update] = useAsyncOperation(apiTerminateSubscription);

  const cancelSubscription = React.useCallback(async ({ reason }) => {
    try {
      await asyncTerminateSubscription(queryMap.subscriptionId, { reason });
      goBack();
    } catch (err) {
      NotificationManager.error('Failed to terminate subscription. Please refresh or retry later');
    }
  }, [queryMap.subscriptionId, goBack, asyncTerminateSubscription]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await apiGetSubscriptionById(queryMap.subscriptionId);
        setSubscription(result);
      } catch (err) {
        NotificationManager.error('Failed to fetch subscription data. Please refresh or retry later');
      }
    };

    fetchData();
  }, [queryMap.subscriptionId]);

  if (subscription) {
    return (
      <SubscriptionCancellation
        processing={update.loading}
        onCancel={goBack}
        cancelSubscription={cancelSubscription}
        subscription={subscription}
      />
    )
  }

  return null;
};

export default SubscriptionCancellationContainer;
