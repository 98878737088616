import React from 'react';
import { NotificationManager } from 'react-notifications';
import { apiUpdateSubscriber } from './api';
import SubscriberFormDialog from '../../common/components/SubscriberFormDialog';
import { useQueryMap } from '../../common/hooks/use-query';
import useGoBack from '../../common/hooks/use-go-back';
import useAsyncOperation from '../../common/hooks/use-async-operation';
import { apiGetSubscriberById } from '../../common/api';

const EditSubscriber = () => {
  const goBack = useGoBack();
  const query = useQueryMap();
  const [subscriber, setSubscriber] = React.useState();
  const [asyncUpdateSubscriber, subscriberUpdate] = useAsyncOperation(apiUpdateSubscriber);
  const editSubscriber = React.useCallback(async ({
    firstName, lastName, email, companyName, phone, line1, line2, city, country, zip,
  }) => {
    try {
      await asyncUpdateSubscriber(query.id, {
        firstName, lastName, email, companyName, phone, line1, line2, city, country, zip,
      });
      NotificationManager.success(`${firstName} ${lastName} subscriber account has been successfully created!`);
      goBack();
    } catch (err) {
      if (err && err.status === 400 && err.data.name === 'EMAIL_ALREADY_EXISTS_EXCEPTION') {
        NotificationManager.error(`${email} already exists for another subscriber`);
      } else {
        NotificationManager.error('Failed to create subscriber. Please refresh or try again later');
      }
    }
  }, [goBack, query.id, asyncUpdateSubscriber]);

  React.useEffect(() => {
    const fetch = async () => {
      try {
        const result = await apiGetSubscriberById(query.id);
        setSubscriber(result);
      } catch (err) {
        if (err && err.status === 404 && err.data.name === 'OBJECT_NOT_FOUND_EXCEPTION') {
          NotificationManager.error(`This subscriber is no longer available`);
          goBack();
        } else {
          NotificationManager.error('Something wrong happened. Please refresh or try again later');
        }
      }
    };

    fetch();
  }, [goBack, query.id]);

  if (subscriber) {
    return (
      <SubscriberFormDialog
        editMode
        title="Edit Subscriber"
        subscriber={subscriber}
        loading={subscriberUpdate.loading}
        save={editSubscriber}
        cancel={goBack}
      />
    )
  }

  return null;
};

export default EditSubscriber;
