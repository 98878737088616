import apiClientRequest from '../../common/apiclient';

export const apiCreateSubscriptionPlan = ({
  name,
  type,
  defaultProducts,
  spacesQuota,
  activitiesQuota,
  workflowsQuota,
  filesGbPrice,
  freeFilesQuota,
  price,
  period,
  unit,
  isDefault,
  visible,
  unlimitedActivities,
  unlimitedTeamBoardCards,
  teamBoardCardsQuota,
  unlimitedWorkflows,
  unlimitedSpaces,
  unlimitedReports,
  reportsQuota,
  position,
  freeEmails,
  emailUnit,
  emailUnitPrice,
}) => apiClientRequest('/create-subscription-plan', {
  method: 'POST',
  data: {
    name,
    type,
    defaultProducts,
    spacesQuota,
    activitiesQuota,
    workflowsQuota,
    filesGbPrice,
    freeFilesQuota,
    price,
    isDefault,
    visible,
    period,
    unit,
    unlimitedActivities,
    unlimitedReports,
    reportsQuota,
    unlimitedWorkflows,
    unlimitedSpaces,
    unlimitedTeamBoardCards,
    teamBoardCardsQuota,
    position,
    freeEmails,
    emailUnit,
    emailUnitPrice,
  }
});
