import React from 'react';
import { ContentNavBar, NavBarActions } from '../../../ui/ContentNavBar';
import { Table, TableActionCell, TableFooter } from '../../../ui/Table';
import LargeBox from '../../../ui/LargeBox';
import Pagination from '../../../ui/Pagination';
import TooltipButton from '../../../ui/TooltipButton';
import TooltipLink from '../../../ui/TooltipLink';

const SuperUsersList = ({ superUsers, onRefresh, currentPage, currentItemsPerPage, goToPage, total }) => (
  <LargeBox heading="Super Users">
    <ContentNavBar>
      <NavBarActions>
        <TooltipButton text="refresh" icon="refresh" onClick={onRefresh} />
      </NavBarActions>
      <NavBarActions>
        <TooltipLink icon="add" text="New Super User" to="/super-users/add" />
      </NavBarActions>
    </ContentNavBar>
    <Table isEmpty={total === 0} isEmptyComponent="There are no super users. Please click on + to add one">
      <thead>
      <tr>
        <th className="text-cell">Full Name</th>
        <th className="text-cell">Email</th>
        <th className="text-cell">Role</th>
        <th>Actions</th>
      </tr>
      </thead>
      <tbody>
      {
        superUsers.map(superUser => (
          <tr key={superUser._id}>
            <td className="text-cell">{ superUser.lastName } { superUser.firstName }</td>
            <td className="text-cell">{ superUser.email }</td>
            <td className="text-cell">{ superUser.role }</td>
            <TableActionCell>
              <TooltipButton icon="edit" text="Edit" to={`/super-users/edit/${superUser._id}`} isLink />
              <TooltipButton icon="close" text="remove" to={`/super-users/remove/${superUser._id}`} isLink />
            </TableActionCell>
          </tr>
        ))
      }
      </tbody>
    </Table>
    <TableFooter>
      <Pagination
        currentPage={currentPage}
        currentItemsPerPage={currentItemsPerPage}
        onPageClick={goToPage}
        total={total}
      />
    </TableFooter>
  </LargeBox>
);

export default SuperUsersList;
