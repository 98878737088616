import React from 'react';
import AppAccessSwitch from '../common/components/AppAccessSwitch';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';
import RestoreContactsListContainer from '../pages/ActivitiesRestoreToolkit/RestoreContactsListContainer';

const RestoreContactsRoutes = () => {
  const match = useRouteMatch();

  return (
    <AppAccessSwitch role="ADMIN">
      <Route path={`${match.url}/list`}>
        <RestoreContactsListContainer />
      </Route>
      <Redirect from={`${match.url}`} to={`${match.url}/list`} />
    </AppAccessSwitch>
  )
};

export default RestoreContactsRoutes;
