import React from 'react';
import { Field } from 'formik';
import CountryInput from '../CountryInput';

const FormikCountryInput = ({ name, ...props }) => (
  <Field name={name}>
    {
      ({ field, form }) => {
        const handleChange = (value) => {
          form.setFieldValue(field.name, value);
        };

        return (
          <CountryInput
            {...field}
            {...props}
            onChange={handleChange}
          />
        );
      }
    }
  </Field>
);

export default FormikCountryInput;
