export const API_ACCESS_TOKEN = 'API_ACCESS_TOKEN';

const SUBSCRIPTION_PLAN_PRODUCT_TYPE = {
  PLANNING_BOARD: 'PLANNING_BOARD',
  TASK_MANAGEMENT: 'TASK_MANAGEMENT',
  DASHBOARD: 'DASHBOARD',
  DATA_EXPORT: 'DATA_EXPORT',
  DATA_IMPORT: 'DATA_IMPORT',
  MAILING: 'MAILING',
  FILES_STORAGE: 'FILES_STORAGE',
  EMAIL_CAMPAIGN: 'EMAIL_CAMPAIGN',

};

export default SUBSCRIPTION_PLAN_PRODUCT_TYPE;
