import styled from 'styled-components';
import MaterialIcon from '@material/react-material-icon';
import React from 'react';
import Popover from '@material-ui/core/Popover';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(MaterialIcon)`
  font-size: 25px;
  color: #555;
  margin: 0 6px;
  cursor: pointer;
`;

const PopoverContent = styled.div`
  width: 200px;
  background-color: #555;
  color: #fff;
  padding: 5px 7px;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
`;

const InputPopover = ({ popoverText, ...props}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Wrapper {...props}>
      <StyledIcon icon="info" onClick={handleClick} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <PopoverContent>
          { popoverText }
        </PopoverContent>
      </Popover>
    </Wrapper>
  )
};

export default InputPopover;
