import apiClientRequest from '../../common/apiclient';

export const apiGetLegacyUsers = () => apiClientRequest('/legacy-users/list');

export const apiGetSubscriptionPlans = () => apiClientRequest('/legacy-users/plans');

export const apiMigrateLegacyUsers = ({ plan }) =>
  apiClientRequest('/legacy-users/migrate', {
    method: 'POST',
    data: {
      plan
    },
  });
