import React from 'react';
import { Form, Formik } from 'formik';
import styled from 'styled-components';
import { submitOnce } from '../../../common/lib/formik-helpers';
import FormDialog from '../../../ui/FormDialog';
import SectionLoader from '../../../ui/SectionLoader';
import FormGroup from '../../../ui/FormGroup';
import Label from '../../../ui/Label';
import FormikTextInput from '../../../ui/FormikTextInput';
import { CustomErrorMessage } from '../../../ui/formik/CustomErrorMessage';
import * as yup from 'yup';

const StyledForm = styled(Form)`
  width: 385px;
`;

const passwordChangeSchema = yup.object({
	oldPassword: yup.string().required('Password is required')
		.min(5, 'The password must contain at leasat 5 characters'),
	newPassword: yup.string().required('New Password is required')
		.min(5, 'The new password must contain at leasat 5 characters'),
});

const ChangeUserPassword = ({ save, cancel, loading }) => {
  const submit = React.useCallback(submitOnce(save), [save]);
  return (
    <Formik
      validationSchema={passwordChangeSchema}
      initialValues={{
        oldPassword: '',
        newPassword: '',
      }}
      onSubmit={submit}
    >
      {
        ({ handleSubmit }) => (
          <FormDialog
            title="Change Password"
            closeDialog={cancel}
            open
            buttons={[
              { text: 'Save', type: 'ok', onClick: handleSubmit },
              { text: 'Back', type: 'cancel' }
            ]}
          >
            {
              loading && <SectionLoader />
            }
            <StyledForm onSubmit={handleSubmit}>

              <FormGroup>
                <Label htmlFor="oldPassword">Current Password</Label>
                <FormikTextInput name="oldPassword" type="password" />
                <CustomErrorMessage name="oldPassword" />
              </FormGroup>

              <FormGroup>
                <Label htmlFor="newPassword">New Password</Label>
                <FormikTextInput name="newPassword" type="password" />
                <CustomErrorMessage name="newPassword" />
              </FormGroup>

            </StyledForm>
          </FormDialog>
        )
      }
    </Formik>
  )
};

export default ChangeUserPassword;
