import React, { useGlobal, useDispatch, useCallback } from "reactn";
import SideMenu from "../components/SideMenu";
import { useLocation, matchPath } from "react-router-dom";
import {
  SET_SELECTED_SIDE_MENU_ITEM,
  TOGGLE_SIDE_MENU
} from "../global/reducers";
import { SIDE_MENU_ITEM_PATH_MAP, SIDE_MENU_ITEMS } from "../SideMenuConfig";
import { useEffect } from "react";

const SideMenuContainer = () => {
  const [sideMenu] = useGlobal("sideMenu");

  const [loginData] = useGlobal("loginData");

  const location = useLocation();
  const toggleSideMenu = useDispatch(TOGGLE_SIDE_MENU);
  const setSideMenuSelectedItem = useDispatch(SET_SELECTED_SIDE_MENU_ITEM);

  const onSideMenuItemClick = useCallback(
    selectedMenuItem => {
      setSideMenuSelectedItem({ selectedMenuItem });
    },
    [setSideMenuSelectedItem]
  );

  useEffect(() => {
    const initSelectedItem = () => {
      const { pathname } = location;
      const selectedMenuItem = Object.keys(SIDE_MENU_ITEM_PATH_MAP).find(path =>
        matchPath(SIDE_MENU_ITEM_PATH_MAP[path], {
          path: pathname,
          exact: true,
          strict: false
        })
      );
      setSideMenuSelectedItem({ selectedMenuItem });
    };

    initSelectedItem();
  }, [loginData]);

  return (
    <SideMenu
      opened={sideMenu.opened}
      userRole={loginData?.user?.role}
      onMenuButtonClick={toggleSideMenu}
      sideMenuItems={SIDE_MENU_ITEMS}
      selectedSideMenuItem={sideMenu.selectedMenuItem}
      onSideMenuItemClick={onSideMenuItemClick}
    />
  );
};

export default SideMenuContainer;
