import React from 'react';
import { Form, Formik } from 'formik';
import styled from 'styled-components';
import FormGroup from '../../../ui/FormGroup';
import Label from '../../../ui/Label';
import FormikTextInput from '../../../ui/FormikTextInput';
import { FormikRadio } from '../../../ui/FormikRadio';
import { Row } from '../../../ui/Flex';
import { CustomErrorMessage } from '../../../ui/formik/CustomErrorMessage';
import { submitOnce } from '../../../common/lib/formik-helpers';
import FormDialog from '../../../ui/FormDialog';
import SectionLoader from '../../../ui/SectionLoader';

const StyledForm = styled(Form)`
  width: 385px;
`;

const AddSuperUser = ({ saveSuperUser, cancel, loading }) => {
  const submit = React.useCallback(submitOnce(saveSuperUser), [saveSuperUser]);
  return (
    <Formik
      initialValues={{
        email: '',
        firstName: '',
        lastName: '',
        role: 'ADMIN',
        password: '',
      }}
      onSubmit={submit}
    >
      {
        ({ handleSubmit }) => (
          <FormDialog
            title="Add Super User"
            closeDialog={cancel}
            open
            buttons={[
              { text: 'Save', type: 'ok', onClick: handleSubmit },
              { text: 'Back', type: 'cancel' }
            ]}
          >
            {
              loading && <SectionLoader />
            }
            <StyledForm onSubmit={handleSubmit}>
              <FormGroup>
                <Label htmlFor="firstName">First Name</Label>
                <FormikTextInput name="firstName" />
                <CustomErrorMessage name="firstName" />
              </FormGroup>

              <FormGroup>
                <Label htmlFor="lastName">Last Name</Label>
                <FormikTextInput name="lastName" />
                <CustomErrorMessage name="lastName" />
              </FormGroup>

              <FormGroup>
                <Label htmlFor="email">Email</Label>
                <FormikTextInput name="email" type="email" />
                <CustomErrorMessage name="email" />
              </FormGroup>

              <FormGroup>
                <Label htmlFor="password">Password</Label>
                <FormikTextInput name="password" type="text" />
                <CustomErrorMessage name="password" />
              </FormGroup>

              <FormGroup>
                <Label htmlFor="role">Role</Label>
                <Row>
                  <FormikRadio name="role" value="ADMIN">Admin</FormikRadio>
                  <FormikRadio name="role" value="SALESMAN">Salesman</FormikRadio>
                </Row>
                <CustomErrorMessage name="role" />
              </FormGroup>
            </StyledForm>
          </FormDialog>
        )
      }
    </Formik>
  )
};

export default AddSuperUser;
