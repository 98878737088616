import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import Breadcrumb from '../../ui/Breadcrumb';
import LegacyUsersListContainer from './containers/LegacyUsersListContainer';
import AppAccessRoute from '../../common/components/AppAccessRoute';
import MigrateUserDialogContainer from './containers/MigrateUserDialogContainer';

const LegacyUsersList = () => {
  const match = useRouteMatch();

  return (
    <>
      <Breadcrumb
        items={[
          { title: 'Legacy users' }
        ]}
      />
      <LegacyUsersListContainer />
      <AppAccessRoute role="ADMIN" path={`${match.url}/migrate`}>
        <MigrateUserDialogContainer />
      </AppAccessRoute>
    </>
  );
};

export default LegacyUsersList;
