import React from 'react';
import styled from 'styled-components';
import MaterialIcon from '@material/react-material-icon';

const StyledInput = styled.input`
  border: 1px solid #ccc;
  border-radius: 15px;
  color: #333;
  padding: 8px 15px 8px 10px;
  width: 120px;
  height: 30px;
  font-size: 12px;

    
  &:focus {
    outline: none;
    box-shadow: 0 0 1px #777;
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

const Button = styled(MaterialIcon)`
  position: absolute;
  cursor: pointer;
  color: #555;
  top: 5px;
  font-size: 21px;
  right: 10px;
`;


const TagInput = ({ onAddClick, ...props }) => (
  <Wrapper>
    <StyledInput type="text" autoComplete="off" {...props} />
    <Button icon="add" onClick={onAddClick} />
  </Wrapper>
);

export default TagInput;
