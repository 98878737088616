import React, { useCallback } from 'react';
import styled from 'styled-components';
import MaterialIcon from '@material/react-material-icon';
import { useHistory } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 7px;
  align-items: center;
  > * {
    margin-right: 5px;
  }
  background-color: inherit;
  border-radius: 4px;
  margin-top: 10px;
`;

const BreadcrumbRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const BreadcrumbIcon = styled(MaterialIcon)`
  font-size: 18px;
  color: ${({ dark = 0 }) => dark ? '#333' : '#777'};
  cursor: pointer;
  margin-right: 5px;
`;

const BreadcrumbText = styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
`;

export const BreadcrumbComponent = ({ showBack, onBackClick, items = [], onHomeClick, ...props }) => (
  <Wrapper {...props}>
    <BreadcrumbRow onClick={onHomeClick}>
      {
        showBack && (
          <BreadcrumbIcon
            dark={1}
            onClick={onBackClick}
            icon="arrow_back"
            title="Back"
          />
        )
      }
    </BreadcrumbRow>
    {
      items.map(({ title, onClick }, key) => (
        <BreadcrumbRow onClick={onClick} key={key}>
          {
            key > 0 && (
              <BreadcrumbIcon
                icon="keyboard_arrow_right"
              />
            )
          }
          <BreadcrumbText>{title}</BreadcrumbText>
        </BreadcrumbRow>
      ))
    }
  </Wrapper>
);

const Breadcrumb = (props) => {
  const history = useHistory();
  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <BreadcrumbComponent
      showBack={history.length > 2}
      onBackClick={goBack}
      {...props}
    />
  )
};

export default Breadcrumb;
