import { Field } from 'formik';
import styled from 'styled-components';
import React from 'react';
import MuiSelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const Select = styled(MuiSelect)`
  max-width: 450px;
  .select {
    color: #333;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    border-radius: 2px;
    max-width: 450px;
  }
`;

const FormikSelect = ({ name, fill, ...props }) => (
  <Field name={name}>
    {
      ({ field, form }) => (
        <Select classes={{
          root: 'select-root',
          select: 'select',
        }} {...field} {...props} onChange={(event) => {
          form.setFieldValue(field.name, event.target.value);
        }} />
      )
    }
  </Field>
);

export const SelectOption = MenuItem;

export default FormikSelect;
