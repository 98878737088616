import axios from "axios";
import appConfig from "../config";
import { API_ACCESS_TOKEN } from "./constants";

const getHttpHeaders = config => {
  let headers = {
    ...config.headers,
    "Content-Type": "application/json"
  };

  const accessToken = localStorage.getItem(API_ACCESS_TOKEN);

  if (accessToken) {
    headers = {
      ...headers,
      Authorization: `Bearer ${accessToken}`
    };
  }

  return headers;
};

const apiClientRequest = async (path, config = {}) => {
  const { method: httpMethod = "GET" } = config;
  const method = httpMethod.toLowerCase();
  const url = `${appConfig.apiUrl}${path}`;
  const headers = getHttpHeaders(config);

  try {
    const response = await axios({
      ...config,
      url,
      method,
      headers
    });

    return response.data;
  } catch (err) {
    throw err.response;
  }
};

export default apiClientRequest;
