import React from 'react';
import SubscriberFormDialog from '../../common/components/SubscriberFormDialog';
import { apiCreateSubscriber } from './api';
import { NotificationManager } from 'react-notifications';
import useGoBack from '../../common/hooks/use-go-back';
import useAsyncOperation from '../../common/hooks/use-async-operation';

const CreateSubscriberDialogContainer = () => {
  const goBack = useGoBack();
  const [asyncCreateSubscriber, subscriberCreation] = useAsyncOperation(apiCreateSubscriber);

  const createSubscriber = React.useCallback(async ({
    firstName, lastName, email, companyName, phone, line1, line2, city, country, zip,
  }) => {
    try {
      await asyncCreateSubscriber({
        firstName, lastName, email, companyName, phone, line1, line2, city, country, zip,
      });
      NotificationManager.success(`${firstName} ${lastName} subscriber account has been successfully created!`);
      goBack();
    } catch (err) {
      if (err.status === 400 && err.data.name === 'EMAIL_ALREADY_EXISTS_EXCEPTION') {
        NotificationManager.error(`${email} already exists for another subscriber`);
      } else {
        NotificationManager.error('Failed to create subscriber. Please refresh or try again later');
      }
    }
  }, [goBack, asyncCreateSubscriber]);

  return (
    <SubscriberFormDialog
      title="Add Subscriber"
      save={createSubscriber}
      cancel={goBack}
      loading={subscriberCreation.loading}
    />
  )
};

export default CreateSubscriberDialogContainer;
