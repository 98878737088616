import React from 'react';
import { Table, TableContainer, TableRow, TableHead, TableBody, TableCell, IconButton, Paper } from '@material-ui/core';
import MaterialIcon from '@material/react-material-icon';
import Breadcrumb from '../../ui/Breadcrumb';
import LargeBox from '../../ui/LargeBox';
import { NotificationManager } from 'react-notifications';

function copyToClipboard(value) {
  const el = document.createElement('textarea');
  el.value = value;
  el.setAttribute('readonly', '');
  el.style = {
    position: 'absolute', left: '-9999px'
  };
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  NotificationManager.success('Copied to clipboard');
}

const ActivitiesToRestoreList = ({ activities, corruptionStatuses, onRestoreActivity }) => {

  return (
    <React.Fragment>
      <Breadcrumb
        items={[
          { title: 'Restore contacts' },
        ]}
      />
      <LargeBox heading="Restore contacts">
        <p>
          Before starting please find the database backup that hasn't been affected by the bug, and that has the contacts bellow: <br />
          <MaterialIcon icon="close" style={{ color: 'red' }} /> refers to the activities that needs to be repaired. <br />
          <MaterialIcon icon="check" style={{ color: 'green' }} /> referes to the activities that are already working <br />
        </p>
        <p>
          To make sure everything is find, please click on the copy to clipboard the link to the activity page <br />
        </p>
        <p>
          NOTE: STEIL might already be present (use the activity id to check if the page works). <br />
        </p>
        <TableContainer variant="outlined" square component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Activity Id</TableCell>
                <TableCell>Contact Id</TableCell>
                <TableCell>First name</TableCell>
                <TableCell>Last name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activities.map(({ idToRestore, expectedContactData }) => (
                <TableRow key={idToRestore}>
                  <TableCell>
                    {corruptionStatuses[idToRestore] ? (
                      <MaterialIcon icon="check" style={{ color: 'green' }} />
                    ) : (
                      <MaterialIcon icon="close" style={{ color: 'red' }} />
                    )}
                  </TableCell>
                  <TableCell>{idToRestore}</TableCell>
                  <TableCell>{expectedContactData.id}</TableCell>
                  <TableCell>{expectedContactData.firstName}</TableCell>
                  <TableCell>{expectedContactData.lastName}</TableCell>
                  <TableCell>{expectedContactData.email}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => onRestoreActivity(idToRestore)}>
                      <MaterialIcon icon="build" />
                    </IconButton>
                    <IconButton onClick={() => copyToClipboard(`/app/activities/d/${idToRestore}`)}>
                      <MaterialIcon icon="content_copy" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </LargeBox>
    </React.Fragment>
  )
};

export default ActivitiesToRestoreList;
