import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import MaterialIcon from '@material/react-material-icon';

const Wrapper = styled(Avatar)`
  &.ui-avatar {
    background-color: ${({ avatarcolor = '#0078D4' }) => avatarcolor };
    color: #fff;
    height: 35px;
    width: 35px;
    font-size: 14px;
  }
`;

const AvatarIcon = styled(MaterialIcon)`
  font-size: 26px;
`;

const UserAvatar = ({ firstName, lastName, avatar, ...props }) => {
  if (firstName && lastName) {
    return (
      <Wrapper classes={{ root: 'ui-avatar' }} avatarcolor={avatar ? avatar.color : '#0078D4'} {...props}>
        { firstName[0] } { lastName[0] }
      </Wrapper>
    );
  } else {
    return (
      <Wrapper classes={{ root: 'ui-avatar' }} avatarcolor={avatar ? avatar.color : '#0078D4'} {...props}>
        <AvatarIcon icon="person" />
      </Wrapper>
    );
  }
};

UserAvatar.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  avatar: PropTypes.shape({
    color: PropTypes.string.isRequired,
  }),
};

export default UserAvatar;
