export const OPEN_SIDE_MENU = "OPEN_SIDE_MENU";

export const openSideMenuReducer = (global, dispatch) => ({
  sideMenu: {
    ...global.sideMenu,
    opened: true
  }
});

openSideMenuReducer.action = OPEN_SIDE_MENU;

export const CLOSE_SIDE_MENU = "CLOSE_SIDE_MENU";

export const closeSideMenuReducer = (global, dispatch) => ({
  sideMenu: {
    ...global.sideMenu,
    opened: false
  }
});

closeSideMenuReducer.action = CLOSE_SIDE_MENU;

export const TOGGLE_SIDE_MENU = "TOGGLE_SIDE_MENU";

export const toggleSideMenuReducer = global => ({
  sideMenu: {
    ...global.sideMenu,
    opened: !global.sideMenu.opened
  }
});

toggleSideMenuReducer.action = TOGGLE_SIDE_MENU;

export const SET_SELECTED_SIDE_MENU_ITEM = "SET_SELECTED_SIDE_MENU_ITEM";

export const setSelectedSideMenuItemReducer = (
  global,
  dispatch,
  { selectedMenuItem }
) => ({
  sideMenu: {
    ...global.sideMenu,
    selectedMenuItem
  }
});

setSelectedSideMenuItemReducer.action = SET_SELECTED_SIDE_MENU_ITEM;
