import React, { useGlobal } from 'reactn';

const CanSeeUsingRole = ({ role, children }) => {
  const [
    loginData
  ] = useGlobal('loginData');

  if (!loginData.user) {
    return null;
  }

  if (loginData.user.role !== 'ADMIN' && loginData.user.role !== role ) {
    return null;
  }

  return children;
};

export default CanSeeUsingRole;
