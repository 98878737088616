import parseJwt from './parse-jwt';

const logoutOnJwtExpiration = function () {
  const tokenData = parseJwt(localStorage.getItem('API_ACCESS_TOKEN'));
  if (tokenData && tokenData.exp < Date.now() / 1000) {
    localStorage.clear();
    window.location.replace('/signin');
  }
};

export default logoutOnJwtExpiration;
