import { useCallback, useEffect, useMemo, useState } from 'react';

const useOperation = (performOperation = {}) => {
  const [ loading, setLoading ] = useState(false);
  const [ success, setSuccess ] = useState(false);
  const [ data, setData ] = useState({});
  const [ error, setError ] = useState(false);

  const doOperation = useCallback(async (...args) => {
    try {
      setLoading(true);
      const result = await performOperation(...args);
      setData(result);
      setSuccess(true);
      setError(false);
      return result;
    } catch (err) {
      setSuccess(false);
      setError(err);
    } finally {
      setLoading(false);
    }
    return null;
  }, [performOperation]);

  const result = useMemo(() => {
    return {
      loading,
      success,
      data,
      error
    };
  }, [loading, success, data, error]);

  useEffect(() => {
    if (success) {
      setSuccess(false);
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      setError(false);
    }
  }, [error]);

  return [
    result,
    doOperation
  ]
};

export default useOperation;
