import React from 'react';
import { useHistory } from 'react-router-dom';
import Dialog from '../../ui/Dialog';
import MessageDialog from '../../ui/MessageDialog';

const ConfirmLogout = () => {
  const history = useHistory();
  const logout = React.useCallback(() => {
    localStorage.clear();
    window.location.pathname = '/signin';
  }, []);

  const goBack = React.useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <MessageDialog
      open
      title="Confirm logout"
      closeDialog={goBack}
      content="Are you sure you want to log out?"
      buttons={[
        { text: 'confirm', onClick: logout, type: 'ok' },
        { text: 'cancel', type: 'cancel' },
      ]}
    />
  )
};

export default ConfirmLogout;
