import React from 'react';
import MessageDialog from '../../../ui/MessageDialog';

const RemoveSubscriptionPlan = ({ subscriptionPlan, removeSubscriptionPlan, cancel }) => (
  <MessageDialog
    open
    title="Remove subscription plan"
    centerTitle
    closeDialog={cancel}
    buttons={[
      { text: 'Confirm', type: 'ok', onClick: removeSubscriptionPlan },
      { text: 'Cancel', type: 'cancel' }
    ]}
  >
    You are about to remove {subscriptionPlan.name}, this action is irreversible are you sure?
  </MessageDialog>
);

export default RemoveSubscriptionPlan;
