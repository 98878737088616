import React, { useCallback, useDispatch, useState } from "reactn";
import Login from "../components/Login";
import { useHistory } from 'react-router-dom';
import { apiLogin } from "../apiservice";
import { WRONG_CREDENTIALS_EXCEPTION } from "../exceptions";
import { LOGIN_REDUCER } from "../global/reducers";

const LoginContainer = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const login = useDispatch(LOGIN_REDUCER);
  const history = useHistory();

  const onLogin = useCallback(
    async ({ email, password }) => {
      try {
        setErrorMessage(null);
        const loginData = await apiLogin(email, password);
        await login({ loginData });
        window.location.pathname = '/';
      } catch (err) {
        if (err.name === WRONG_CREDENTIALS_EXCEPTION) {
          setErrorMessage("Your credential are invalid");
        } else {
          setErrorMessage("An unexpected Error Has Occurred");
        }
        throw err;
      }
    },
    [login, history]
  );

  return <Login login={onLogin} errorMessage={errorMessage} />;
};

export default LoginContainer;
