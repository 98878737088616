import React from "react";
import styled from "styled-components";
import MaterialIcon from "@material/react-material-icon";
import Button from "@material-ui/core/Button";

const Wrapper = styled.div`
  box-shadow: 0 1px 1px #ccc;
  background-color: #fff;
  border: 1px solid #eee;
  padding: 5px 7px 12px 7px;
  border-radius: 4px;
  width: 280px;
  position: relative;
  z-index: 2;
`;

const Icon = styled(MaterialIcon)`
  position: absolute;
  top: 12px;
  ${({ left = 0 }) => (left ? "left" : "right")}: 5px;
  font-size: 21px;
  color: #555;
  cursor: pointer;
`;

const Heading = styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  padding: 10px 0;
  text-align: center;
  border-bottom: 1px solid #ccc;
`;

export const DropDownBoxButton = styled(Button)`
  height: 38px;
  width: 100% !important;
  padding: 5px 10px !important;
  border-radius: 2px !important;
  justify-content: space-around !important;
  &:not(:hover) {
    background-color: rgba(9, 30, 66, 0.04) !important;
  }

  &:hover {
    background-color: rgba(9, 30, 66, 0.14) !important;
  }
  color: #000 !important;
  font-family: "Open Sans", sans-serif !important;
  text-transform: none !important;
  font-size: 13px !important;
  text-align: center;
  margin-right: 10px !important;
`;

const DropDownBox = ({
  title,
  children,
  onCloseClick,
  onBackClick,
  ...props
}) => (
  <Wrapper {...props}>
    {typeof onBackClick === "function" && (
      <Icon left={1} icon="keyboard_arrow_left" onClick={onBackClick} />
    )}
    <Icon icon="close" onClick={onCloseClick} />
    <Heading>{title}</Heading>
    {children}
  </Wrapper>
);

export default DropDownBox;
