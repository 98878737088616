import styled from "styled-components";
import { red } from "@material-ui/core/colors";

const ErrorMessage = styled.div`
  text-transform: lowercase;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  color: ${red.A700};
`;

export default ErrorMessage;
