import React from 'react';
import debounce from 'lodash/debounce';
import { ContentNavBar, NavBarActions } from '../../../ui/ContentNavBar';
import { Table, TableFooter, TableActionCell } from '../../../ui/Table';
import LargeBox from '../../../ui/LargeBox';
import Pagination from '../../../ui/Pagination';
import TooltipButton from '../../../ui/TooltipButton';
import TooltipLink from '../../../ui/TooltipLink';
import SearchClearableInput from '../../../common/components/SearchClearableInput';
import moment from 'moment';
import MessageDialog from '../../../ui/MessageDialog';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { NotificationManager } from 'react-notifications';
import { apiChangeOwner, apiCheckQuotas, apiDeleteSpace } from '../api';
import useGoBack from '../../../common/hooks/use-go-back';
import {
    useAddPropertiesToQueryString,
} from '../../../common/hooks/use-query';
import styled from 'styled-components';

const ErrorMessage = styled.p`
  color: red;
`;

const SpacesList = ({ spaces, setSpaces, total, currentPage, currentItemsPerPage, goToPage, users, getUsersBySpace, history, match, onRefresh, onSearchChange }) => {

    const [open, setOpen] = React.useState(false);
    const [owner, setOwner] = React.useState(false);
    const [newOwner, setNewOwner] = React.useState(false);
    const [space, setSpace] = React.useState(false);
    const [spaceDialogOpen, setSpaceDialogOpen] = React.useState(false);
    const [quotasStatus, setQuotasStatus] = React.useState(false);
    const options = users.filter(user => user.email !== owner);
    const goBack = useGoBack();
    const addPropertiesToQueryString = useAddPropertiesToQueryString();

    const checkQuotas = async (value) => {
        try {
            const checkQuotas = await apiCheckQuotas(space, value.email);
            setQuotasStatus(checkQuotas);
        } catch (err) {
            NotificationManager.error('Failed to check quotas. Please refresh or try again later');
        }
    };

    const onChangeOwnner = (_id, owner) => {
        history.push(`${match.url}?${addPropertiesToQueryString([
            ['action', 'change-owner'],
            ['owner', owner],
        ])}`);
        setOpen(true);
        setOwner(owner);
        getUsersBySpace(_id);
        setSpace(_id);
    }

    const onDeleteSpace = (spaceId) => {
        setSpace(spaceId);
        setSpaceDialogOpen(true);
    }

    const deleteSpace = async () => {
        try {
            const spaceDeletion = await apiDeleteSpace(space);
            if (spaceDeletion) {
                const spacesList = spaces.filter(space => space._id !== spaceDeletion);
                setSpaces(spacesList);
                setSpaceDialogOpen(false);
            }
        } catch (e) {
            NotificationManager.error('Failed to delete this space. Please refresh or try again later');
            setSpaceDialogOpen(false);
        }
    }

    const changeOwnner = async () => {
        try {
            const changeOwner = await apiChangeOwner(space, owner, newOwner);
            if (changeOwner) {
                NotificationManager.success('Success');
                goBack();
            }
        } catch (err) {
            NotificationManager.error('Failed to change owner. Please refresh or try again later');
        } finally {
            setOwner(false);
            setNewOwner(false);
            setSpace(false);
            setOpen(false);
        }
    };

    const handleSearchChange = React.useCallback(debounce(onSearchChange, 500), [onSearchChange]);

    const buttons = [{ text: 'Cancel', type: 'cancel' }];

    if (options.length > 0 && quotasStatus && quotasStatus.checkQuotas) {
        buttons.push({
            text: 'Change', type: 'ok', onClick: changeOwnner,
        });
    }

    return (
        <LargeBox heading="Spaces">
            <ContentNavBar>
                <NavBarActions>
                    <TooltipButton icon="refresh" text="refresh" onClick={onRefresh} />
                    <SearchClearableInput placeholder="Search ..." searchProperty="search" onChange={handleSearchChange} />
                </NavBarActions>
            </ContentNavBar>
            <Table isEmpty={total === 0} isEmptyComponent="There are no spaces.">
                <thead>
                    <tr>
                        <th className="text-cell">Name</th>
                        <th className="text-cell">Owner first name</th>
                        <th className="text-cell">Owner last name</th>
                        <th className="text-cell">Owner email</th>
                        <th className="text-cell">Created At</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        spaces.map((space, key) => (
                            <tr key={key}>
                                <td className="text-cell">{space.name}</td>
                                <td className="text-cell">{space.subscriber && space.subscriber.firstName}</td>
                                <td className="text-cell">{space.subscriber && space.subscriber.lastName}</td>
                                <td className="text-cell">{space.subscriber && space.subscriber.email}</td>
                                <td className="text-cell">{moment(space.createdAt).format('YYYY-DD-MM HH:mm')}</td>
                                <TableActionCell>
                                    <TooltipButton
                                        icon="people_alt"
                                        text="Change Owner"
                                        onClick={() => onChangeOwnner(space._id, space.subscriber && space.subscriber.email)}
                                    />
                                    <TooltipLink
                                        icon="people"
                                        text="Space users"
                                        to={`/spaces/${space._id}/users/list?owner=${(space.subscriber && encodeURIComponent(space.subscriber.email))}`}
                                    />
                                    <TooltipButton
                                        icon="delete"
                                        text="Remove space"
                                        onClick={() => onDeleteSpace(space._id)}
                                    />
                                </TableActionCell>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
            <TableFooter>
                <Pagination
                    currentPage={currentPage}
                    currentItemsPerPage={currentItemsPerPage}
                    onPageClick={goToPage}
                    total={total}
                />
            </TableFooter>
            <MessageDialog
                open={open}
                title="Change Owner"
                centertitle="true"
                closeDialog={() => {
                    setOpen(false);
                    setOwner(false);
                    setNewOwner(false);
                }}
                buttons={buttons}
            >
                {
                    options.length === 0 ? <p>Sorry, There is no user can be owner for this space</p> : <>
                        <Autocomplete
                            options={users.filter(user => user.email !== owner)}
                            getOptionLabel={(option) => option.email}
                            style={{ width: 400 }}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    checkQuotas(newValue);
                                } else {
                                    setQuotasStatus(false);
                                }
                            }}
                            onInputChange={(event, owner) => {
                                setNewOwner(owner);
                            }}
                            renderInput={(params) => <TextField {...params} label="Choose a new owner" />}
                        />
                        {
                            (quotasStatus && !quotasStatus.subscription) && <ErrorMessage>
                                Sorry, you cannot change the ownership<br />
                                <strong>the selected user does not have subscription</strong>
                            </ErrorMessage>
                        }
                        {
                            (quotasStatus && quotasStatus.subscription && (!quotasStatus.subscriptionActive || quotasStatus.subscriptionExpired)) && <ErrorMessage>
                                Sorry, you cannot change the ownership<br />
                                <strong>the user's subscription was expired or not active</strong>
                            </ErrorMessage>
                        }
                        {
                            (quotasStatus && quotasStatus.subscription && quotasStatus.subscriptionActive && !quotasStatus.checkQuotas) && <ErrorMessage>
                                Sorry, you cannot change the ownership<br />
                                <strong>you must upgrade subscription for the selected user</strong>
                            </ErrorMessage>
                        }

                    </>
                }
            </MessageDialog>
            <MessageDialog
                open={spaceDialogOpen}
                title="Delete Space"
                centertitle="true"
                closeDialog={() => {
                    setSpace(false);
                    setSpaceDialogOpen(false);
                }}
                buttons={[
                    { text: 'Cancel', type: 'cancel' },
                    { text: 'Delete', type: 'ok', onClick: deleteSpace, }
                ]}
            >
                Warning! do you want realy delete this space ?
            </MessageDialog>
        </LargeBox>
    );
};

export default SpacesList;
