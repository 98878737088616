import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import MaterialIcon from "@material/react-material-icon";
import Button from "@material-ui/core/Button";

const Wrapper = styled(Button)`
  &.side-menu-item {
    padding: ${({ opened }) =>
      opened ? "11px 5px 11px 15px" : "11px 9px 11px 9px"};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    min-width: 0;
  }

  .side-menu-item-label {
    text-transform: none;
  }
`;

const Icon = styled(MaterialIcon)`
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  padding: 0 6px;
`;

const Title = styled.span`
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: ${({ small = false }) => (small ? "13px" : "14px")};
  line-height: 16px;
  padding: 0 6px;
  display: ${({ opened }) => (opened ? "inline" : "none")};
`;

const SideMenuItem = ({ small, icon, opened, selected, title, onClick }) => (
  <Wrapper
    selected={selected ? 1 : 0}
    opened={opened ? 1 : 0}
    classes={{
      root: "side-menu-item",
      label: "side-menu-item-label"
    }}
    onClick={onClick}
  >
    {icon && <Icon title={title} icon={icon} />}
    <Title small={small} opened={opened ? 1 : 0}>
      {title}
    </Title>
  </Wrapper>
);

SideMenuItem.propTypes = {
  icon: PropTypes.string,
  opened: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func
};

SideMenuItem.defaultProps = {
  opened: true,
  small: false
};

export default SideMenuItem;
