import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import MaterialIcon from "@material/react-material-icon";
import Button from "@material-ui/core/Button";
import { Transition } from "react-transition-group";
import useTogglableState from "../../../common/hooks/use-togglable-state";

const Wrapper = styled(Button)`
  &.side-menu-item {
    background-color: inherit;
    padding: ${({ expanded }) =>
      expanded ? "11px 5px 11px 15px" : "11px 9px 11px 9px"};
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-width: 0;
  }

  .side-menu-item-label {
    text-transform: none;
  }
`;

const Icon = styled(MaterialIcon)`
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  padding: 0 6px;
`;

const Title = styled.span`
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 16px;
  padding: 0 6px;
  display: ${({ expanded }) => (expanded ? "inline" : "none")};
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const DropdownContent = styled.div`
  padding-left: 35px;
  display: flex;
  flex-direction: column;
  transition: max-height ease-in-out 200ms;
  overflow: hidden;
  max-height: ${({ state }) => {
    switch (state) {
      case "entered":
        return "200px";
      case "exited":
        return "0";
      case "entering":
        return "0";
      case "exiting":
        return "200px";
      default:
        return "200px";
    }
  }};
  ${({ expanded }) => (expanded ? "" : "max-height: 0;")}
`;

const ExpandIcon = styled(Icon)`
  justify-self: flex-end;
  margin-right: 10px;
`;

const SideMenuItemDropdown = ({ icon, expanded, opened, title, children }) => {
  const [dropdown, toggleDropdown, setDropdown] = useTogglableState(false);

  useEffect(() => {
    setDropdown(opened);
  }, [opened]);
  return (
    <div>
      <Wrapper
        expanded={expanded ? 1 : 0}
        classes={{
          root: "side-menu-item",
          label: "side-menu-item-label"
        }}
        onClick={toggleDropdown}
      >
        <TitleWrapper>
          <Icon title={title} icon={icon} />
          <Title expanded={expanded ? 1 : 0}>{title}</Title>
        </TitleWrapper>
        <ExpandIcon icon={dropdown ? "expand_less" : "expand_more"} />
      </Wrapper>
      <Transition in={dropdown} timeout={0}>
        {animationState => (
          <DropdownContent expanded={expanded} state={animationState}>
            {children}
          </DropdownContent>
        )}
      </Transition>
    </div>
  );
};

SideMenuItemDropdown.propTypes = {
  icon: PropTypes.string.isRequired,
  expanded: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onClick: PropTypes.func
};

SideMenuItemDropdown.defaultProps = {
  expanded: true
};

export default SideMenuItemDropdown;
