import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import Label from '../../../ui/Label';
import FormikTextInput from '../../../ui/FormikTextInput';
import FormikErrorMessage from '../../../ui/FormikErrorMessage';
import FormGroup from '../../../ui/FormGroup';
import LargeBox from '../../../ui/LargeBox';
import Button from '../../../ui/Button';
import SectionLoader from '../../../ui/SectionLoader';
import { submitOnce } from '../../../common/lib/formik-helpers';

const validationSchema = yup.object({
    numberOfDays: yup.number().required('This field is required'),
});

const Outboxes = ({ save, form, loading }) => {
    const submit = React.useCallback(submitOnce(save), [save]);
    return (
        <LargeBox heading="Outboxes expiration">
            <Formik
                validationSchema={validationSchema}
                initialValues={{
                    numberOfDays: form?.numberOfDays || 5,
                }}
                enableReinitialize={true}
                onSubmit={submit}
            >
                {
                    ({ handleSubmit }) => (
                        <FormGroup>
                            <Label htmlFor="numberOfDays">Number of days</Label>
                            <FormikTextInput name="numberOfDays" type="number"/>
                            <FormikErrorMessage name="numberOfDays" />
                            <br />
                            <Button onClick={handleSubmit} type="submit" color="success">Save</Button>
                        </FormGroup>

                    )
                }
            </Formik>
            {loading && <SectionLoader />}
        </LargeBox>
    )
};

export default Outboxes;