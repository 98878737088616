import { useCallback, useState } from "react";

const useTogglableState = (initialState, { onToggleState } = {}) => {
  const [state, setState] = useState(initialState);
  const toggleState = useCallback(() => {
    if (state === initialState) {
      setState(!initialState);
    } else {
      setState(initialState);
    }
    if (typeof onToggleState === "function") {
      onToggleState(!initialState);
    }
  }, [state]);
  return [state, toggleState, setState];
};

export default useTogglableState;
