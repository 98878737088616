import React from 'react';
import { Field } from 'formik';
import compact from 'lodash/compact';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MaterialCheckbox from '@material-ui/core/Checkbox';
import isArray from 'lodash/isArray';

const isChecked = (fieldValue, value) => {
  if (typeof fieldValue === 'string' &&  compact(fieldValue.split(',')).includes(value)) {
    return true;
  }

  return fieldValue === true;
};

const Checkbox = ({ name, value, label }) => {
  return (
    <Field name={name}>
      {({ field, form }) => (
        <FormControlLabel
          control={
            <MaterialCheckbox
              {...field}
              checked={isChecked(field.value, value)}
              onChange={() => {
                const fieldValue = typeof field.value === 'string' ? compact(field.value.split(',')) : field.value;
                if (isArray(fieldValue)) {
                  let nextValue = null;
                  if (fieldValue.includes(value)) {
                    nextValue = fieldValue.filter(v => v !== value).join(',');
                  } else {
                    nextValue = fieldValue.concat(value).join(',');
                  }
                  form.setFieldValue(name, nextValue);
                } else {
                  if (fieldValue === value) {
                    form.setFieldValue(name, !value);
                  } else {
                    form.setFieldValue(name, value);
                  }
                }
              }}
              color="primary"
            />
          }
          label={label}
        />
      )}
    </Field>
  );
};

export default Checkbox;
