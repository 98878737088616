import React from 'react';
import debounce from 'lodash/debounce';
import { ContentNavBar, NavBarActions } from '../../../ui/ContentNavBar';
import { Table, TableFooter } from '../../../ui/Table';
import LargeBox from '../../../ui/LargeBox';
import Pagination from '../../../ui/Pagination';
import TooltipButton from '../../../ui/TooltipButton';
import SearchClearableInput from '../../../common/components/SearchClearableInput';
import moment from 'moment';
import MessageDialog from '../../../ui/MessageDialog';
import CanSeeUsingRole from '../../../common/components/CanSeeUsingRole';

const LogsList = ({ logs, total, currentPage, currentItemsPerPage, goToPage, onRefresh, onSearchChange, onDeleteAll, open, setOpen }) => {

    const handleSearchChange = React.useCallback(debounce(onSearchChange, 500), [onSearchChange]);

    const buttons = [{ text: 'Yes', type: 'ok', onClick: onDeleteAll },{ text: 'No', type: 'cancel' }];

    return (
        <LargeBox heading="Logs">
            <ContentNavBar>
                <NavBarActions>
                    <TooltipButton icon="refresh" text="refresh" onClick={onRefresh} />
                </NavBarActions>
                <NavBarActions>
                    <SearchClearableInput placeholder="Search ..." searchProperty="search" onChange={handleSearchChange} />
                    <CanSeeUsingRole role="ADMIN">
                        <TooltipButton
                            icon="delete"
                            onClick={() => setOpen(true)}
                            text="Delete all logs"
                            color="#eb5a46"
                        />
                    </CanSeeUsingRole>
                </NavBarActions>
            </ContentNavBar>
            <Table isEmpty={total === 0} isEmptyComponent="There are no logs.">
                <thead>
                    <tr>
                        <th className="text-cell">Message</th>
                        <th className="text-cell">Type</th>
                        <th className="text-cell">Created At</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        logs.map((log, key) => (
                            <tr key={key}>
                                <td className="text-cell">{log.message}</td>
                                <td className="text-cell">{log.type}</td>
                                <td className="text-cell">{moment(log.createdAt).format('YYYY-DD-MM HH:mm')}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
            <TableFooter>
                <Pagination
                    currentPage={currentPage}
                    currentItemsPerPage={currentItemsPerPage}
                    onPageClick={goToPage}
                    total={total}
                />
            </TableFooter>
            <MessageDialog
                open={open}
                title="Remove logs"
                centertitle="true"
                closeDialog={()=> setOpen(false)}
                buttons={buttons}
            >
                <p>You are about to delete all logs, this action is irreversible are you sure?</p>
            </MessageDialog>
        </LargeBox>
    );
};

export default LogsList;
