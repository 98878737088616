import apiClientRequest from '../../common/apiclient';

export const apiUpdateSubscriber = async (id, {
  firstName, lastName, email, companyName, phone, line1, line2, city, country, zip,
}) =>
  apiClientRequest(`/update-subscriber/${id}`, {
    method: 'PATCH',
    data: {
      firstName, lastName, email, companyName, phone, line1, line2, city, country, zip,
    }
  });
