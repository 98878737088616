import React from 'reactn';
import IdleTimer from 'react-idle-timer';
import {Switch, Route, Redirect } from "react-router-dom";
import LoginContainer from "./pages/Login/containers/LoginContainer";
import AppLayoutContainer from "./pages/Main/containers/AppLayoutContainer";
import appConfig from './config';

const Entry = () => {
  const logout = React.useCallback(() => {
    localStorage.clear();
    window.location.pathname = '/signin';
  }, []);

  if (localStorage.getItem('API_ACCESS_TOKEN')) {
    return (
      <IdleTimer
        onIdle={logout}
        timeout={1000 * 60 * (+appConfig.idleTimeout || 60)}
      >
        <Switch>
          <Route path="/">
            <AppLayoutContainer />
          </Route>
        </Switch>
      </IdleTimer>
    );
  } else {
    return (
      <Switch>
        <Route path="/signin">
          <LoginContainer />
        </Route>
        <Redirect from="/" to="/signin" />
      </Switch>
    );
  }
};

export default Entry;
