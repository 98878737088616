import React from 'react';
import { useHistory } from 'react-router-dom';
import { apiCreateSubscription, apiGetAllSubscriptionPlans } from '../api';
import { NotificationManager } from 'react-notifications';
import CreateNewSubscriptionDialog from '../components/CreateNewSubscriptionDialog';
import { useQueryMap } from '../../../common/hooks/use-query';
import useAsyncOperation from '../../../common/hooks/use-async-operation';
import { apiGetSubscriberById } from '../../../common/api';

const CreateNewSubscriptionContainer = () => {
  const history = useHistory();
  const queryMap = useQueryMap();
  const [plans, setPlans] = React.useState(null);
  const [subscriber, setSubscriber] = React.useState(null);
  const [asyncCreateSubscription, subscriptionCreation] = useAsyncOperation(apiCreateSubscription);

  const goBack = React.useCallback(() => {
    history.goBack();
  }, [history]);

  React.useEffect(() => {
    const fetchSubscriber = async () => {
      try {
        const result = await apiGetSubscriberById(queryMap.subscriberId);
        setSubscriber(result);
      } catch (err) {
        NotificationManager.error('Failed to fetch subscriber information. Please refresh or try again later');
      } finally {
      }
    };

    fetchSubscriber();
  }, [queryMap.subscriberId]);

  React.useEffect(() => {
    const fetchPlans = async () => {
      try {
        const result = await apiGetAllSubscriptionPlans(queryMap.subscriberId);
        setPlans(result);
      } catch (err) {
        NotificationManager.error('Failed to fetch plans');
      }
    };

    fetchPlans();
  }, [queryMap.subscriberId]);

  const createSubscription = React.useCallback(async ({ plan, subscriber }) => {
    try {
      await asyncCreateSubscription({ planId: plan, subscriberId: subscriber });
      NotificationManager.success('Subscription has been successfully created');
      goBack();
    } catch (err) {
      if (err.data && err.data.name === 'ACTIVE_SUBSCRIPTION_EXISTS_EXCEPTION') {
        NotificationManager.error('There is already an active subscription');
      } else {
        NotificationManager.error('Something went wrong');
      }
    }
  }, [goBack, asyncCreateSubscription]);

  if (plans && subscriber) {
    return (
      <CreateNewSubscriptionDialog
        cancel={goBack}
        subscriber={subscriber}
        plans={plans}
        createSubscription={createSubscription}
        loading={subscriptionCreation.loading}
      />
    );
  }

  return null;
};

export default CreateNewSubscriptionContainer;
