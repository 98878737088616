import React, { useState, useEffect } from 'react';
import Breadcrumb from '../../../ui/Breadcrumb';
import { useRouteMatch } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { apiUnsubscribeUserFromSpace, apiGetUsers } from '../api';
import SpaceUsersList from '../components/SpaceUsersList';
import useGoBack from '../../../common/hooks/use-go-back';

const SpaceUsersListContainer = () => {

    const match = useRouteMatch();
    const goBack = useGoBack();

    const [
        users,
        setUsers
    ] = useState([]);

    useEffect(() => {
        const fetch = async () => {
            try {
                const result = await apiGetUsers(match.params.spaceId);
                setUsers(result);
            } catch (err) {
                NotificationManager.error('Failed to load users. Please refresh or try again later');
            }
        };

        fetch();
    }, [match.params]);

    const onUnsubscribeUserFromSpace = async (space, user) => {
        try {
            const unsubscribedUser = await apiUnsubscribeUserFromSpace(space, user);
            if (unsubscribedUser) {
                NotificationManager.success('Success');
                goBack();
            }
        } catch (err) {
            NotificationManager.error('Failed to unsubscribe user from space. Please refresh or try again later');
        }
    };

    return (
        <>
            <Breadcrumb
                items={[
                    { title: 'Space users list' }
                ]}
            />
            <SpaceUsersList
                match={match}
                users={users}
                onUnsubscribeUserFromSpace={onUnsubscribeUserFromSpace}
            />
        </>
    );
};

export default SpaceUsersListContainer;
