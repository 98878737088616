import React from "react";
import styled from "styled-components";
import { Scrollbars } from "react-custom-scrollbars";
import SideMenuContainer from "../containers/SideMenuContainer";
import HeaderContainer from "../containers/HeaderContainer";
import SubscriptionPlansRoutes from '../../../routes/SubscriptionPlansRoutes';
import SubscribersRoutes from '../../../routes/SubscribersRoutes';
import WorkflowTemplatesRoutes from '../../../routes/WorkflowTemplatesRoutes';
import { MQ_LARGE_DOWN } from '../../../common/breakpoints';
import { Route, Redirect, Switch } from 'react-router-dom';
import LegacyUsersList from '../../LegacyUsersList/LegacyUsersList';
import AppAccessRoute from '../../../common/components/AppAccessRoute';
import SuperUsersList from '../../SuperUsersList/SuperUserslist';
import ConfirmLogout from '../../ConfirmLogout/ConfirmLogout';
import SystemFixesRoutes from '../../../routes/SystemFixesRoutes';
import SpacesRoutes from '../../../routes/SpacesRoutes';
import SettingsRoutes from '../../../routes/SettingsRoutes';
import LogsRoutes from "../../../routes/LogsRoutes";
import ChangePasswordEmailUserRoutes from "../../../routes/ChangePasswordEmailUserRoutes";
import EmailCampaignsRoutes from "../../../routes/EmailCampaingsRoutes";
import CorruptedActivitiesRoutes from '../../../routes/CorruptedActivitiesRoutes';
import RestoreContactsRoutes from '../../../routes/RestoreContactsRoutes';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  background-color: #f8f9fa;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 10px;
`;

const Main = styled.main`
  position: relative;
  top: 50px;
  background-color: #f8f9fa;
  width: 100%;
  height: calc(100% - 50px);
  padding: 5px 5px 0 10px;

  @media ${MQ_LARGE_DOWN} {
    padding-left: 50px;
  }
`;

const Content = styled(Scrollbars)`
  height: calc(100vh - 50px);
`;

const AppLayout = () => (
  <Wrapper>
    <SideMenuContainer />
    <Container>
      <HeaderContainer />
      <Main>
        <Content autoHide autoHideDuration={100}>
          <Switch>
            <Route path="/workflows">
              <WorkflowTemplatesRoutes/>
            </Route>
            <Route path="/subscription-plans">
              <SubscriptionPlansRoutes />
            </Route>
            <Route path="/subscribers">
              <SubscribersRoutes />
            </Route>
            <Route path="/other-fixes">
              <SystemFixesRoutes />
            </Route>
            <AppAccessRoute role="ADMIN" path="/super-users">
              <SuperUsersList/>
            </AppAccessRoute>
            <Route path="/legacy-users">
              <LegacyUsersList />
            </Route>
            <Route path="/signin">
              <ConfirmLogout />
            </Route>
            <Route path="/spaces">
              <SpacesRoutes />
            </Route>
            <Route path="/settings">
              <SettingsRoutes />
            </Route>
            <Route path="/logs">
              <LogsRoutes />
            </Route>
            <Route path="/email-campaigns">
              <EmailCampaignsRoutes />
            </Route>
            <Route path="/user">
              <ChangePasswordEmailUserRoutes />
            </Route>
            <Route path="/corrupted-activities">
              <CorruptedActivitiesRoutes />
            </Route>
            <Route path="/restore-contacts">
              <RestoreContactsRoutes />
            </Route>
            <Redirect from="/" to="/subscription-plans" exact />
          </Switch>
        </Content>
      </Main>
    </Container>
  </Wrapper>
);

export default AppLayout;
