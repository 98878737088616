import React from 'react';
import { useHistory } from 'react-router-dom';
import MigrateUserDialog from '../components/MigrateUserDialog';
import { NotificationManager } from 'react-notifications';
import { apiGetSubscriptionPlans, apiMigrateLegacyUsers } from '../api';
import useAsyncOperation from '../../../common/hooks/use-async-operation';

const MigrateUserDialogContainer = () => {
  const history = useHistory();
  const [plans, setPlans] = React.useState(null);
  const [migrateLegacyUsers, operation] = useAsyncOperation(apiMigrateLegacyUsers);
  const goBack = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const migrate = React.useCallback(async ({ plan }) => {
    try {
      await migrateLegacyUsers({ plan });
      goBack();
    } catch (err) {
      NotificationManager.error('Something wrong happened. Please refresh or try again later');
    }
  }, [goBack]);

  React.useEffect(() => {
    const fetch = async () => {
      try {
        const result = await apiGetSubscriptionPlans();
        setPlans(result);
      } catch (err) {
        NotificationManager.error('Something wrong happened. Please refresh or try again later');
        goBack();
      }
    };

    fetch();
  }, [goBack]);

  if (plans) {
    return (
      <MigrateUserDialog
        migrating={operation.loading}
        plans={plans}
        goBack={goBack}
        migrate={migrate}
      />
    )
  }

  return null;
};
export default MigrateUserDialogContainer;
