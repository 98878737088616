import React from 'react';
import { NotificationManager } from 'react-notifications';
import { apiChangeUserEmail } from '../api';
import useAsyncOperation from '../../../common/hooks/use-async-operation';
import useGoBack from '../../../common/hooks/use-go-back';
import ChangeUserEmail from '../components/ChangeUserEmail';

const ChangeUserEmailContainer = () => {
  const goBack = useGoBack();
  const [asyncChangeUserEmail, userEmailAlteration] = useAsyncOperation(apiChangeUserEmail);

  const save = React.useCallback(async ({ oldEmail, newEmail }) => {
    try {
      await asyncChangeUserEmail({
        oldEmail,
        newEmail,
      });
      NotificationManager.success(`Your email has been successfully updated.`);
      localStorage.clear();
      window.location.pathname = "/";
      
    } catch (err) {
      if (err.status === 403) {
        NotificationManager.error(err.data?.error || err.message);
      } else {
        NotificationManager.error('Failed to change your email. Please refresh or try again later');
      }
    }
  }, [goBack, asyncChangeUserEmail]);

  return (
    <ChangeUserEmail
      cancel={goBack}
      save={save}
      loading={userEmailAlteration.loading}
    />
  )
};

export default ChangeUserEmailContainer;

