import React from 'react';
import styled from 'styled-components';
import { Field, formik } from 'formik';
import MuiCheckbox from '@material-ui/core/Checkbox';
import Label from './Label';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const Checkbox = ({ name, children, ...props }) => (
  <Wrapper>
    <Label htmlFor={name}>{children}</Label>
    <MuiCheckbox name={name} {...props} />
  </Wrapper>
);

export default Checkbox;

export const FormikCheckbox = ({ name, value, ...props }) => (
  <Field name={name}>
    {
      ({ field, form }) => (
        <Checkbox
          name={field.name}
          value={field.value}
          checked={form.values[name].includes(value)}
          {...field}
          onChange={event => {
            const { checked } = event.target;
            if (checked) {
              form.setFieldValue(field.name, form.values[name].concat(value));
            } else {
              form.setFieldValue(field.name, form.values[name].filter(x => x !== value));
            }
            form.setFieldTouched(field.name);
          }}
          {...props}
        />
      )
    }
  </Field>
);
