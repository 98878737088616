import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import LegacyUsersList from '../components/LegacyUsersList';
import { NotificationManager } from 'react-notifications';
import { apiGetLegacyUsers } from '../api';

const LegacyUsersListContainer = () => {
  const [users, setUsers] = React.useState([]);
  const history = useHistory();
  const match = useRouteMatch();

  React.useEffect(() => {
    const fetch = async () => {
      try {
        const result = await apiGetLegacyUsers();
        setUsers(result);
      } catch (err) {
        NotificationManager.error('Failed to load legacy users. Please refresh or try again later');
      }
    };

    fetch();

    return history.listen((location, action) => {
      if (action === 'POP') {
        fetch();
      }
    });
  }, [history]);

  const migrate = React.useCallback(() => {
    history.push(`${match.url}/migrate`);
  }, [history, match.url]);

  return (
    <LegacyUsersList users={users} onMigrateClick={migrate} />
  )
};

export default LegacyUsersListContainer;
