import apiClientRequest from "../../common/apiclient";
import LoginData from "./models/login-data";
import { SUPER_USER_CREDENTIALS_ARE_WRONG_EXCEPTION } from "./apiexceptions";
import { WrongCredentialsException } from "./exceptions";
import { UnexpectedServerException } from "../../common/exceptions";
import { API_ACCESS_TOKEN } from "../../common/constants";

export const apiLogin = async (email, password) => {
  try {
    const data = await apiClientRequest("/auth/login", {
      method: "POST",
      data: {
        email,
        password
      }
    });

    return new LoginData(data);
  } catch (err) {
    if (
      err &&
      err.data &&
      err.data.name === SUPER_USER_CREDENTIALS_ARE_WRONG_EXCEPTION
    ) {
      throw new WrongCredentialsException();
    } else {
      throw new UnexpectedServerException();
    }
  }
};

export const isUserLoggedIn = () => {
  return localStorage.getItem(API_ACCESS_TOKEN);
};

export const getCurrentUser = async () => {};
