import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, .85);
  z-index: 1;
`;

const SectionLoader = () => (
  <Wrapper>
    <CircularProgress />
  </Wrapper>
);

export default SectionLoader;
