import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { usePaginate } from '../../../common/hooks/use-paginate';
import Breadcrumb from '../../../ui/Breadcrumb';
import LogsList from '../components/LogsList';
import { NotificationManager } from 'react-notifications';
import {
  useAddPropertyToQueryString,
  useQueryMap
} from '../../../common/hooks/use-query';
import { apiGetLogs, apiDeleteAllLogs } from '../api';

const LogsListContainer = () => {

  const [open, setOpen] = React.useState(false);

  const [
    currentPage,
    currentItemsPerPage,
    goToPage
  ] = usePaginate();

  const [
    logs,
    setLogs
  ] = useState([]);

  const [
    total,
    setTotal
  ] = useState(0);

  const addPropertyToQueryString = useAddPropertyToQueryString();
  const history = useHistory();
  const match = useRouteMatch();

  const queryMap = useQueryMap();

  const { search: currentSearch } = queryMap;


  const fetchData = async () => {
    try {
      const { logs, total } = await apiGetLogs({
        page: currentPage,
        itemsPerPage: currentItemsPerPage,
        search: currentSearch,
      });

      setLogs(logs);
      setTotal(total);
    } catch (err) {
      NotificationManager.error('Failed to load logs. Please refresh or try again later');
    }
  };

  useEffect(() => {
    fetchData();
    return history.listen((location, action) => {
      if (action === 'POP') {
        fetchData();
      }
    });
  }, [currentPage, currentItemsPerPage, currentSearch, history]);

  const onSearchChange = React.useCallback((search) => {
    history.push(`${match.url}?${addPropertyToQueryString(['search', search])}`);
  }, [history, match.url, addPropertyToQueryString]);

  
  const onRefresh = React.useCallback(() => {
    fetchData();
  }, []);

  const onDeleteAll = React.useCallback(async () => {
    try {
      await apiDeleteAllLogs();
      fetchData();
      setOpen(false);
    } catch (err) {
      NotificationManager.error('Failed to delete logs. Please refresh or try again later');
    }
  }, [history]);
  return (
    <>
      <Breadcrumb
        items={[
          { title: 'Logs' }
        ]}
      />
      <LogsList
        logs={logs}
        currentPage={currentPage}
        currentItemsPerPage={currentItemsPerPage}
        currentSearch={currentSearch}
        total={total}
        goToPage={goToPage}
        onRefresh={onRefresh}
        onSearchChange={onSearchChange}
        onDeleteAll={onDeleteAll} 
        setOpen={setOpen} open={open}/>
    </>
  );
};

export default LogsListContainer;
