import React from 'react';
import { Route } from 'react-router-dom';
import WorkflowTemplatesDirs from "../pages/Workflows/containers/workflow-templates-dirs/list";
import WorkflowTemplatesDirsAdd from "../pages/Workflows/containers/workflow-templates-dirs/add";
import WorkflowTemplateDirEdit from "../pages/Workflows/containers/workflow-templates-dirs/edit";
import WorkflowTemplatesAdd from "../pages/Workflows/containers/workflow-templates/add";
import WorkflowTemplateEdit from "../pages/Workflows/containers/workflow-templates/edit";
import WorkflowTemplates from "../pages/Workflows/containers/workflow-templates/list";
import AppAccessSwitch from '../common/components/AppAccessSwitch';

const WorkflowTemplatesRoutes = () => (
  <AppAccessSwitch role="ADMIN">
      <Route path="/workflows/templates/edit/:id" component={WorkflowTemplateEdit} />
      <Route path="/workflows/templates/add" component={WorkflowTemplatesAdd} />
      <Route path="/workflows/templates" component={WorkflowTemplates} />
      <Route path="/workflows/templateDirs/edit/:id" component={WorkflowTemplateDirEdit} />
      <Route path="/workflows/templateDirs/add" component={WorkflowTemplatesDirsAdd} />
      <Route path="/workflows/templateDirs/:id" component={WorkflowTemplates} />
      <Route path="/workflows/templateDirs" component={WorkflowTemplatesDirs} />
  </AppAccessSwitch>
);

export default WorkflowTemplatesRoutes;
