import React from 'react';
import { Table } from '../../../ui/Table';
import printDate from '../../../common/lib/print-date';
import FIX_NAME from '../models/fix-name';
import TooltipLink from '../../../ui/TooltipLink';

const SystemFixesList = ({ fixes }) => (
  <Table isEmptyComponent="There are no fixes" isEmpty={fixes.length === 0}>
    <thead>
    <tr>
      <th className="text-cell">Name</th>
      <th className="text-cell">Fixed</th>
      <th className="date-cell">Fix Date</th>
      <th>Actions</th>
    </tr>
    </thead>
    <tbody>
    {
      fixes.map(fix => (
        <tr key={fix._id}>
          <td className="text-cell">{FIX_NAME[fix.name]}</td>
          <td className="text-cell">{fix.fixed ? 'Yes' : 'No'}</td>
          <td className="date-cell">{printDate(fix.fixDate)}</td>
          <td>
            {
              !fix.deactivated && (
                <TooltipLink text="Run" icon="play_arrow" to={`?action=run-fix&id=${fix._id}`} />
              )
            }
          </td>
        </tr>
      ))
    }
    </tbody>
  </Table>
);

export default SystemFixesList;
