import moment from 'moment';

const getSubscriptionStatus = subscription => {
  if (!subscription) {
    return 'none';
  }

  if (subscription.status !== 'ACTIVE') {
    return subscription.status;
  }

  if (subscription.expirationDate && moment().diff(subscription.expirationDate) > 0) {
    return 'EXPIRED';
  } else {
    return 'ACTIVE';
  }
};

export default getSubscriptionStatus;
