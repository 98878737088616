import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SubscriptionPlansList from '../components/SubscriptionPlansList';
import { NotificationManager } from 'react-notifications';
import { apiGetSubscriptionsPlans } from '../api';
import Breadcrumb from '../../../ui/Breadcrumb';
import { usePaginate } from '../../../common/hooks/use-paginate';

const SubscriptionPlansListContainer = () => {
  const [
    currentPage,
    currentItemsPerPage,
    goToPage,
  ] = usePaginate();
  const history = useHistory();

  const [subscriptionPlans, setSubscriptionPlans] = React.useState({
    data: [],
    total: 0,
  });

  useEffect( () => {
    const fetch = async () => {
      try {
        const { subscriptionPlans, total } = await apiGetSubscriptionsPlans({ page: currentPage, itemsPerPage: currentItemsPerPage });
        setSubscriptionPlans({
          data: subscriptionPlans,
          total,
        });
      } catch (err) {
        NotificationManager.error('Failed to load subscriptions');
      }
    };

    fetch();

    return history.listen((pathname, action) => {
      if (action === 'POP') {
        fetch();
      }
    })
  }, [currentPage, currentItemsPerPage, history]);
  return (
    <React.Fragment>
      <Breadcrumb
        items={[
          { title: 'Subscription Plans' }
        ]}
      />
      <SubscriptionPlansList
        subscriptionPlans={subscriptionPlans.data}
        currentPage={currentPage}
        currentItemsPerPage={currentItemsPerPage}
        total={subscriptionPlans.total}
        goToPage={goToPage}
      />
    </React.Fragment>
  )
};

export default SubscriptionPlansListContainer;
