import { addCallback, addReducer } from "reactn";

const makeGlobalState = (initialState = {}, callbacks = [], reducers = []) => {
  if (typeof initialState !== "object") {
    throw new Error(
      `expected initial state to be an object, ${typeof initialState} was given`
    );
  }

  if (!Array.isArray(callbacks)) {
    throw new Error(
      `expected callbacks to be an array, ${typeof initialState} was given`
    );
  }

  callbacks.forEach((callback, index) => {
    if (typeof callback !== "function") {
      throw new Error(
        `expected callback at index ${index} to be a function, ${typeof initialState} was given`
      );
    }
  });

  reducers.forEach((reducer, index) => {
    if (typeof reducer !== "function") {
      throw new Error(
        `expected reducer at ${index} to be a function, ${typeof initialState} was given`
      );
    }
  });

  return {
    initialState,
    callbacks: () => {
      callbacks.forEach(callback => {
        addCallback(callback);
      });
    },
    reducers: () => {
      reducers.forEach(reducer => {
        addReducer(reducer.action, reducer);
      });
    }
  };
};

export default makeGlobalState;
