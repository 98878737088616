import React from 'reactn';
import SystemFixesList from '../components/SystemFixesList';
import { FETCH_SYSTEM_FIXES } from '../gstate/reducers';

const SystemFixesListContainer = () => {
  const [fixes] = React.useGlobal('systemFixes');
  const fetchSystemFixes = React.useDispatch(FETCH_SYSTEM_FIXES);

  React.useEffect(() => {
    fetchSystemFixes();
  }, [fetchSystemFixes]);

  return (
    <SystemFixesList fixes={fixes} />
  );
};

export default SystemFixesListContainer;
