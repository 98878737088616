import React from 'react';
import CustomFieldValue from './CustomFieldValue';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box } from '@material-ui/core';
import TagInput from '../../ui/TagInput';

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    padding: theme.spacing(1, 0),
    '& > *:not(last-of-type)': {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    }
  },
}));

const CustomFieldValuesList = ({ values = [], onAddClick, onDeleteClick }) => {
  const classes = useStyles();

  const [value, setValue] = React.useState('');
  const onChange = React.useCallback((evt) => {
    setValue(evt.target.value);
  }, []);
  const add = () => {
    if (value) {
      onAddClick(value);
      setValue('');
    }
  };

  return (
    <Box className={classes.root}>
      {values.map((value, key) => (
        <CustomFieldValue
          key={key}
          content={value}
          onDeleteClick={() => onDeleteClick(value)}
        />
      ))}
      <TagInput onAddClick={add} value={value} onChange={onChange} />
    </Box>
  )
};

export default CustomFieldValuesList;
