import React from 'react';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import Breadcrumb from '../../ui/Breadcrumb';
import LargeBox from '../../ui/LargeBox';
import MaterialIcon from '@material/react-material-icon';
import SectionLoader from '../../ui/SectionLoader';

const truncate = value => {
  if (typeof value === 'string') {
    return value.substr(0, 35);
  }

  return value;
};

const CorruptedActivitiesList = ({ isLoading, activities, onRestore, contactsCount, restoreResult, onRefresh }) => {

  return (
    <React.Fragment>
      <Breadcrumb
        items={[
          { title: 'Corrupted activities' },
        ]}
      />
      <LargeBox
        heading="Corrupted activities"
      >
        {isLoading && <SectionLoader />}
        <Box pt={2} pb={2} mb={2} alignItems="center" display="flex" flexDirection="row" justifyContent="space-between">
          <Box display="column">
            <Typography variant="body1">
              There are {activities.length} corrupted activities and {contactsCount} contacts to restore.
            </Typography>
            {restoreResult ? (
              <Typography variant="body1">
                {restoreResult.fixedActivities} activities has been fixed and {restoreResult.restoredContacts} contacts has been restored.
              </Typography>
            ) : (
              <Typography variant="body1">
                No result available
              </Typography>
            )}
          </Box>
          <Box display="flex" flex="row">
            <IconButton size="small" onClick={onRestore}>
              <MaterialIcon icon="build" />
            </IconButton>
            <IconButton size="small" onClick={onRefresh}>
              <MaterialIcon icon="refresh" />
            </IconButton>
          </Box>
        </Box>
        <TableContainer style={{ height: 'calc(100vh - 300px)'}} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>id</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>First name</TableCell>
                <TableCell>Last name</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Mobile</TableCell>
                <TableCell>Web</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Created at</TableCell>
                <TableCell>Updated at</TableCell>
                <TableCell>Space name</TableCell>
                <TableCell>Space owner</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activities.map(activity => (
                <TableRow>
                  <TableCell>{activity.instanceId}</TableCell>
                  <TableCell>{activity.contact.title}</TableCell>
                  <TableCell>{activity.contact.email}</TableCell>
                  <TableCell>{activity.contact.firstName}</TableCell>
                  <TableCell>{activity.contact.lastName}</TableCell>
                  <TableCell>{activity.contact.phone}</TableCell>
                  <TableCell>{activity.contact.mobile}</TableCell>
                  <TableCell>{activity.contact.web ? 'exists' : 'no'}</TableCell>
                  <TableCell>{truncate(activity.contact.company)}</TableCell>
                  <TableCell>{activity.createdAt}</TableCell>
                  <TableCell>{activity.updatedAt}</TableCell>
                  <TableCell>{truncate(activity.space.name)}</TableCell>
                  <TableCell>{truncate(activity.space.user.firstName)} {truncate(activity.space.user.lastName)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </LargeBox>
    </React.Fragment>
  )
};

export default CorruptedActivitiesList;
