import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Select from "./Select";

const StyledSelect = styled(Select)`
  font-size: 14px;
  margin: 0 5px;
`;

const PageItemsPerPageSelect = ({
  currentItemsPerPage,
  onItemsPerPageSelect,
  itemsPerPageOptions
}) => {
  const selectItemsPerPage = evt => {
    const { value } = evt.target;
    onItemsPerPageSelect(+value);
  };

  return (
    <StyledSelect value={currentItemsPerPage} onChange={selectItemsPerPage}>
      {itemsPerPageOptions.map(value => (
        <option key={value} value={value}>
          {value}
        </option>
      ))}
    </StyledSelect>
  );
};

PageItemsPerPageSelect.propTypes = {
  currentItemsPerPage: PropTypes.number,
  itemsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  onItemsPerPageSelect: PropTypes.func.isRequired
};

PageItemsPerPageSelect.defaultProps = {
  currentItemsPerPage: 10,
  itemsPerPageOptions: [10, 50, 100]
};

export default PageItemsPerPageSelect;
