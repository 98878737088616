import React from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import FormDialog from '../../../ui/FormDialog';
import { submitOnce } from '../../../common/lib/formik-helpers';
import FormGroup from '../../../ui/FormGroup';
import Label from '../../../ui/Label';
import FormikSelect2 from '../../../ui/FormikSelect2';
import TextInput from '../../../ui/formik/TextInput';
import { CustomErrorMessage } from '../../../ui/formik/CustomErrorMessage';
import SectionLoader from '../../../ui/SectionLoader';

const validationSchema = yup.object({
  plan: yup.string().required('plan is required'),
});

const fullName = subscriber => `${subscriber.firstName} ${subscriber.lastName}`;

const CreateNewSubscriptionDialog = ({ createSubscription, loading, subscriber, plans, cancel }) => {
  const [dialogReady, setDialogReady] = React.useState(false);
  const setDialogToReady = React.useCallback(() => {
    setDialogReady(true);
  }, []);

  const submit = React.useCallback(submitOnce(values => {
    createSubscription({
      subscriber: values.subscriber,
      plan: values.plan.value,
    });
  }), [createSubscription]);

  const plansOptions = React.useMemo(() => {
    return plans.map(({ name, _id }) => ({
      value: _id,
      label: name,
    }));

  }, [plans]);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        subscriber: subscriber._id,
        plan: '',
      }}
      onSubmit={submit}
    >
      {
        ({ handleSubmit }) => (
          <FormDialog
            open
            title="Create Subscription"
            closeDialog={cancel}
            buttons={[
              { text: 'confirm', type: 'ok', onClick: handleSubmit },
              { text: 'cancel', type: 'cancel' },
            ]}
            onEntered={setDialogToReady}
          >
            { loading && <SectionLoader />}
            <Form>
              <FormGroup>
                <Label htmlFor="subscriber">Subscriber</Label>
                <TextInput readOnly value={fullName(subscriber)} />
                <input type="hidden" name="subscriber" />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="plan">Plan</Label>
                <input type="hidden" name="plan" />
                {
                  dialogReady && (
                    <FormikSelect2
                      placeholder="Please choose a plan"
                      name="plan"
                      options={plansOptions}
                      menuPortalTarget={document.querySelector('.MuiDialog-container')}
                    />
                  )
                }
                <CustomErrorMessage name="plan" />
              </FormGroup>
            </Form>
          </FormDialog>
        )
      }
    </Formik>
  )
};

export default CreateNewSubscriptionDialog;
