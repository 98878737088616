import { Field } from 'formik';
import compact from 'lodash/compact';
import React from 'react';
import CustomFieldValuesList from './CustomFieldValuesList';

const FormikCustomFieldValuesList = ({ name, className }) => (
  <Field name={name}>
    {({ field, form }) => {
      const values = field.value ? compact(field.value.split(',')) : [];
      const add = value => {
        const newValues = [
          ...values,
          value,
        ];
        form.setFieldValue(field.name, newValues.join(','));
      };

      const remove = value => {
        const newValues = compact(values.filter(v => v !== value));
        form.setFieldValue(field.name, newValues.join(','));
      };

      return (
        <CustomFieldValuesList
          className={className}
          values={values}
          onAddClick={add}
          onDeleteClick={remove}
        />
      );
    }}
  </Field>
);

export default FormikCustomFieldValuesList;
