import apiClientRequest from '../../common/apiclient';

export const apiGetTawktoChatID = () => apiClientRequest(`/settings/chat/tawkto`, {
  method: 'GET',
});

export const apiCreateOrUpdateTawktoChatSettings = ({ tawktoId, disabled }) => apiClientRequest(`/settings/chat/tawkto`, {
    method: 'POST',
    data: { tawktoId, disabled },
  });
