import React from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import FormDialog from '../../../ui/FormDialog';
import { submitOnce } from '../../../common/lib/formik-helpers';
import FormGroup from '../../../ui/FormGroup';
import Label from '../../../ui/Label';
import TextInput from '../../../ui/formik/TextInput';
import SelectInput from '../../../ui/formik/SelectInput';
import FormikErrorMessage from '../../../ui/FormikErrorMessage';
import SectionLoader from '../../../ui/SectionLoader';
import {
  useQueryMap,
} from '../../../common/hooks/use-query';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  category: yup.string().required('Category is required'),
  tags: yup.array().of(yup.string()),
  template: yup.mixed()
    .test('fileType', "Unsupported File Format", files => {
      const allowedExtensions = /(\.json)$/i;
      if(files) {
          return Array.from(files).every(file => allowedExtensions.test(file.name));
      }
      return true;
    })
    .when("id", {
      is: undefined,
      then: yup.mixed().required('Template is required')
    }),
  templateThumbnail: yup.mixed()
    .test('fileType', "Unsupported File Format", files => {
      const allowedExtensions = /(\.pdf|\.png|\.jpeg|\.jpg|\.gif)$/i;
      if (files) {
          return Array.from(files).every(file => allowedExtensions.test(file.name));
      }
      return true;
    })
    .when("id", {
      is: undefined,
      then: yup.mixed().required('Template thumbnail is required')
    }),
});

const EmailTemplateFormDialog = ({ title, template, categories, tags, save, loadTemplate, open, cancel, loading }) => {
  const history = useHistory();
  const submit = React.useCallback(submitOnce(save), [save]);

  const queryMap = useQueryMap();
  const { id } = queryMap;
  React.useEffect(() => {
    loadTemplate(id);
  }, [id])

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        id,
        name: template?.description || '',
        category: template?.metadata?.emailTemplateDetails?.category || '',
        tags: template?.metadata?.emailTemplateDetails?.tags || [],
        template: null,
        templateThumbnail: null,
      }}
      enableReinitialize
      onSubmit={submit}>
      {
        ({ handleSubmit, setFieldValue, handleChange, values }) => (
          <FormDialog
            open={open}
            title={title}
            closeDialog={cancel}
            buttons={[
              { text: 'Save', type: 'ok', onClick: handleSubmit },
              { text: 'Cancel', type: 'cancel' }
            ]}
            onExit={()=> history.push('templates')}
          >
            {
              loading && (
                <SectionLoader />
              )
            }
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label htmlFor="category">Category</Label>
                <SelectInput
                    name="category"
                    fill='true'
                >
                    <option key="00001" value="">Please choose a category</option>
                    {
                      categories.map(category => <option key={category._id} value={category._id}>{category.name}</option>)
                    }
                </SelectInput>
                <FormikErrorMessage name="category" />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="tag">Tag</Label>

                <Select variant="outlined"
                  name="tags"
                  multiple
                  displayEmpty
                  value={values.tags}
                  onChange={handleChange}
                  input={<Input variant="outlined"/>}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <span style={{ color: '#333'}}>Please choose a tag</span>;
                    }
                    return selected.map((value) => {
                      let { name } = tags.find(tag => tag._id === value);
                      return name;
                    }).join(', ');
                  }}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {
                    tags.map(tag =>
                      <MenuItem key={tag._id} value={tag._id}>
                        {tag.name}
                      </MenuItem>
                    )
                  }

                </Select>
                <FormikErrorMessage name="tags" />
              </FormGroup>

              <FormGroup>
                <Label htmlFor="name">
                  Name
                </Label>
                <TextInput name="name" fill='true'/>
                <FormikErrorMessage name="name" />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="template">
                  Template
                </Label>
                <TextInput fill='true'
                  name="templateFile"
                  type='file'
                  accept=".json"
                  onChange={(event) => {
                    setFieldValue("template", event.currentTarget.files);
                  }}
                />
                <FormikErrorMessage name="template" />
              </FormGroup>
            <FormGroup>
                <Label htmlFor="template-thumbnail">
                  Template Thumbnail
                </Label>
                <TextInput
                  name="templateThumbnailFile" fill='true'
                  type='file'
                  accept="image/*"
                  onChange={(event) => {
                    setFieldValue("templateThumbnail", event.currentTarget.files);
                  }}
                />
                <FormikErrorMessage name="templateThumbnail" />
              </FormGroup>
            </Form>
          </FormDialog>
        )
      }
    </Formik>
  );
};

export default EmailTemplateFormDialog;
