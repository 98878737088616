import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CanSeeUsingRole from '../../src/common/components/CanSeeUsingRole';
import useQuery from '../common/hooks/use-query';

const StyledArrowUpwardIcon = styled(ArrowUpwardIcon)`
    vertical-align: bottom
`;

const StyledArrowDownwardIcon = styled(ArrowDownwardIcon)`
    vertical-align: bottom
`;

const TableHeader = ({ header, history }) => {
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('createdAt');
    const query = useQuery();

    const getSearchTerm = useCallback(() => {
        return query.get('search') || '';
    }, [query]);
    const search = getSearchTerm();

    const getPage = useCallback(() => {
        return query.get('page') || '';
    }, [query]);
    const page = getPage();

    const getItemsPerPage = useCallback(() => {
        return query.get('itemsPerPage') || '';
    }, [query]);
    const itemsPerPage = getItemsPerPage();

    const getFromDate = useCallback(() => {
        return query.get('fromDate') || '';
    }, [query]);
    const fromDate = getFromDate();

    const getToDate = useCallback(() => {
        return query.get('toDate') || '';
    }, [query]);
    const toDate = getToDate();

    useEffect(() => {
        const params = [];
        if (order) {
            params.push(`order=${order}`);
        }
        if (orderBy) {
            params.push(`orderBy=${orderBy}`)
        }
        if (search) {
            params.push(`search=${search}`)
        }
        if (page) {
            params.push(`page=${page}`)
        }
        if (itemsPerPage) {
            params.push(`itemsPerPage=${itemsPerPage}`)
        }
        if (fromDate) {
            params.push(`fromDate=${fromDate}`)
        }
        if (toDate) {
            params.push(`toDate=${toDate}`)
        }
        history.push(`?${params.join('&')}`);
    }, [order, orderBy]);

    const sort = (orderBy, order) => {
        setOrderBy(orderBy);
        setOrder(order);
    }

    return (
        header && header.map((entry, key) => {
            return (
                entry.role ? <CanSeeUsingRole role={entry.role}>
                    <th key={key} style={{ cursor: 'pointer', textAlign: entry.textAlign }} onClick={() => sort(entry.orderBy, order === 'desc' ? 'asc' : 'desc')}>
                        {
                            orderBy === entry.orderBy ? (
                                order === 'desc' ? <StyledArrowDownwardIcon /> : <StyledArrowUpwardIcon />
                            ) : null
                        }
                        {entry.column}
                    </th>
                </CanSeeUsingRole> : <th key={key} style={{ cursor: 'pointer', textAlign: entry.textAlign }} onClick={() => sort(entry.orderBy, order === 'desc' ? 'asc' : 'desc')}>
                        {
                            orderBy === entry.orderBy ? (
                                order === 'desc' ? <StyledArrowDownwardIcon /> : <StyledArrowUpwardIcon />
                            ) : null
                        }
                        {entry.column}
                    </th>
            )
        })
    )
};

export default TableHeader;
