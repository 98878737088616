import React from 'react';
import styled from 'styled-components';
import MuiButton from '@material-ui/core/Button';

const Button = styled(MuiButton)`
  &.submit-button {
    width: 150px;
    align-self: flex-end;
    &:not(:hover) {
      background-color: #0078d4;
    }
    
    &:hover {
      background-color: #0078d4dd;
    }
    color: #fff;
    text-transform: none;
    border-radius: 2px;
  }
`;

const SubmitButton = (props) => (
  <Button classes={{
    root: 'submit-button',
  }} {...props} />
);

export default SubmitButton
