import React from 'react';
import { useQueryMap } from '../hooks/use-query';
import ClearableInput from '../../ui/ClearableInput';

const SearchClearableInput = ({ searchProperty, ...props }) => {
  const query = useQueryMap();
  const searchValue = query[searchProperty] || '';

  return (
    <ClearableInput defaultValue={searchValue} {...props} />
  );
};

export default SearchClearableInput;
