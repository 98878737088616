import React, { useCallback, useState } from "react";
import styled from "styled-components";
import MuiInput from '@material-ui/core/Input';
import Snackbar from "@material-ui/core/Snackbar";
import * as yup from "yup";
import { Form, Formik } from "formik";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "../../../ui/Button";
import bg from "../../../images/bg.png";
import Label from "../../../ui/Label";
import FormGroup from "../../../ui/FormGroup";
import { MAIN_COLOR } from "../../../common/styles/colors";
import FormikErrorMessage from "../../../ui/FormikErrorMessage";
import Alert from "../../../ui/Alert";
import ErrorMessage from "../../../ui/ErrorMessage";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${bg});
  background-position: center center;
`;

const Box = styled.div`
  width: 450px;

  @media only screen and (max-device-width: 670px) {
    width: 100%;
  }

  padding: 40px 50px;
  background-color: #efefef;
  box-shadow: 0 0 1px #333;
`;

const Actions = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
`;

const Heading = styled.h2`
  @media only screen and (max-device-width: 670px) and (orientation: landscape) {
    display: none;
  }

  margin: 25px 0;
  font-family: Roboto, sans-serif;
  font-size: 21px;
  font-weight: normal;
  color: ${MAIN_COLOR};
`;

const SubHeading = styled.h3`
  @media only screen and (max-device-width: 670px) and (orientation: landscape) {
    display: none;
  }
  margin: 20px 0 40px 0;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: normal;
  color: #777;
`;

const loginSchema = yup.object({
  email: yup
    .string()
    .required("Champ requis")
    .email("Invalid Email"),
  password: yup
    .string()
    .required("Champ requis")
});

const Login = ({ login, errorMessage }) => {
  const [status, setStatus] = useState(null);

  const handleLoginSubmit = useCallback(
    async (values, { setSubmitting }) => {
      try {
        await login(values);
        setStatus("success");
      } catch (err) {
        setStatus("error");
      } finally {
        setSubmitting(false);
      }
    },
    [login]
  );

  const handleClose = useCallback(() => {
    setStatus(null);
  }, []);

  return (
    <Wrapper>
      <Box>
        <Heading>Bienvenue à Prediwell Admin</Heading>
        <Formik
          initialValues={{
            email: "",
            password: ""
          }}
          validationSchema={loginSchema}
          onSubmit={handleLoginSubmit}
        >
          {({
            errors,
            values,
            touched,
            handleSubmit,
            isSubmitting,
            handleBlur,
            handleChange
          }) => (
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label>Email</Label>
                <MuiInput
                  type="text"
                  value={values.email}
                  error={!!errors.email && touched.email}
                  name="email"
                  placeholder="example@test.xx"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <FormikErrorMessage name="email" />
              </FormGroup>
              <FormGroup>
                <Label>Mot de passe</Label>
                <MuiInput
                  type="password"
                  value={values.password}
                  error={!!errors.password && touched.password}
                  name="password"
                  placeholder="**********"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <FormikErrorMessage name="password" />
              </FormGroup>
              <Actions>
                <Button type="submit" variant="contained" color="primary">
                  {!isSubmitting ? (
                    "Connexion"
                  ) : (
                    <CircularProgress color="inherit" size={19} />
                  )}
                </Button>
                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
              </Actions>
            </Form>
          )}
        </Formik>
      </Box>
      <Snackbar
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={status === "error" && !!errorMessage}
        autoHideDuration={1000}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <Snackbar
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={status === "success"}
        autoHideDuration={2000}
      >
        <Alert severity="success">Successful Login!</Alert>
      </Snackbar>
    </Wrapper>
  );
};

export default Login;
