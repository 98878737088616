import apiClientRequest from '../../common/apiclient';

export const apiAssignSubscriber = ({ salesman, subscriber }) =>
  apiClientRequest('/subscriber-assignment', {
    method: 'POST',
    data: {
      salesman,
      subscriber,
    }
  });
