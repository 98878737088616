import apiClientRequest from '../../common/apiclient';

export const apiGetSubscriptionById = (subscriptionId) =>
  apiClientRequest(`/update-subscription/subscription/${subscriptionId}`);

export const apiUpdateSubscription = (subscriptionId, { expirationDate }) =>
  apiClientRequest(`/update-subscription/${subscriptionId}`, {
    method: 'PATCH',
    data: {
      expirationDate,
    }
  });
