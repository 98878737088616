import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import SubscriptionPlansListContainer from '../pages/SubscriptionPlansList/containers/SubscriptionPlansListContainer';
import AddSubscriptionPlanContainer from '../pages/AddSubscriptionPlan/containers/AddSubscriptionPlanContainer';
import EditSubscriptionPlanContainer from '../pages/EditSubscriptionPlan/containers/EditSubscriptionPlanContainer';
import AppAccessSwitch from '../common/components/AppAccessSwitch';
import RemoveSubscriptionPlan from '../pages/RemoveSubscriptionPlan/containers/RemoveSubscriptionPlanContainer';
import QueryParamRoute from '../common/components/QueryParamRoute';
import CanSeeUsingRole from '../common/components/CanSeeUsingRole';

const SubscriptionPlansRoutes = () => (
  <>
    <AppAccessSwitch role="SALESMAN">
      <Route path="/subscription-plans/list">
        <SubscriptionPlansListContainer />
      </Route>
      <Redirect from="/subscription-plans" to="/subscription-plans/list" exact />
    </AppAccessSwitch>
    <CanSeeUsingRole role="ADMIN">
      <QueryParamRoute property="action" value="add-subscription-plan">
        <AddSubscriptionPlanContainer />
      </QueryParamRoute>
    </CanSeeUsingRole>
    <CanSeeUsingRole role="ADMIN">
      <QueryParamRoute property="action" value="edit-subscription-plan" requiredParams={['subscriptionPlanId']}>
        <EditSubscriptionPlanContainer />
      </QueryParamRoute>
    </CanSeeUsingRole>
    <CanSeeUsingRole role="ADMIN">
      <QueryParamRoute property="action" value="remove-subscription-plan" requiredParams={['subscriptionPlanId']}>
        <RemoveSubscriptionPlan />
      </QueryParamRoute>
    </CanSeeUsingRole>
  </>
);

export default SubscriptionPlansRoutes;
