import styled from "styled-components";

const Label = styled.label`
  padding: 10px 0;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  color: #333;
  text-transform: capitalize;
`;

export default Label;
