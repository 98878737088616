import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import FormDialog from '../../../ui/FormDialog';
import { NotificationManager } from 'react-notifications';
import SectionLoader from '../../../ui/SectionLoader';
import {
  useQueryMap,
} from '../../../common/hooks/use-query';
import EmailEditor from 'react-email-editor';
import {
  apiGetTemplateContentById,
  apiSavetemplateContent,
  apiUploadMedia,
  apiRemoveMedia,
} from '../api';

const EmailTemplateEditor = ({ title, open, cancel }) => {
  const [editor, setEditor] = useState(null);
  const [content, setContent] = useState();
  const [loading, setLoading] = useState(false)
  const emailEditorRef = useRef(null);
  const history = useHistory();
  const queryMap = useQueryMap();
  const { id } = queryMap;

  React.useEffect(() => {
      setEditor(emailEditorRef);
      onLoad();
  }, [emailEditorRef?.current]);

  const saveContent = React.useCallback(async () => {
    try {
      setLoading(true);
      await apiSavetemplateContent({ id, content });
      history.push('templates')
      cancel();
    } catch (error) { console.log('content error:', error)
      NotificationManager.error('Failed to add email template. Please refresh or try again later');
    } finally {
      setLoading(false);
    }
  }, [id, content]);

  const onLoad = React.useCallback(async() => {
    const emaiEditor = editor?.current?.editor;
    if (emaiEditor) {
      if (id) {
        const template = await apiGetTemplateContentById(id);
        emaiEditor.loadDesign(template);
      }

      if(emaiEditor) {
        emaiEditor.registerCallback('image', async (file, done) => {
          const form = new FormData();
          form.append('media', file.attachments[0]);
          done ({ progress: 100, url: await apiUploadMedia(form) });
        });

        emaiEditor.addEventListener('design:updated',async ({ type: event, item }) => {
          const { type, values: val } = item;
          if (type === 'image') {
            console.log('design:updated pppp: ', val?.src);
            await apiRemoveMedia(val?.src);
          }

          emaiEditor.exportHtml(({ design }) => setContent(JSON.stringify(design)));
        });
      }
    }
  }, [editor, id]);

  return (
    <FormDialog
      open={open}
      title={title}
      closeDialog={cancel}
      buttons={[
        { text: 'Save', type: 'ok', onClick: saveContent },
        { text: 'Cancel', type: 'cancel' }
      ]}
      onExit={ () => history.push(`templates`)}
    >
      {
        loading && (
          <SectionLoader />
        )
      }

      <div style={{ width: '90vw', height: '80vh' }}>
        {editor &&
          <EmailEditor
            ref={editor}
          />
        }
      </div>
    </FormDialog>
  );
};

export default EmailTemplateEditor;
