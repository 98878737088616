import React from 'react';
import { Route } from 'react-router-dom';
import chatSetting from "../pages/Chat/chatSetting";
import AppAccessSwitch from '../common/components/AppAccessSwitch';
import GoogleAnalyticsSetting from "../pages/Google/GoogleAnalyticsSetting";
import GoogleTagsSetting from "../pages/Google/GoogleTagsSetting";
import OutboxesContainer from '../pages/Settings/containers/OutboxesContainer';

const SettingsRoutes = () => (
  <AppAccessSwitch role="ADMIN">
    <Route path="/settings/chat" component={chatSetting} />
    <Route path="/settings/google-analytics" component={GoogleAnalyticsSetting} />
    <Route path="/settings/google-tags" component={GoogleTagsSetting} />
    <Route path="/settings/outboxes" component={OutboxesContainer} />
  </AppAccessSwitch>
);

export default SettingsRoutes;
