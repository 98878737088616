import styled from 'styled-components';

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 17px;
  margin-bottom: 7px;
  border-bottom: 1px solid #ccc;
  
  > span {
    font-family: "Open Sans", sans-serif;
    text-transform: capitalize;
    font-weight: bold;
    color: #0078D4;
    font-size: 18px;
  }
`;

export const HeadingText = styled.span`
  font-family: "Open Sans", sans-serif;
  font-variant: normal;
  color: #0078D4;
  font-size: 18px;
  font-weight: bold;
`;

export default Heading;
