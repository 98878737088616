import React from 'react';
import { useHistory } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { useQueryMap } from '../../common/hooks/use-query';
import { apiSendSubscriptionCheckoutEmail } from './api';

const SendSubscriptionCheckoutEmail = () => {
  const history = useHistory();
  const queryMap = useQueryMap();

  React.useEffect(() => {
    const sendEmail = async () => {
      try {
        await apiSendSubscriptionCheckoutEmail(queryMap.subscriptionId);
        NotificationManager.success('Email has been sent');
      } catch (err) {
        NotificationManager.error('Something went wrong');
      } finally {
        history.goBack();
      }
    };

    sendEmail();
  }, [history, queryMap.subscriptionId]);

  return null;
};

export default SendSubscriptionCheckoutEmail;
