import React from 'reactn';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { NotificationContainer } from 'react-notifications';
import MomentUtils from '@date-io/moment';
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import indigo from "@material-ui/core/colors/indigo";
import { BrowserRouter as Router } from "react-router-dom";
import Normalize from "./common/styles/Normalize";
import General from "./common/styles/Global";
import "react-notifications/lib/notifications.css";
import createGlobalState from "./global/GlobalState";
import Entry from './Entry';

const theme = createMuiTheme({
  palette: {
    primary: indigo
  }
});

createGlobalState();

const App = () =>(
  <React.Fragment>
    <Normalize />
    <General />
    <NotificationContainer />
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <MuiThemeProvider theme={theme}>
        <Router>
          <Entry />
        </Router>
      </MuiThemeProvider>
    </MuiPickersUtilsProvider>
  </React.Fragment>
);

export default App;
