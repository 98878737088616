import React, { useState } from 'react';
import { ContentNavBar, NavBarActions } from '../../../ui/ContentNavBar';
import TooltipButton from '../../../ui/TooltipButton';
import { Table, TableFooter } from '../../../ui/Table';
import Pagination from '../../../ui/Pagination';
import LargeBox from '../../../ui/LargeBox';
import printDate from '../../../common/lib/print-date';
import SpacesDetails from '../../SpacesList/components/SpacesDetails';
import TabsNavigation from '../../../ui/TabsNavigation';
import styled from 'styled-components';

const Tab = styled.div`
  display: block;
  flex: 1;
`;

const UsersTab = styled(Tab)``;
UsersTab.tabName = 'Users';
const SpacesTab = styled(Tab)``;
SpacesTab.tabName = 'Spaces';

const UsersList = ({ onRefresh, total, users, currentPage, currentItemsPerPage, goToPage, subscriberId }) => {

  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <LargeBox heading="Details">
      <TabsNavigation tabIndex={activeIndex} setTabIndex={setActiveIndex}>
        <UsersTab>
          <ContentNavBar>
            <NavBarActions>
              <TooltipButton icon="refresh" onClick={onRefresh} />
            </NavBarActions>
            <NavBarActions>
            </NavBarActions>
          </ContentNavBar>
          <Table isEmpty={total === 0} isEmptyComponent="There are no users. Please click on + to add one">
            <thead>
              <tr>
                <th className="text-cell">Full Name</th>
                <th className="text-cell">Email</th>
                <th className="date-cell">Created At</th>
              </tr>
            </thead>
            <tbody>
              {
                users.map(subscriber => (
                  <tr key={subscriber.id}>
                    <td className="text-cell">{subscriber.lastName} {subscriber.firstName}</td>
                    <td className="text-cell">{subscriber.email}</td>
                    <td className="date-cell">{printDate(subscriber.createdAt)}</td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
          <TableFooter>
            <Pagination
              currentPage={currentPage}
              currentItemsPerPage={currentItemsPerPage}
              onPageClick={goToPage}
              total={total}
            />
          </TableFooter>
        </UsersTab>
        <SpacesTab>
          <SpacesDetails subscriberId={subscriberId} />
        </SpacesTab>
      </TabsNavigation>
    </LargeBox>
  )
};

export default UsersList;
