import useQuery from '../../common/hooks/use-query';
import { useHistory } from "react-router-dom";
import { useCallback, useState } from "react";

export const usePaginate = (
  getData,
  {
    dataFieldName = "",
    filters,
    listPath = (page, itemsPerPage) =>
      `list?page=${page}&itemsPerPage=${itemsPerPage}`
  },
  ...fetchDataArgs
) => {
  const query = useQuery();

  const history = useHistory();

  const [result, setResult] = useState({
    data: [],
    total: 0
  });

  const getPage = useCallback(() => {
    const queryPage = +query.get("page");
    return isNaN(queryPage) || queryPage < 1 ? 1 : queryPage;
  }, [query]);

  const getItemsPerPage = useCallback(() => {
    const queryItemsPerPage = +query.get("itemsPerPage");

    return isNaN(queryItemsPerPage) || queryItemsPerPage < 1
      ? 6
      : queryItemsPerPage;
  }, [query]);

  const currentPage = getPage();
  const currentItemsPerPage = getItemsPerPage();

  const fetchData = useCallback(
    async (
      { page = getPage(), itemsPerPage = getItemsPerPage() },
      additionalFilters = {}
    ) => {
      const fetchFilters = {
        ...filters,
        ...additionalFilters
      };

      setResult({
        ...result,
        loading: true,
        success: false,
        error: true
      });
      try {
        if(dataFieldName) {
          const { total, ...result } = await getData(
            { page, itemsPerPage },
            ...fetchDataArgs,
            fetchFilters
          );
          const data = result[dataFieldName];
          setResult({
            ...result,
            data,
            total,
            success: true,
            error: false,
            loading: false
          });
        } else {
          const data = await getData(
            { page, itemsPerPage },
            ...fetchDataArgs,
            fetchFilters
          );
          setResult({
            ...result,
            data: data[0],
            total: data[1],
            success: true,
            error: false,
            loading: false
          });
        }
      } catch (error) {
        setResult({
          ...result,
          success: false,
          loading: false,
          error
        });
      }
    },
    [
      dataFieldName,
      fetchDataArgs,
      filters,
      getData,
      getItemsPerPage,
      getPage,
      result
    ]
  );

  const refresh = useCallback(() => {
    const page = getPage();
    const itemsPerPage = getItemsPerPage();
    fetchData({
      page,
      itemsPerPage
    });
  }, [fetchData, getItemsPerPage, getPage]);

  const goToPage = useCallback(
    ({ page = getPage(), itemsPerPage = getItemsPerPage() }) => {
      history.push(listPath(page, itemsPerPage));
      fetchData({ page, itemsPerPage });
    },
    [fetchData, history, listPath, getPage, getItemsPerPage]
  );

  return [
    result,
    currentPage,
    currentItemsPerPage,
    goToPage,
    refresh,
    fetchData
  ];
};
