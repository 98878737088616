import React from 'react';

const useAsyncOperation = (operation) => {
  const [operationState, setOperationState] = React.useState({
    loading: false,
    success: false,
    error: false,
  });

  const decoratedOperation = React.useCallback(async (...args) => {
    try {
      setOperationState({
        loading: true,
        success: false,
        error: false,
      });
      const result = await operation(...args);
      setOperationState({
        loading: false,
        success: true,
        error: false,
      });
      return result;
    } catch (err) {
      setOperationState({
        loading: false,
        success: false,
        error: true,
      });
      throw err;
    }
  }, [operation]);

  return [
    decoratedOperation,
    operationState,
  ];
};

export default useAsyncOperation;
