import React  from 'react';
import { Field } from 'formik';
import RichText from '../RichText';

const FormikRichText = ({ name, ...props }) => {
  return (
    <Field name={name}>
      {
        ({ field, form }) => {
          const handleRichTextChange = (event, editor) => {
            const data = editor.getData();
            form.setFieldValue(name, data);
          };

          const handleRichTextBlur = () => {
            form.setFieldTouched(name);
          };

          return (
            <RichText
              {...props}
              {...field}
              data={field.value}
              onChange={handleRichTextChange}
              onBlur={handleRichTextBlur}
            />
          );
        }
      }
    </Field>
  );
};

export default FormikRichText;
