import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';

import Breadcrumb from '../../../../ui/Breadcrumb';
import LargeBox from '../../../../ui/LargeBox';
import TooltipButton from '../../../../ui/TooltipButton';
import ClearableInput from '../../../../ui/formik/ClearableInput';
import { ContentNavBar, NavBarActions } from '../../../../ui/ContentNavBar';
import { Table, TableFooter } from '../../../../ui/Table';
import { Row } from '../../../../ui/Flex';
import Pagination from '../../../../ui/Pagination';
import RctSectionLoader from '../../../../ui/RctSectionLoader';
import DeleteConfirmationDialog from '../../../../ui/DeleteConfirmationDialog';

import { usePaginate } from '../../../../common/hooks/use-paginate-deprecated';
import useQuery from '../../../../common/hooks/use-query';
import { useDeleteItem } from '../../../../common/hooks/use-delete-item';
import useOperation from '../../../../common/hooks/use-operation';
import { apiGetWorkflowTemplates, apiDeleteTemplate } from '../../api';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import TooltipLink from '../../../../ui/TooltipLink';

const FilterForm = styled(Form)`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const StyledArrowUpwardIcon = styled(ArrowUpwardIcon)`
    vertical-align: bottom
`;

const StyledArrowDownwardIcon = styled(ArrowDownwardIcon)`
    vertical-align: bottom
`;

const WorkflowTemplates = ({ history, match }) => {

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('name');
  const query = useQuery();

  const getSearchTerm = useCallback(() => {
    return query.get('search') || '';
  }, [query]);

  const getOrderBy = useCallback(() => {
    return query.get('orderBy') || '';
  }, [query]);

  const getOrder = useCallback(() => {
    return query.get('order') || '';
  }, [query]);

  const search = getSearchTerm();
  const [
    workflowTemplates,
    page,
    itemsPerPage,
    goToPage,
    onReload,
    getWorkflowTemplatesByFilter
  ] = usePaginate(apiGetWorkflowTemplates, {
    listPath: (page, itemsPerPage) => `/workflows/${match.params.id ? 'templateDirs' : 'templates'}?search=${search}&page=${page}&itemsPerPage=${itemsPerPage}&order=${getOrder()}&orderBy=${getOrderBy()}`,
    filters: {
      search: getSearchTerm(),
      orderBy: getOrderBy(),
      order: getOrder(),
    },
  }, match.params.id || '');

  const [
    TemplateDeletion,
    deleteTemplate
  ] = useOperation(apiDeleteTemplate);

  const [
    deleteTemplateDialogRef,
    onDeleteClick,
    onDeleteTemplate
  ] = useDeleteItem(deleteTemplate);

  useEffect(() => {
    if (TemplateDeletion.success) {
      onReload();
    }
    getWorkflowTemplatesByFilter({ page, itemsPerPage }, { order, orderBy, search });
  }, [order, TemplateDeletion.success]);

  const applyFilter = useCallback(({ search }) => {
    const params = [];
    if (search) {
      params.push(`search=${search}`);
    }

    if (page) {
      params.push(`page=${page}`)
    }

    if (itemsPerPage) {
      params.push(`itemsPerPage=${itemsPerPage}`)
    }

    history.push(`?${params.join('&')}`);

    getWorkflowTemplatesByFilter({ page, itemsPerPage }, { order, orderBy, search });
  }, [page, itemsPerPage]);

  const tableTH = useCallback((columnName, _orderBy, aling = 'leftAlign') => {
    return (
      <th className={aling} onClick={() => setOrderBy(_orderBy)}>
        {
          orderBy === _orderBy ? (
            order === 'desc' ? <StyledArrowDownwardIcon onClick={() => setOrder('asc')} /> : <StyledArrowUpwardIcon onClick={() => setOrder('desc')} />
          ) : null
        }
        {columnName}
      </th>
    )
  }, [order, orderBy])

  return (
    <div>
      <Breadcrumb
        items={[
          { title: 'templates' },
        ]}
      />
      <LargeBox heading="Templates">
        <ContentNavBar>
          <NavBarActions>
            <TooltipButton icon="refresh" text="refresh" onClick={onReload} />
          </NavBarActions>
          <NavBarActions>
            <Formik
              initialValues={{
                search
              }}
              onSubmit={applyFilter}
            >
              {
                ({ handleSubmit }) => (
                  <FilterForm onSubmit={handleSubmit}>
                    <ClearableInput
                      name="search"
                      type="text"
                      placeholder="Search ..."
                    />
                    <TooltipButton onClick={handleSubmit} icon="search" text='search' />
                  </FilterForm>
                )
              }
            </Formik>
            <TooltipLink icon="add" text="new workflow" to="/workflows/templates/add" />
          </NavBarActions>
        </ContentNavBar>
        <Table
          isEmpty={workflowTemplates.data.length === 0}
          isEmptyComponent="Sorry, there are no templates directories. To create one click on the add button."
        >
          <thead>
            <tr>
              {tableTH('Name', 'name', 'leftAlign')}
              {tableTH('Description', 'description', 'leftAlign')}
              {tableTH('Doc URL', 'docUrl', 'leftAlign')}
              {tableTH('Directory', 'workflowTemplateDir', 'leftAlign')}
              {tableTH('Lang', 'lang', 'leftAlign')}
              {tableTH('Default', 'isDefault', 'centerAlign')}
              <th className="centerAlign">Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              workflowTemplates && workflowTemplates.data.map((template) => (
                <tr key={template._id}>
                  <td className="leftAlign">{template.name}</td>
                  <td className="leftAlign">{template.description}</td>
                  <td className="leftAlign">{template.docUrl}</td>
                  <td className="leftAlign">{template.workflowTemplateDir && template.workflowTemplateDir.name}</td>
                  <td className="leftAlign">{template.lang}</td>
                  <td className="centertAlign">{template.isDefault ? 'Yes' : 'No'}</td>
                  <td className="centerAlign">
                    <Row>
                      <TooltipLink to={`/workflows/templates/edit/${template._id}`} icon="edit" text="Edit" />
                      <TooltipButton icon="close" text="remove" onClick={() => onDeleteClick({ id: template._id })} />
                    </Row>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
        {
          (workflowTemplates.loading || workflowTemplates.loading) && (
            <RctSectionLoader />
          )
        }
        <TableFooter>
          <Pagination
            currentPage={page}
            currentItemsPerPage={itemsPerPage}
            onPageClick={goToPage}
            total={workflowTemplates.total}
          />
        </TableFooter>
      </LargeBox>
      <DeleteConfirmationDialog
        ref={deleteTemplateDialogRef}
        title="Are You Sure Want To Delete?"
        message="This will delete template."
        onConfirm={onDeleteTemplate}
      />
    </div>
  )
};

export default WorkflowTemplates;
