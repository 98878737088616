import styled from 'styled-components';
import { Form as FormikForm } from 'formik';
import { MQ_SMALL_ONLY } from '../common/breakpoints';

const Form = styled(FormikForm)`
  width: 667px;
  grid-template-columns: 50% 50%;
  display: grid;
  
  @media ${MQ_SMALL_ONLY} {
    grid-template-columns: 100%;
  }
`;

export default Form;
