import React from 'react';
import Breadcrumb from '../../ui/Breadcrumb';
import SuperUsersListContainer from './containers/SuperUsersListContainer';
import { Route } from 'react-router-dom';
import AddSuperUserContainer from '../AddSuperUser/containers/AddSuperUserContainer';
import EditSuperUserContainer from '../EditSuperUser/containers/EditSuperUserContainer';
import RemoveSuperUserContainer from '../RemoveSuperUser/containers/RemoveSuperUserContainer';

const SuperUsersList = () => (
  <>
    <Breadcrumb
      items={[
        { title: 'SupersUsers' }
      ]}
    />
    <SuperUsersListContainer />
    <Route path="/super-users/add">
      <AddSuperUserContainer />
    </Route>
    <Route path="/super-users/edit/:id">
      <EditSuperUserContainer />
    </Route>
    <Route path="/super-users/remove/:id">
      <RemoveSuperUserContainer />
    </Route>
  </>
);

export default SuperUsersList;
