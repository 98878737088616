import { useCallback, useMemo } from 'react';
import { useHistory } from "react-router-dom";
import { useAddPropertiesToQueryString, useQueryMap } from '../../common/hooks/use-query';

export const usePaginate = () => {
  const history = useHistory();
  const queryMap = useQueryMap();
  const addPropertiesToQueryString = useAddPropertiesToQueryString();

  const currentPage = useMemo(() => {
    return +queryMap.page || 1;
  }, [queryMap]);

  const currentItemsPerPage = useMemo(() => {
    return +queryMap.itemsPerPage || 10;
  }, [queryMap]);

  const goToPage = useCallback(({ page = 1, itemsPerPage = 6 }) => {
    history.push('?' + addPropertiesToQueryString([
      ['page', page],
      ['itemsPerPage', itemsPerPage],
    ]));
  }, [addPropertiesToQueryString, history]);

  return [
    currentPage,
    currentItemsPerPage,
    goToPage,
  ];
};

