import { useState } from "react";

const usePopover = (popoverId = "simple-popover") => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? popoverId : undefined;

  return [anchorEl, open, id, handleClick, handleClose];
};

export default usePopover;
