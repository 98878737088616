import { setGlobal } from "reactn";
import loginPageGlobal from "../pages/Login/global";
import sideMenuGlobalState from "../pages/Main/global";

const createGlobalState = async () => {
  initGlobalStates([loginPageGlobal, sideMenuGlobalState]);
};

function initGlobalStates(globals) {
  globals.forEach(global => {
    setGlobal(global.initialState);
    global.callbacks();
    global.reducers();
  });
}

export default createGlobalState;
