import makeGlobalState from "../../../common/lib/make-global-state";
import { closeSideMenuReducer } from "./reducers";
import { openSideMenuReducer } from "./reducers";
import { toggleSideMenuReducer } from "./reducers";
import { setSelectedSideMenuItemReducer } from "./reducers";

export default makeGlobalState(
  {
    sideMenu: {
      selectedMenuItem: "",
      opened: true
    }
  },
  [],
  [
    openSideMenuReducer,
    closeSideMenuReducer,
    toggleSideMenuReducer,
    setSelectedSideMenuItemReducer
  ]
);
