import React, { useRef } from 'react';
import styled from 'styled-components';
import MaterialIcon from '@material/react-material-icon';

const InputWrapper = styled.div`
  position: relative;
`;

const CloseIcon = styled(MaterialIcon)`
  position: absolute;
  top: 5px;
  right: 7px;
  padding-top: 3px;
  padding-left: 3px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  
  &:active, &:hover {
    color: #fff;
    background-color: #777;
  }
  
  &:active {
    transform: scale(1.05);
  }
`;

const Input = styled.input`
  border-radius: 20px;
  padding: 8px 39px 8px 10px;
  width: 200px;
  border: 1px solid #aaa;
  font-size: 14px;
  
  &:focus {
    outline: 0;
  }
  
  &::placeholder {
    font-size: 14px;
  }
`;

const ClearableInput = ({ onChange, ...props }) => {
  const input = useRef(null);
  const clear = React.useCallback(() => {
    if (input.current && input.current.value) {
      input.current.value = '';
      if (typeof onChange === 'function') {
        onChange('');
      }
    }
  }, [onChange]);

  const fireChange = React.useCallback(evt => {
    const { value } = evt.target;
    if (typeof onChange === 'function') {
      onChange(value);
    }
  }, [onChange]);

  return (
    <InputWrapper>
      <Input {...props} onChange={fireChange} ref={input} />
      <CloseIcon className="clear-input-btn" onClick={clear} icon="close" />
    </InputWrapper>
  );
};

export default ClearableInput;
