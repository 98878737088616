import React from 'react';
import MessageDialog from '../../../ui/MessageDialog';
import SectionLoader from '../../../ui/SectionLoader';
import FormGroup from '../../../ui/FormGroup';
import Label from '../../../ui/Label';
import { CustomErrorMessage } from '../../../ui/formik/CustomErrorMessage';
import FormikRichText from '../../../ui/formik/FormikRichText';
import FormDialog from '../../../ui/FormDialog';
import { Formik } from 'formik';
import { submitOnce } from '../../../common/lib/formik-helpers';

const SubscriptionCancellation = ({ processing, subscription, cancelSubscription, onCancel }) => {
  const submit = React.useCallback(submitOnce(cancelSubscription), [cancelSubscription]);

  return (
    <Formik
      initialValues={{
        reason: '',
      }}
      onSubmit={submit}
    >
      {
        ({ handleSubmit }) => (
          <FormDialog
            open={true}
            title="Cancel Subscription"
            buttons={[
              { text: 'Yes', type: 'ok', onClick: handleSubmit },
              { text: 'No', type: 'cancel' },
            ]}
            closeDialog={onCancel}
          >
            { processing && <SectionLoader />}
            <FormGroup>
              Are you sure you want to cancel {subscription.subscriber.firstName} {subscription.subscriber.lastName}
              's {subscription.subscriptionPlan.name} subscription?
            </FormGroup>
            <FormGroup>
              <Label htmlFor="reason">Reason</Label>
              <FormikRichText name="reason" />
              <CustomErrorMessage name="reason" />
            </FormGroup>
          </FormDialog>
        )
      }
    </Formik>
  );
};

export default SubscriptionCancellation;
