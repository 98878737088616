import React from 'reactn';
import ActivitiesToRestoreList from './ActivitiesToRestoreList';
import RestorationDialog from './RestorationDialog';
import useAsyncOperation from '../../common/hooks/use-async-operation';
import { apiGetActivitiesStatuses, apiRestoreActivity } from './api';
import { NotificationManager } from 'react-notifications';
import MessageDialog from '../../ui/MessageDialog';
import SectionLoader from '../../ui/SectionLoader';
import ReactJson from 'react-json-view'

const activities = [
  {
    idToRestore: '609ba001c3bd9b001d1fee2f',
    expectedContactData: {
      id: '615c3750587133963cdd3acd',
      firstName: 'Max',
      lastName: 'CHORUS',
      email: 'data.protection@streff.lu',
    },
  },
  {
    idToRestore: '609b9d80c3bd9b001d1fede4',
    expectedContactData: {
      id: '615c3751587133963cdd3acf',
      firstName: 'Michel',
      lastName: 'SOUTIRAN',
      email: 'sparflex@sparflex.com',
    },
  },
  {
    idToRestore: '609b9e4cc3bd9b001d1fedfd',
    expectedContactData: {
      id: '615c3751587133963cdd3ace',
      firstName: 'Birgit',
      lastName: 'STEIL',
      email: 'smanu@pt.lu',
    },
  },
  {
    idToRestore: '609ba21dc3bd9b001d1feec9',
    expectedContactData: {
      id: '615c3751587133963cdd3ad0',
      firstName: 'Alain',
      lastName: 'FURPASS',
      email: 'info@sudenergie.lu',
    },
  },
  {
    idToRestore: '609b9cc1c3bd9b001d1fedcb',
    expectedContactData: {
      id: '615c3751587133963cdd3ad1',
      firstName: 'Dejan ',
      lastName: 'PETKOW',
      email: 'info@sparc-industries.com',
    },
  },
  {
    idToRestore: '609b9a48c3bd9b001d1fed07',
    expectedContactData: {
      id: '615c3752587133963cdd3ad2',
      firstName: 'Max',
      lastName: 'WEBER',
      email: 'contact@rosport.com',
    },
  },
];

const RestoreContactsListContainer = () => {
  const [selectedIdToRestore, setIdToRestore] = React.useState(false);
  const [restorationDialogVisible, setRestorationDialogVisible] = React.useState(false);
  const [previewRestorationDialogVisible, setPreviewRestorationDialogVisible] = React.useState(false);
  const [contactPreview, setContactPreview] = React.useState(null);
  const [formBag, setFormBag] = React.useState(null);
  const [restoreActivity, result] = useAsyncOperation(apiRestoreActivity);
  const [corruptionStatuses, setCorruptionStatuses] = React.useState({});

  const activity = selectedIdToRestore && activities.find(a => a.idToRestore === selectedIdToRestore);

  const handlePreviewRestoredContact = React.useCallback(async ({ activityId, backupURL, contactId }) => {
    try {
      const preview = await restoreActivity(activityId, backupURL, contactId, false);
      setRestorationDialogVisible(true);
      setContactPreview(preview);
      setFormBag({
        activityId,
        backupURL,
        contactId,
      });
    } catch (err) {
      NotificationManager.error('Something wrong happened');
    }
  }, [restoreActivity]);

  const cancelRestoreContact = React.useCallback(() => {
    setRestorationDialogVisible(false);
    setContactPreview(null);
    setFormBag(null);
  }, []);

  const handleRestoreContact = React.useCallback(async () => {
    try {
      const { activityId, backupURL, contactId } = formBag;
      await restoreActivity(activityId, backupURL, contactId, true);
      NotificationManager.success('Activity has been restored');
      setRestorationDialogVisible(false);
      setPreviewRestorationDialogVisible(false);
      setContactPreview(null);
      setFormBag(null);
    } catch (err) {
      NotificationManager.error('Something went wrong');
    }
  }, [formBag]);

  React.useEffect(() => {
    const fetchActivitiesCorruptionStatus = async () => {
      try {
        const statuses = await apiGetActivitiesStatuses(activities.map(activity => activity.idToRestore));
        setCorruptionStatuses(statuses);
      } catch (err) {
        NotificationManager.error('Something went wrong');
      }
    };

    fetchActivitiesCorruptionStatus();
  }, []);

  return (
    <React.Fragment>
      <ActivitiesToRestoreList
        activities={activities}
        corruptionStatuses={corruptionStatuses}
        onRestoreActivity={(idToRestore) => {
          setIdToRestore(idToRestore);
          setPreviewRestorationDialogVisible(true);
        }}
      />
      {previewRestorationDialogVisible && (
        <RestorationDialog
          isLoading={result.loading}
          onPreviewRestoredContact={handlePreviewRestoredContact}
          onClose={() => setPreviewRestorationDialogVisible(false)}
          idToRestore={selectedIdToRestore}
          contactId={activity.expectedContactData.id}
          firstName={activity.expectedContactData.firstName}
          lastName={activity.expectedContactData.lastName}
          email={activity.expectedContactData.email}
        />
      )}
      {restorationDialogVisible && contactPreview && formBag && (
        <MessageDialog
          open
          title="Confirm restore"
          closeDialog={cancelRestoreContact}
          buttons={[
            { type: 'ok', text: 'Confirm', onClick: handleRestoreContact },
            { type: 'cancel', text: 'Cancel' },
          ]}
        >
          {result.loading && <SectionLoader />}
          <ReactJson src={contactPreview} enableClipboard={false} displayDataTypes={false} />
        </MessageDialog>
      )}
    </React.Fragment>
  );
};

export default RestoreContactsListContainer;
