import React from 'react';
import  { useGlobal } from 'reactn';
import PropTypes from 'prop-types';
import { Switch  } from 'react-router-dom';
import ForbiddenAccess from '../../pages/ForbiddenAccess/ForbiddenAccess';

const AppAccessSwitch = ({ role: switchRole, children }) => {
  const [loginData] = useGlobal('loginData');
  if (loginData) {
    const { user } = loginData;

    return (
      <Switch>
        {
          React.Children.map(children, child => {
            const role = [].concat(child.props.role || switchRole);

            if (user.role === 'ADMIN') {
              return child;
            }
            if (role && role.includes(user.role)) {
              return React.cloneElement(child, {
                component: ForbiddenAccess
              });
            } else {
              return child;
            }

          })
        }
      </Switch>
    );
  }

  return null;
};

AppAccessSwitch.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ])
};

export default AppAccessSwitch;
