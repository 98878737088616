import { useEffect, useMemo, useState } from 'react';

const useGetAll = (getData, ...params) => {
  const [ loading, setLoading ] = useState(false);
  const [ init, setInit ] = useState(true);
  const [ data, setData ] = useState([]);
  const [ error, setError ] = useState(false);
  const [ success, setSuccess ] = useState(false);
  const fetchData = async (...fetchData) => {
    try {
      setLoading(true);
      const data = await getData(...fetchData);
      setData(data);
      setSuccess(true);
      if (init) {
        setInit(false);
      }
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const initialFetchData = () => fetchData(...params);

  const result = useMemo(() => {
    return {
      data,
      success,
      error,
      loading,
      init,
    };
  }, [data, success, error, loading, init]);

  useEffect(() => {
    initialFetchData();
  }, [...params]);


  return [
    result,
    fetchData
  ];
};

export default useGetAll;
