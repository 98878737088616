import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { usePaginate } from '../../../common/hooks/use-paginate';
import Breadcrumb from '../../../ui/Breadcrumb';
import SpacesList from '../components/SpacesList';
import { NotificationManager } from 'react-notifications';
import {
  useAddPropertyToQueryString,
  useQueryMap
} from '../../../common/hooks/use-query';
import { apiGetSpaces, apiGetUsers } from '../api';

const SpacesListContainer = () => {

  const [
    currentPage,
    currentItemsPerPage,
    goToPage
  ] = usePaginate();

  const [
    spaces,
    setSpaces
  ] = useState([]);

  const [
    total,
    setTotal
  ] = useState(0);

  const [
    users,
    setUsers
  ] = useState([]);

  const addPropertyToQueryString = useAddPropertyToQueryString();
  const history = useHistory();
  const match = useRouteMatch();

  const queryMap = useQueryMap();

  const { search: currentSearch } = queryMap;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { spaces, total } = await apiGetSpaces({
          page: currentPage,
          itemsPerPage: currentItemsPerPage,
          search: currentSearch,
        });

        setSpaces(spaces);
        setTotal(total);
      } catch (err) {
        NotificationManager.error('Failed to load spaces. Please refresh or try again later');
      }
    };

    fetchData();
    return history.listen((location, action) => {
      if (action === 'POP') {
        fetchData();
      }
    });
  }, [currentPage, currentItemsPerPage, currentSearch, history]);

  const getUsersBySpace = (spaceId) => {
    const fetch = async () => {
      try {
        const result = await apiGetUsers(spaceId);
        setUsers(result);
      } catch (err) {
        NotificationManager.error('Failed to load users. Please refresh or try again later');
      }
    };

    fetch();
  }

  const onSearchChange = React.useCallback((search) => {
    history.push(`${match.url}?${addPropertyToQueryString(['search', search])}`);
  }, [history, match.url, addPropertyToQueryString]);

  return (
    <>
      <Breadcrumb
        items={[
          { title: 'Spaces' }
        ]}
      />
      <SpacesList
        spaces={spaces}
        setSpaces={setSpaces}
        currentPage={currentPage}
        currentItemsPerPage={currentItemsPerPage}
        currentSearch={currentSearch}
        total={total}
        goToPage={goToPage}
        users={users}
        getUsersBySpace={getUsersBySpace}
        history={history}
        match={match}
        onSearchChange={onSearchChange} />
    </>
  );
};

export default SpacesListContainer;
