import { createGlobalStyle } from "styled-components";

const General = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  
  #root, html, body {
    width: 100%;
    height: 100%;
  }
`;

export default General;
