import React, { useMemo } from 'react';
import styled from 'styled-components';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import MaterialIcon from '@material/react-material-icon';

const bgColors = {
  ok: '#0078D4',
  cancel: '#dadada'
};

const colors = {
  ok: '#fff',
  cancel: '#333'
};

const DialogWrapper = styled(MuiDialog)`
  .dialog-paper {
    padding-bottom: 10px;
    box-sizing: border-box;
    background-color: #f9f8fa;
    max-width: none;
  }
`;

const DialogTitle = styled(MuiDialogTitle)`
  &.dialog-title {
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    color: #333;
    font-weight: bold;
    text-align: ${({ titlecentered }) => titlecentered ? 'center' : 'left'};
    text-align: center;
    padding: 20px 0;
  }
`;

const DialogContent = styled(MuiDialogContent)`
  &.dialog-content {
    max-width: ${({ ismessagedialog = false }) => ismessagedialog ? '400px' : 'none' };
    padding-top: 20px;
  }
`;

const DialogActions = styled(MuiDialogActions)`
  &.dialog-actions {
    padding-left: 20px;
    padding-right: 20px;
    justify-content: space-between;
  }
`;

const DialogButton = styled(Button)`
  &.dialog-button {
    color: ${({ dialogtype = 'ok' }) => colors[dialogtype]};
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    border-radius: 2px;
    text-transform: lowercase;

    &:not(:hover) {
      background-color: ${({ dialogtype = 'ok' }) => bgColors[dialogtype]};
    }

    &:hover {
      background-color: ${({ dialogtype = 'ok' }) => bgColors[dialogtype]}dd;
    }
  }
`;

const CloseIcon = styled(MaterialIcon)`
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 50%;
  color: #333;
  cursor: pointer;
  padding: 2px;
`;

const Dialog = ({
  title, open, closeDialog, content, buttons, children, ...props
}) => {
  const dialogButtonsClick = useMemo(() => {
    return buttons.map(button => {
      if (button.type === 'cancel') {
        return (...args) => {
          if (typeof button.onClick === 'function') {
            button.onClick(...args);
          }
          closeDialog();
        }
      } else {
        return button.onClick;
      }
    })
  }, [buttons, closeDialog]);
  return (
    <DialogWrapper
      classes={{
        paper: 'dialog-paper'
      }}
      open={open}
      onClose={closeDialog}
      {...props}
    >
      <DialogTitle disableTypography classes={{
        root: 'dialog-title'
      }}>
        <CloseIcon onClick={closeDialog} icon="close" />
        {title}
      </DialogTitle>
      <DialogContent classes={{
        root: 'dialog-content'
      }}>
        { content || children }
      </DialogContent>
      <DialogActions classes={{
        root: 'dialog-actions'
      }}>
        {
          buttons.map((button, index) => button.type === 'custom' ?
            <React.Fragment key={index}>{button.component}</React.Fragment> : (
              <DialogButton key={index} dialogtype={button.type} classes={{
                root: 'dialog-button'
              }} onClick={dialogButtonsClick[index]}>{ button.text }</DialogButton>
            ))
        }
      </DialogActions>
    </DialogWrapper>
  );
};

export default Dialog;
