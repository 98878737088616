import apiClientRequest from '../../common/apiclient';

export const apiGetSubscribers = ({ page, itemsPerPage, plan, order, orderBy, fromDate, toDate, status, search }) =>
  apiClientRequest('/subscribers-list', {
  params: { page, itemsPerPage, search, plan, order, orderBy, fromDate, toDate, status },
});

export const apiResetActivities = (subscribers) => apiClientRequest('/search-activities', {
  method: 'POST',
  data: { subscribers }
});

export const apiExportSubscribers = () => apiClientRequest('/subscribers-list/export', {
  method: 'GET',
});

export const apiGetPlans = () =>
  apiClientRequest('/subscribers-list/plans');
