import React from 'react';
import { useHistory } from 'react-router-dom';
import AddSuperUser from '../components/AddSuperUser';
import { NotificationManager } from 'react-notifications';
import { apiAddSuperUser } from '../api';
import useGoBack from '../../../common/hooks/use-go-back';
import useAsyncOperation from '../../../common/hooks/use-async-operation';

const AddSuperUserContainer = () => {
  const goBack = useGoBack();
  const [asyncAddSuperUser, superUserCreation] = useAsyncOperation(apiAddSuperUser);

  const saveSuperUser = React.useCallback(async ({
    firstName, lastName, email, password, role,
  }) => {
    try {
      await asyncAddSuperUser({
        firstName,
        lastName,
        email,
        password,
        role,
      });
      goBack();
      NotificationManager.success(`${email} has been successfully created`);
    } catch (err) {
      if (err.data && err.data.name === 'EMAIL_ALREADY_EXISTS_EXCEPTION') {
        NotificationManager.error('Email already exists');
      } else {
        NotificationManager.error('Failed to add super user. Please refresh or try again later');
      }
    }
  }, [goBack, asyncAddSuperUser]);

  return (
    <AddSuperUser
      cancel={goBack}
      saveSuperUser={saveSuperUser}
      loading={superUserCreation.loading}
    />
  )
};

export default AddSuperUserContainer;
