import React from 'react';
import AppAccessSwitch from '../common/components/AppAccessSwitch';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';
import CorruptedActivitiesListContainer from '../pages/CorruptedActivitiesList/CorruptedActivitiesListContainer';

const CorruptedActivitiesRoutes = () => {
  const match = useRouteMatch();

  return (
    <AppAccessSwitch role="ADMIN">
      <Route path={`${match.url}/list`}>
        <CorruptedActivitiesListContainer />
      </Route>
      <Redirect from={`${match.url}`} to={`${match.url}/list`} />
    </AppAccessSwitch>
  )
};

export default CorruptedActivitiesRoutes;
