import React from "react";
import { ErrorMessage } from "formik";
import Message from "./ErrorMessage";

const renderErrorMessage = message => <Message>{message}</Message>;

const FormikErrorMessage = ({ ...props }) => (
  <ErrorMessage {...props} render={renderErrorMessage} />
);

export default FormikErrorMessage;
