import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Breadcrumb from '../../../ui/Breadcrumb';
import { NotificationManager } from 'react-notifications';
import Outboxes from '../components/Outboxes';
import { apiSaveSettings } from '../api';

const OutboxesContainer = () => {

  const [loading, setLoading] = useState(false);

  useEffect(() => {
  }, []);

  const save = React.useCallback(async (settings) => {
      try {
        setLoading(true);
        await apiSaveSettings('OUTBOXES_EXPIRATION', settings);
        NotificationManager.success('Success');
      } catch (e) {
        NotificationManager.error(`Something wrong happened`);
        console.error('OUTBOXES_EXPIRATION: ', e)
      } finally {
        setLoading(false);
      }
  }, []);

  return (
    <>
      <Breadcrumb
        items={[
          { title: 'Outboxes expiration' }
        ]}
      />
      <Outboxes save={save} loading={loading} />
    </>
  );
  };

  export default OutboxesContainer;
