import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CentredRow = styled(Row)`
  justify-content: center;
`;
