import React from 'react';
import MuiIcon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  fontSizeSmall: {
    fontSize: 18,
  },
  fontSizeLarge: {
    fontSize: 21,
  },
}));

const Icon = React.forwardRef(({ icon, ...props }, ref) => {
  const classes = useStyles();
  return (
    <MuiIcon ref={ref} classes={classes} {...props}>
      {icon}
    </MuiIcon>
  );
})

export default Icon;
