import React from 'react';
import styled from 'styled-components';
import Dialog from '../../../ui/Dialog';
import { Formik } from 'formik';
import FormGroup from '../../../ui/FormGroup';
import Label from '../../../ui/Label';
import FormikSelect from '../../../ui/FormikSelect';
import MenuItem from '@material-ui/core/MenuItem';
import { submitOnce } from '../../../common/lib/formik-helpers';
import SectionLoader from '../../../ui/SectionLoader';

const Wrapper = styled.div`
  width: 385px;
`;

const MigrateUserDialog = ({ plans, goBack, migrate, migrating }) => {
  const submit = React.useCallback(submitOnce(migrate), [migrate]);

  return (
    <Formik
      initialValues={{
        plan: '',
      }}
      onSubmit={submit}
    >
      {
        ({ handleSubmit }) => (
          <Dialog
            open
            title="Legacy User Migration"
            closeDialog={goBack}
            buttons={[
              { text: 'Confirm', onClick: handleSubmit, type: 'ok' },
              { text: 'Cancel', type: 'cancel' },
            ]}
          >
            { migrating && <SectionLoader />}
            <Wrapper>
              <FormGroup>
                <Label htmlFor="plan">Subscription Plan</Label>
                <FormikSelect name="plan" defaultValue="">
                  <MenuItem value="">Choose the plan</MenuItem>
                  {
                    plans.map(plan => (
                      <MenuItem key={plan._id} value={plan._id}>
                        { plan.name }
                      </MenuItem >
                    ))
                  }
                </FormikSelect>
              </FormGroup>
            </Wrapper>
          </Dialog>
        )
      }
    </Formik>
  );
};

export default MigrateUserDialog;
