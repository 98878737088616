import apiClientRequest from '../../common/apiclient';

export const apiGetAllSubscriptionPlans = (subscriberId) =>
  apiClientRequest(`/create-subscription/available-subscription-plans/${subscriberId}`);

export const apiCreateSubscription = ({
  planId, subscriberId,
}) =>
  apiClientRequest(`/create-subscription`, {
    method: 'POST',
    data: {
      planId, subscriberId,
    }
  });
