import React from 'react';
import styled from 'styled-components';
import MuiRadio from '@material-ui/core/Radio';
import Label from './Label';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const Radio = ({ name, children, ...props }) => (
  <Wrapper>
    <Label htmlFor={name}>{children}</Label>
    <MuiRadio name={name} {...props} />
  </Wrapper>
);

export default Radio;
