import React, { useCallback } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { Field, Form, Formik } from 'formik';
import FormGroup from '../../ui/FormGroup';
import Label from '../../ui/Label';
import FormikTextInput from '../../ui/FormikTextInput';
import FormikErrorMessage from '../../ui/FormikErrorMessage';
import { FormikCheckbox } from '../../ui/Checkbox';
import SUBSCRIPTION_PLAN_PRODUCT_TYPE from '../constants';
import { FormikRadio } from '../../ui/FormikRadio';
import { MQ_LARGE_DOWN } from '../breakpoints';
import FormDialog from '../../ui/FormDialog';
import { submitOnce } from '../lib/formik-helpers';
import SectionLoader from '../../ui/SectionLoader';

const subscriptionPlanSchema = yup.object({
  name: yup.string().required(),
  price: yup.number().test('testingPrice', function (value) {
    const { parent, path, createError } = this;

    if (parent.editMode) {
      return true;
    }

    if (parent.type === 'FREE') {
      if (value !== 0) {
        return createError({
          message: 'Free plan price must be 0',
          path,
        });
      }

      return true;
    } else {
      if (value <= 0) {
        return createError({
          message: 'Commercial plan cannot be free',
          path,
        });
      }

      return true;
    }
  }),
  period: yup.number().test('testing period', 'period should be > 1', value => value > 0),
  position: yup.number().required('position should be number'),
  spacesQuota: yup.number().required('Maximum spaces number is required'),
  activitiesQuota: yup.number().required('Activities Quota is required'),
  workflowsQuota: yup.number().required('Maximum Workflow number is required'),
  freeFilesQuota: yup.number().test('Free files quotas', 'Free files quotas should be > 0', value => value > 0),
});

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const FormGroupInline = styled.div`
  display: flex;
  flex-direction: row;

  > * {
    flex: 1;
  }

  @media ${MQ_LARGE_DOWN} {
    flex-direction: column;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;

  &:first-of-type {
    padding-right: 20px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 500px;

  > * {
    flex: 1;
  }

  @media ${MQ_LARGE_DOWN} {
    flex-direction: column;
  }
`;

const QuotaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > div:first-of-type {
    flex: 1!important;
    margin-right: 6px;
  }
`;

const Label2 = styled.label`
  font-family: "Open Sans", sans-serif;
  margin: 0 10px;
`;

const SubscriptionPlanDialog = ({ title, onSave, onCancel, loading, editMode = false, initialValues }) => {
  const onSubmit = useCallback(submitOnce(onSave), [onSave]);

  return (
    <Formik
      validationSchema={subscriptionPlanSchema}
      initialValues={{
        name: '',
        price: 0.00,
        type: 'FREE',
        spacesQuota: 1,
        activitiesQuota: 1,
        workflowsQuota: 1,
        teamBoardCardsQuota: 1,
        reportsQuota: 1,
        filesGbPrice: 0,
        freeFilesQuota: 0.0001,
        unlimitedSpaces: false,
        unlimitedActivities: false,
        unlimitedWorkflows: false,
        unlimitedTeamBoardCards: false,
        unlimitedReports: false,
        defaultProducts: [SUBSCRIPTION_PLAN_PRODUCT_TYPE.FILES_STORAGE],
        unit: 'days',
        period: 1,
        visible: true,
        isDefault: false,
        freeEmails: 0,
        emailUnit: 1000,
        emailUnitPrice: 1,
        position: 0,
        editMode,
        ...initialValues
      }}
      enableReinitialize={editMode}
      onSubmit={onSubmit}
    >
      {
        ({ handleSubmit, values }) => (
          <FormDialog
            open
            title={title}
            closeDialog={onCancel}
            buttons={[
              { type: 'ok', text: 'save', onClick: handleSubmit },
              { type: 'cancel', text: 'cancel' },
            ]}
          >
            {loading && <SectionLoader />}
            <Form onSubmit={handleSubmit}>
              <Field type="hidden" name="editMode" />
              <Wrapper>
                <Column>
                  <FormGroup>
                    <Label htmlFor="name">
                      Name
                    </Label>
                    <FormikTextInput name="name" />
                    <FormikErrorMessage name="name" />
                  </FormGroup>
                  <FormGroup>
                    <Label>Credit Card Required</Label>
                    <CheckboxWrapper>
                      <FormikRadio name="type" value="COMMERCIAL">Yes</FormikRadio>
                      <FormikRadio name="type" value="FREE">No</FormikRadio>
                    </CheckboxWrapper>
                  </FormGroup>
                  <FormGroupInline>
                    {
                      values.type !== 'FREE' && (
                        <FormGroup>
                          <Label>Price</Label>
                          <FormikTextInput type="number" min={1} name="price" />
                        </FormGroup>
                      )
                    }
                    <FormGroup>
                      <Label>Period</Label>
                      <FormikTextInput type="number" min={1} name="period" />
                    </FormGroup>
                    <FormGroup>
                      <Label>Unit</Label>
                      <CheckboxWrapper>
                        <FormikRadio name="unit" value="days">Days</FormikRadio>
                        <FormikRadio name="unit" value="months">Months</FormikRadio>
                      </CheckboxWrapper>
                    </FormGroup>
                  </FormGroupInline>
                  <FormGroup>
                    <Label htmlFor="isDefault">Default</Label>
                    <CheckboxWrapper>
                      <FormikRadio name="isDefault" value={true}>Yes</FormikRadio>
                      <FormikRadio name="isDefault" value={false}>No</FormikRadio>
                    </CheckboxWrapper>
                  </FormGroup>
                  <FormGroup>
                    <Label>Visible</Label>
                    <CheckboxWrapper>
                      <FormikRadio name="visible" value={true}>Yes</FormikRadio>
                      <FormikRadio name="visible" value={false}>No</FormikRadio>
                    </CheckboxWrapper>
                  </FormGroup>
                  <FormGroup>
                    <Label>Position</Label>
                    <CheckboxWrapper>
                      <FormikTextInput type="number" min={1} value={values.position} name="position" />
                    </CheckboxWrapper>
                    <FormikErrorMessage name="position" />
                  </FormGroup>
                </Column>
                <Column>
                  <FormGroup>
                    <Label htmlFor="spacesQuota">
                      Spaces Quota
                    </Label>
                    <QuotaWrapper>
                      <FormikTextInput
                        type="number"
                        min={0}
                        step={1}
                        name="spacesQuota"
                        disabled={values.unlimitedSpaces}
                      />
                      <Label2 htmlFor="unlimitedSpaces">Unlimited</Label2>
                      <FormikRadio name="unlimitedSpaces" value={true}>Yes</FormikRadio>
                      <FormikRadio name="unlimitedSpaces" value={false}>No</FormikRadio>
                    </QuotaWrapper>
                    <FormikErrorMessage name="spacesQuota" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="activitiesQuota">
                      Activities Quota
                    </Label>
                    <QuotaWrapper>
                      <FormikTextInput
                        type="number"
                        min={0}
                        step={1}
                        name="activitiesQuota"
                        disabled={values.unlimitedActivities}
                      />
                      <Label2 htmlFor="unlimitedActivities">Unlimited</Label2>
                      <FormikRadio name="unlimitedActivities" value={true}>Yes</FormikRadio>
                      <FormikRadio name="unlimitedActivities" value={false}>No</FormikRadio>
                    </QuotaWrapper>
                    <FormikErrorMessage name="activitiesQuota" />
                  </FormGroup>
                  <FormGroup>
                    <Label name="workflowsQuota">
                      Workflows Quota
                    </Label>
                    <QuotaWrapper>
                      <FormikTextInput
                        type="number"
                        min={0}
                        step={1}
                        name="workflowsQuota"
                        disabled={values.unlimitedWorkflows}
                      />
                      <Label2 htmlFor="unlimitedWorkflows">Unlimited</Label2>
                      <FormikRadio name="unlimitedWorkflows" value={true}>Yes</FormikRadio>
                      <FormikRadio name="unlimitedWorkflows" value={false}>No</FormikRadio>
                    </QuotaWrapper>
                    <FormikErrorMessage name="workflowsQuota" />
                  </FormGroup>
                  <FormGroup>
                    <Label name="teamBoardCardsQuota">
                      Team boards Quota
                    </Label>
                    <QuotaWrapper>
                      <FormikTextInput
                        type="number"
                        min={0}
                        step={1}
                        name="teamBoardCardsQuota"
                        disabled={values.unlimitedTeamBoardCards}
                      />
                      <Label2 htmlFor="unlimitedTeamBoardCards">Unlimited</Label2>
                      <FormikRadio name="unlimitedTeamBoardCards" value={true}>Yes</FormikRadio>
                      <FormikRadio name="unlimitedTeamBoardCards" value={false}>No</FormikRadio>
                    </QuotaWrapper>
                    <FormikErrorMessage name="teamBoardCardsQuota" />
                  </FormGroup>
                  <FormGroup>
                    <Label name="reportsQuota">
                      Reports Quota
                    </Label>
                    <QuotaWrapper>
                      <FormikTextInput
                        type="number"
                        min={0}
                        step={1}
                        name="reportsQuota"
                        disabled={values.unlimitedReports}
                      />
                      <Label2 htmlFor="unlimitedReports">Unlimited</Label2>
                      <FormikRadio name="unlimitedReports" value={true}>Yes</FormikRadio>
                      <FormikRadio name="unlimitedReports" value={false}>No</FormikRadio>
                    </QuotaWrapper>
                    <FormikErrorMessage name="reportsQuota" />
                  </FormGroup>
                  {
                    values.defaultProducts.includes(SUBSCRIPTION_PLAN_PRODUCT_TYPE.FILES_STORAGE) &&
                    <FormGroupInline>
                      <FormGroup>
                        <Label htmlFor="freeFilesQuota">
                          Free Files Quota (GB)
                        </Label>
                        <QuotaWrapper>
                          <FormikTextInput
                            type="number"
                            min={0.0001}
                            step={1}
                            name="freeFilesQuota"
                            onInput={(e) => {
                              if (e.target.value) {
                                e.target.value = e.target.value.toString().slice(0, 5);
                              } else {
                                console.info('MINIMIMI');
                              }
                            }}
                          />
                        </QuotaWrapper>
                        <FormikErrorMessage name="freeFilesQuota" />
                      </FormGroup>
                      {values.type !== 'FREE' &&
                        <FormGroup>
                          <Label htmlFor="filesGbPrice">
                            FILES GB PRICE (€)
                          </Label>
                          <QuotaWrapper>
                            <FormikTextInput
                              type="number"
                              min={0}
                              step={1}
                              name="filesGbPrice"
                            />
                          </QuotaWrapper>
                          <FormikErrorMessage name="filesGbPrice" />
                        </FormGroup>
                      }
                    </FormGroupInline>
                  }

                  {
                    values.defaultProducts.includes(SUBSCRIPTION_PLAN_PRODUCT_TYPE.EMAIL_CAMPAIGN) &&
                    <FormGroupInline>
                      <FormGroup>
                        <Label htmlFor="freeEmails">
                          Free emails
                        </Label>
                        <QuotaWrapper>
                          <FormikTextInput
                            type="number"
                            min={0}
                            step={1}
                            name="freeEmails"
                            onInput={(e) => {
                              e.target.value = e.target.value.toString().slice(0, 5);
                            }}
                          />
                        </QuotaWrapper>
                        <FormikErrorMessage name="freeEmails" />
                      </FormGroup>
                      {values.type !== 'FREE' &&
                        <>
                          <FormGroup>
                            <Label htmlFor="emailUnit">
                              Email Unit
                            </Label>
                            <QuotaWrapper>
                              <FormikTextInput
                                type="number"
                                min={0}
                                step={1}
                                name="emailUnit"

                              />
                            </QuotaWrapper>
                            <FormikErrorMessage name="emailUnit" />
                          </FormGroup>
                          <FormGroup>
                            <Label htmlFor="emailUnitPrice">
                              Price email per unit
                            </Label>
                            <QuotaWrapper>
                              <FormikTextInput
                                type="number"
                                min={0}
                                step={1}
                                name="emailUnitPrice"
                              />
                            </QuotaWrapper>
                            <FormikErrorMessage name="emailUnitPrice" />
                          </FormGroup>
                        </>
                      }
                    </FormGroupInline>
                  }

                  <FormGroup>
                    <Label>Default Products</Label>
                    <CheckboxWrapper>
                      <FormikCheckbox name="defaultProducts" value={SUBSCRIPTION_PLAN_PRODUCT_TYPE.PLANNING_BOARD}>
                        Team Board
                      </FormikCheckbox>
                      <FormikCheckbox name="defaultProducts" value={SUBSCRIPTION_PLAN_PRODUCT_TYPE.TASK_MANAGEMENT}>
                        Task Management
                      </FormikCheckbox>
                      <FormikCheckbox name="defaultProducts" value={SUBSCRIPTION_PLAN_PRODUCT_TYPE.DATA_EXPORT}>
                        Data Export
                      </FormikCheckbox>
                      <FormikCheckbox name="defaultProducts" value={SUBSCRIPTION_PLAN_PRODUCT_TYPE.DATA_IMPORT}>
                        Data Import
                      </FormikCheckbox>
                      <FormikCheckbox name="defaultProducts" value={SUBSCRIPTION_PLAN_PRODUCT_TYPE.DASHBOARD}>
                        Dashboard
                      </FormikCheckbox>
                      <FormikCheckbox name="defaultProducts" value={SUBSCRIPTION_PLAN_PRODUCT_TYPE.MAILING}>
                        Mailing
                      </FormikCheckbox>
                      <FormikCheckbox name="defaultProducts" value={SUBSCRIPTION_PLAN_PRODUCT_TYPE.FILES_STORAGE}>
                        Files Storage
                      </FormikCheckbox>
                      <FormikCheckbox name="defaultProducts" value={SUBSCRIPTION_PLAN_PRODUCT_TYPE.EMAIL_CAMPAIGN}>
                        Email Campaign
                      </FormikCheckbox>
                    </CheckboxWrapper>
                  </FormGroup>
                </Column>
              </Wrapper>
            </Form>
          </FormDialog>
        )
      }
    </Formik>
  );
};

export default SubscriptionPlanDialog;
