import React from "react";
import styled from "styled-components";

const StyledSelect = styled.select`
  border: 1px solid #ccc;
  padding: 7px 10px;
  border-radius: 4px;
  color: #333;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;

  width: ${({ fill = false }) => fill ? '100%' : 'auto'};
  border-color: ${({ hasError = false }) => hasError ? '#cc0000' : '#ccc'};
  border-color: ${({ isValid = false }) => isValid ? '#00cc00' : '#ccc'};
  
  &:focus {
    outline: none;
    box-shadow: 0 0 1px #777;
  }
`;

const Select = ({ fill, hasError, isValid, ...props }) => (
  <StyledSelect
    fill={fill ? 1 : 0}
    hasError={hasError ? 1 : 0}
    isValid={isValid ? 1 : 0}
    {...props}
  />
);

export default Select;
