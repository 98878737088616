import React from 'react';
import { Field } from 'formik';
import MaterialInput from '@material-ui/core/Input';

const FormikTextInput = ({ name, ...props }) => (
  <Field name={name}>
    {
      ({ field, form }) => (
        <MaterialInput {...field} {...props} error={form.touched[name] && !!form.errors[name]} />
      )
    }
  </Field>
);

export default FormikTextInput;
