import React from 'react';
import FormDialog from '../../ui/FormDialog';
import { Formik } from 'formik';
import FormGroup from '../../ui/FormGroup';
import Label from '../../ui/Label';
import FormikTextInput from '../../ui/FormikTextInput';
import FormikErrorMessage from '../../ui/FormikErrorMessage';
import { submitOnce } from '../lib/formik-helpers';
import * as yup from 'yup';
import Form from '../../ui/Form';
import SectionLoader from '../../ui/SectionLoader';
import FormikCountryInput from '../../ui/formik/FormikCountryInput';

const validationSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().required('Email is required').email('Invalid email'),
  phone: yup.string().required('Phone is required'),
  line1: yup.string().required('Line 1 is required'),
  line2: yup.string(),
  city: yup.string().required('City is required'),
  country: yup.string().required('Country is required'),
  zip: yup.string().required('Zip code is required'),
});

const SubscriberFormDialog = ({ title, save, subscriber, editMode = false, cancel, loading }) => {
  const submit = React.useCallback(submitOnce(save), [save]);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        firstName: subscriber?.firstName || '',
        lastName: subscriber?.lastName || '',
        email: subscriber?.email || '',
        companyName: subscriber?.companyName || '',
        city: subscriber?.city || '',
        line1: subscriber?.line1 || '',
        line2: subscriber?.line2 || '',
        country: subscriber?.country || '',
        phone: subscriber?.phone || '',
        zip: subscriber?.zip || '',
      }}
      enableReinitialize={editMode}
      onSubmit={submit}>
      {
        ({ handleSubmit }) => (
          <FormDialog
            open
            title={title}
            closeDialog={cancel}
            buttons={[
              { text: 'Save', type: 'ok', onClick: handleSubmit },
              { text: 'Cancel', type: 'cancel' }
            ]}
          >
            {
              loading && (
                <SectionLoader />
              )
            }
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label htmlFor="name">
                  First Name
                </Label>
                <FormikTextInput name="firstName" />
                <FormikErrorMessage name="firstName" />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="lastName">
                  Last Name
                </Label>
                <FormikTextInput name="lastName" />
                <FormikErrorMessage name="lastName" />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="email">
                  Email
                </Label>
                <FormikTextInput name="email" />
                <FormikErrorMessage name="email" />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="phone">
                  Phone
                </Label>
                <FormikTextInput name="phone" />
                <FormikErrorMessage name="phone" />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="companyName">
                  Company
                </Label>
                <FormikTextInput name="companyName" />
                <FormikErrorMessage name="companyName" />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="line1">
                  Line 1
                </Label>
                <FormikTextInput name="line1" />
                <FormikErrorMessage name="line1" />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="line2">
                  Line 2
                </Label>
                <FormikTextInput name="line2" />
                <FormikErrorMessage name="line2" />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="city">
                  City
                </Label>
                <FormikTextInput name="city" />
                <FormikErrorMessage name="city" />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="country">
                  Country
                </Label>
                <FormikCountryInput name="country" />
                <FormikErrorMessage name="country" />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="zip">
                  Zip
                </Label>
                <FormikTextInput name="zip" />
                <FormikErrorMessage name="zip" />
              </FormGroup>
            </Form>
          </FormDialog>
        )
      }
    </Formik>
  );
};

export default SubscriberFormDialog;
