import React from 'react';
import SubscriptionDetailsDialog from './SubscriptionDetailsDialog';
import { useQueryMap } from '../../common/hooks/use-query';
import { NotificationManager } from 'react-notifications';
import { apiGetSubscriptionById } from '../EditSubscription/api';
import useGoBack from '../../common/hooks/use-go-back';

const SubscriptionDetailsContainer = () => {
  const [subscription, setSubscription] = React.useState(null);
  const queryMap = useQueryMap();
  const goBack = useGoBack();

  React.useEffect(() => {
    const fetch = async () => {
      try {
        const result = await apiGetSubscriptionById(queryMap.subscriptionId);
        setSubscription(result);
      } catch (err) {
        NotificationManager.error('Something went wrong. Please try again later');
        goBack();
      }
    };

    fetch();
  }, [goBack, queryMap.subscriptionId]);

  if (subscription) {
    return (
      <SubscriptionDetailsDialog subscription={subscription} close={goBack} />
    );
  }

  return null;
};

export default SubscriptionDetailsContainer;
