import apiClientRequest from '../../common/apiclient';

export const apiGetSuperUserById = id =>
  apiClientRequest(`/update-super-user/get/${id}`);

export const apiUpdateSuperUserById = (id, { firstName, lastName, email, role }) =>
  apiClientRequest(`/update-super-user/update/${id}`, {
    method: 'PATCH',
    data: {
      firstName,
      lastName,
      email,
      role,
    },
  });

  export const apiChangeUserPassword = ({ oldPassword, newPassword }) =>
  apiClientRequest(`/update-super-user/password`, {
    method: 'PATCH',
    data: {
      oldPassword,
      newPassword,

    },
  });

  export const apiChangeUserEmail = ({ oldEmail, newEmail }) =>
  apiClientRequest(`/update-super-user/email`, {
    method: 'PATCH',
    data: {
      oldEmail,
      newEmail,

    },
  });