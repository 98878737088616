import apiClientRequest from '../../common/apiclient';

export const apiGetTemplates = ({ page, itemsPerPage, order, orderBy, search }) =>
apiClientRequest('/email-campaigns/templates', {
    params: { page, itemsPerPage, order, orderBy, search },
});

export const apiSaveTemplate = (data) =>
apiClientRequest(`/email-campaigns/templates`, {
    method: 'POST',
    data,
});

export const apiDeleteEmailTemplate = (id) => apiClientRequest(
    `/email-campaigns/templates/${id}`, { method: 'DELETE', },
);

export const apiGetTemplatesCategories = ({ page, itemsPerPage, order, orderBy, search }) =>
apiClientRequest('/email-campaigns/template-categories', {
    params: { page, itemsPerPage, order, orderBy, search },
});

export const apiGetTemplatesTags = ({ page, itemsPerPage, order, orderBy, search }) =>
apiClientRequest('/email-campaigns/template-tags', {
    params: { page, itemsPerPage, order, orderBy, search },
});

export const apiGetAllTemplateCategories = () => apiClientRequest(
    `/email-campaigns/template-categories`, { params: { search: '_all_', page: 1, itemsPerPage: 10 },
});

export const apiGetAllTemplateTags = () => apiClientRequest(
    `/email-campaigns/template-tags`, { params: { search: '_all_', page: 1, itemsPerPage: 10 },
});

export const apiGetTemplateCategoryById = (id) => apiClientRequest(`/email-campaigns/template-categories/${id}`);

export const apiGetTemplateTagById = (id) => apiClientRequest(`/email-campaigns/template-tags/${id}`);

export const apiSaveTemplateCategories = (data) =>
apiClientRequest(`/email-campaigns/template-categories`, {
    method: 'POST',
    data,
});

export const apiSaveTemplateTags = (data) =>
apiClientRequest(`/email-campaigns/template-tags`, {
    method: 'POST',
    data,
});

export const apiDeleteTemplateCategory = (id) => apiClientRequest(
    `/email-campaigns/template-categories/${id}`, { method: 'DELETE', },
);

export const apiDeleteTemplateTag = (id) => apiClientRequest(
    `/email-campaigns/template-tags/${id}`, { method: 'DELETE', },
);

export const apiGetAuthorization = () => apiClientRequest(`/email-campaigns/authorization`);

export const apiGenerateToken = (data) =>
apiClientRequest(`/email-campaigns/authorization`, {
    method: 'POST',
    data,
});

export const apiGetTemplateById = (id) => apiClientRequest(`/email-campaigns/templates/${id}`);

export const apiGetTemplateContentById = (id) => apiClientRequest(`/email-campaigns/template-content/${id}`);

export const apiSavetemplateContent = async (data) =>
    apiClientRequest('/email-campaigns/template-content', {
        method: 'POST',
        data,
});

export const apiUploadMedia = async (data) =>
    apiClientRequest('/email-campaigns/media', {
        method: 'POST',
        data,
});

export const apiRemoveMedia = async (data) =>
    apiClientRequest('/email-campaigns/media', {
        method: 'DELETE',
        data,
});
