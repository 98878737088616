import React, { useEffect, useState } from 'react';
import { Transition } from 'react-transition-group';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
`;

const TabButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Tab = styled.div`
  width: 0;
  transition: opacity 500ms ease-in-out, width 500ms ease-in-out, display 100ms ease-in-out 500ms;
  opacity: ${({ animationState }) => {
    switch (animationState) {
      case 'entered':
        return 1;
      case 'entering':
        return 0;
      case 'exited':
        return 0;
      case 'exiting':
        return 1;
      default:
        return 0;
    }
  }};
  width: ${({ animationState }) => {
    switch (animationState) {
      case 'entering':
      case 'exited':
        return 0;
      case 'entered':
      case 'exiting':
        return '100%';
      default:
        return 0;
    }
  }};
  display: ${({ animationState }) => {
  switch (animationState) {
    case 'entering':
    case 'exited':
      return 'none';
    case 'entered':
    case 'exiting':
      return 'block';
    default:
      return 'none';
  }
}};
`;

const TabButton = styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: #777;
  width: 150px;
  text-align: center;
  padding: 2px 25px 2px 25px;
  transition: color 200ms ease-in-out;
  color: ${({ animationState }) => {
    switch (animationState) {
      case 'entering':
      case 'exited':
        return '#777';
      case 'entered':
      case 'exiting':
        return '#0078D4';
      default:
        return '#777';
    }
  }};
`;


const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px 0;
`;

const TabButtonContainer = styled.div`
  cursor: pointer;
  padding-bottom: 10px;
  border-bottom: 0 solid #0078D4;
  transition: border-bottom-width 200ms ease-in-out;
  
  border-bottom-width: ${({ animationState }) => {
  switch (animationState) {
      case 'entering':
      case 'exited':
        return 0;
      case 'entered':
      case 'exiting':
        return '3px';
      default:
        return 0;
    }
  }}
  
  &:not(:last-of-type) > div {
    border-right: 1px solid #aaa;
  }
`;

const DelayedTab = ({ show = true, children }) => {
  const [
    rendered,
    setRendered
  ] = useState(show);

  useEffect(() => {
    if  (show && !rendered) {
      setRendered(true);
    }
  }, [show]);

  if (rendered) {
    return (
      <React.Fragment>
        { children }
      </React.Fragment>
    )
  }

  return null;
};

const TabsNavigation = ({ children, tabIndex, setTabIndex }) => {
  return (
    <Wrapper>
      <TabButtonsContainer>
        {
          React.Children.map(children, (tabContent, index) => (
            <Transition in={index === tabIndex} timeout={0}>
              {
                animationState => (
                  <TabButtonContainer key={index} animationState={animationState}>
                    <TabButton onClick={() => setTabIndex(index)} animationState={animationState}>
                      { tabContent.type.tabName }
                    </TabButton>
                  </TabButtonContainer>
                )
              }
            </Transition>
          ))
        }
      </TabButtonsContainer>
      <TabsContainer>
        {
          React.Children.map(children, (tabContent, index) => (
            <Transition in={tabIndex === index} timeout={0}>
              {
                animationState => (
                  <Tab animationState={animationState} key={index}>
                    <DelayedTab show={index === tabIndex}>
                      { tabContent }
                    </DelayedTab>
                  </Tab>
                )
              }
            </Transition>
          ))
        }
      </TabsContainer>
    </Wrapper>
  )
};

export default TabsNavigation;
