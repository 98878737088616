import apiClientRequest from "../../common/apiclient";

export const apiGetWorkflowTemplatesDir = async ({ page = 1, itemsPerPage = 10 }, { search, order, orderBy }) => {
    const params = {
        page,
        itemsPerPage
    };

    if (search) {
        params.search = search;
    }

    if (order) {
        params.order = order;
    }

    if (orderBy) {
        params.orderBy = orderBy;
    }

    return apiClientRequest(`/workflow-templates/dirs`, {
        method: 'GET',
        params
    });
};

export const apiGetAllTemplatesDirs = async () => {
    return apiClientRequest(`/workflow-templates/dirs/all`, {
        method: 'GET',
    });
};

export const apiGetWorkflowTemplates = async ({ page = 1, itemsPerPage = 10 }, dirId, { search, order, orderBy }) => {
    const params = {
        page,
        itemsPerPage
    };

    if(dirId) {
        params.dirId = dirId;
    }

    if (search) {
        params.search = search;
    }

    if (order) {
        params.order = order;
    }

    if (orderBy) {
        params.orderBy = orderBy;
    }

    return apiClientRequest(`/workflow-templates`, {
        method: 'GET',
        params
    });
};

export const apiAddWorkflowTemplateDir = async (name, description, lang, docUrl) => {
    return apiClientRequest(`/workflow-templates/dirs`, {
      method: 'POST',
      data: {
        name,
        description,
        lang,
        docUrl
      }
    });
};

export const apiUpdateWorkflowTemplateDir = async (id, name, description, lang, docUrl) => {
    return apiClientRequest(`/workflow-templates/dirs/${id}`, {
      method: 'PUT',
      data: {
        name,
        description,
        lang,
        docUrl
      }
    });
};

export const apiUpdateWorkflowTemplate = async (id, name, description, lang, docUrl, isActive, isDefault, workflowTemplateDir, configurations, customFields) => {
    return apiClientRequest(`/workflow-templates/${id}`, {
      method: 'PUT',
      data: {
        name,
        description,
        lang,
        docUrl,
        isActive,
        isDefault,
        workflowTemplateDir,
        configurations,
        customFields,
      }
    });
};

export const apiAddWorkflowTemplate = async ({
  name, description, lang, docUrl, isActive, isDefault, workflowTemplateDir, configurations, customFields,
}) => {
    return apiClientRequest(`/workflow-templates`, {
      method: 'POST',
      data: {
        name,
        description,
        lang,
        docUrl,
        isActive,
        isDefault,
        workflowTemplateDir,
        configurations,
        customFields,
      }
    });
};

export const apiDeleteTemplate = async (payload) => {
    const { id } = payload;
    return apiClientRequest(`/workflow-templates/${id}`, {
      method: 'DELETE',
    });
};

export const apiDeleteDir = async (payload) => {
    const { id } = payload;
    return apiClientRequest(`/workflow-templates/dirs/${id}`, {
      method: 'DELETE',
    });
};

export const apiGetTemplateDirById = async (id) => {
    return apiClientRequest(`/workflow-templates/dirs/${id}`, {
        method: 'GET',
    });
};

export const apiGetTemplateById = async (id) => {
    return apiClientRequest(`/workflow-templates/${id}`, {
        method: 'GET',
    });
};
