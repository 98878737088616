import React from 'react';
import {
  useLocation
} from 'react-router-dom';
import pickBy from 'lodash/pickBy';

export default function useQuery() {
  const location = useLocation();

  return React.useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);
}

export function useQueryMap() {
  const query = useQuery();

  return React.useMemo(() => {
    return Array.from(query.entries())
      .reduce((acc, [queryParam, queryValue]) => ({
        ...acc,
        [queryParam]: queryValue
      }), {});
  });
}

export const queryMapToString = queryMap =>
  Object.keys(queryMap).reduce((acc, name) => [
    ...acc,
    `${name}=${encodeURIComponent(queryMap[name])}`
  ], [])
    .join('&');

export const useAddPropertiesToQueryString = () => {
  const queryMap = useQueryMap();
  return React.useCallback((properties) => {
    let newQueryMap = {
      ...queryMap,
      ...properties.reduce((acc, [key, value]) => ({
        ...acc,
        [key]: value,
      }), {}),
    };

    newQueryMap = pickBy(newQueryMap);

    return queryMapToString(newQueryMap);
  }, [queryMap]);
};

export const useAddPropertyToQueryString = () => {
  const queryMap = useQueryMap();

  return React.useCallback(([key, value]) => {
    let newQueryMap = {
      ...queryMap,
      [key]: value,
    };

    newQueryMap = pickBy(newQueryMap);

    return queryMapToString(newQueryMap);
  }, [queryMap]);
};
