import React from 'reactn';
import ConfirmFixExecution from './ConfirmFixExecution';
import { useQueryMap } from '../../common/hooks/use-query';
import { apiGetSystemFixById, apiRunSystemFix } from './api';
import { NotificationManager } from 'react-notifications';
import useGoBack from '../../common/hooks/use-go-back';
import useAsyncOperation from '../../common/hooks/use-async-operation';
import { FETCH_SYSTEM_FIXES } from '../SystemFixesList/gstate/reducers';

const ConfirmFixExecutionContainer = () => {
  const params = useQueryMap();
  const [systemFix, setSystemFix] = React.useState();
  const [runSystemFix, systemFixExecution] = useAsyncOperation(apiRunSystemFix);
  const fetchSystemFixes = React.useDispatch(FETCH_SYSTEM_FIXES);

  const goBack = useGoBack();

  const runFix = React.useCallback(async () => {
    try {
      await runSystemFix(params.id);
      await fetchSystemFixes();
      NotificationManager.success('Fix has been successfully executed');
      goBack();
    } catch (err) {
      NotificationManager.error('Something is wrong. Please refresh or try again later');
    }
  }, [params.id, fetchSystemFixes]);

  React.useEffect(() => {
    const fetch = async () => {
      try {
        const result = await apiGetSystemFixById(params.id);
        setSystemFix(result);
      } catch (err) {
        NotificationManager.error('Something is wrong. Please refresh or try again later');
      }
    };

    fetch();
  }, [params.id]);

  if (systemFix) {
    return (
      <ConfirmFixExecution loading={systemFixExecution.loading} fix={systemFix} submit={runFix} cancel={goBack} />
    );
  }

  return null;
};

export default ConfirmFixExecutionContainer;
