import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import AppAccessSwitch from '../common/components/AppAccessSwitch';
import SystemFixesList from '../pages/SystemFixesList/SystemFixesList';
import QueryParamRoute from '../common/components/QueryParamRoute';
import ConfirmFixExecutionContainer from '../pages/ConfirmFixExecution/ConfirmFixExecutionContainer';

const SystemFixesRoutes = () => (
  <>
    <AppAccessSwitch role="ADMIN">
      <Route path="/other-fixes/list">
        <SystemFixesList />
      </Route>
      <Redirect from="/other-fixes" to="/other-fixes/list" />
    </AppAccessSwitch>
    <QueryParamRoute property="action" value="run-fix" requiredParams={['id']}>
      <ConfirmFixExecutionContainer />
    </QueryParamRoute>
  </>
);

export default SystemFixesRoutes;
