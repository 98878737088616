import React from 'react';
import styled from 'styled-components';
import { ContentNavBar, NavBarActions } from '../../../ui/ContentNavBar';
import TooltipButton from '../../../ui/TooltipButton';
import { Table, TableActionCell } from '../../../ui/Table';
import printDate from '../../../common/lib/print-date';
import LargeBox from '../../../ui/LargeBox';
import MaterialIcon from '@material/react-material-icon';

const Icon = styled(MaterialIcon)`
  font-size: 21px;
  color: ${({ color = 'crimson'}) => color};
`;

const LegacyUsersList = ({ users, onMigrateClick }) => (
  <LargeBox heading="Users">
    <ContentNavBar>
      <NavBarActions />
      <NavBarActions>
        <TooltipButton icon="build" text="migrate" onClick={onMigrateClick} />
      </NavBarActions>
    </ContentNavBar>
    <Table isEmpty={users.length === 0} isEmptyComponent="There are no legacy users.">
      <thead>
      <tr>
        <th>#</th>
        <th>Full Name</th>
        <th>Email</th>
        <th>verified</th>
        <th>Created At</th>
      </tr>
      </thead>
      <tbody>
      {
        users.map(user => (
          <tr key={user._id}>
            <td>{ !user.verified ? (
              <Icon title="User needs to verify before migration" icon="warning" />
            ) : (
              <Icon icon="check" color="#00ff00" />
            )}</td>
            <td>{ user.lastName } { user.firstName }</td>
            <td>{ user.email }</td>
            <td>{ user.verified ? 'Verified' : 'Non Verified' }</td>
            <td>{printDate(user.createdAt)}</td>
          </tr>
        ))
      }
      </tbody>
    </Table>
  </LargeBox>
);

export default LegacyUsersList;
