import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import Dialog from '../../ui/Dialog';
import { Table } from '../../ui/Table';
import printDate from '../../common/lib/print-date';

const SubscriptionDetailsDialog = ({ subscription, close }) => (
  <Dialog
    open
    title="Subscription Details"
    closeDialog={close}
    buttons={[]}
  >
    <Table bordered={false}>
      <tbody>
      <tr>
        <th>Plan</th>
        <td>{subscription.subscriptionPlan.name}</td>
      </tr>
      <tr>
        <th>Status</th>
        <td>{subscription.status}</td>
      </tr>
      <tr>
        <th>Started At</th>
        <td>{printDate(subscription.activationDate)}</td>
      </tr>
      <tr>
        <th>Ended At</th>
        <td>{printDate(subscription.expirationDate)}</td>
      </tr>
      {
        subscription.status === 'CANCELLED' && subscription.lastCancellationReason && (
          <tr>
            <th>Reason</th>
            <td>
              { ReactHtmlParser(subscription.lastCancellationReason )}
            </td>
          </tr>
        )
      }
      </tbody>
    </Table>
  </Dialog>
);

export default SubscriptionDetailsDialog;
