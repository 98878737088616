import { Field } from 'formik';
import SelectWithPopover from './SelectWithPopover';
import React from 'react';

const SelectInput = ({ name, ...props }) => (
  <Field
    name={name}
  >
    {
      ({ field }) => (
        <SelectWithPopover {...field} {...props} />
      )
    }
  </Field>
);

export default SelectInput;
