import React from 'react';
import { useParams } from 'react-router-dom';
import RemoveSubscriptionPlan from '../components/RemoveSubscriptionPlan';
import { NotificationManager } from 'react-notifications';
import { apiGetSubscriptionPlan, apiRemoveSubscriptionPlan } from '../api';
import useGoBack from '../../../common/hooks/use-go-back';
import { useQueryMap } from '../../../common/hooks/use-query';

const RemoveSubscriptionPlanContainer = () => {
  const [subscriptionPlan, setSubscriptionPlan] = React.useState(null);
  const queryMap = useQueryMap();

  const goBack = useGoBack();

  const removeSubscriptionPlan = React.useCallback(async () => {
    try {
      await apiRemoveSubscriptionPlan(queryMap.subscriptionPlanId);
      NotificationManager.success(`${subscriptionPlan.name} has been successfully removed`);
      goBack();
    } catch (err) {
      if (err.status === 404 && err.data.name === 'OBJECT_NOT_FOUND_EXCEPTION') {
        NotificationManager.error(`${subscriptionPlan.name} no longer exists. Please refresh the page`);
      } else {
        NotificationManager.error(`Failed to remove super user. Please refresh or try again later`);
      }
    }
  }, [queryMap.subscriptionPlanId, subscriptionPlan, goBack]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await apiGetSubscriptionPlan(queryMap.subscriptionPlanId);
        setSubscriptionPlan(result);
      } catch (err) {
        if (err.status === 404 && err.data.name === 'OBJECT_NOT_FOUND_EXCEPTION') {
          NotificationManager.error('this subscription plan no longer exists. Please refresh the page');
        } else if(err.status === 403) {
          goBack();
          NotificationManager.error("This plan can't be deleted because it has been used in a subscription.");
        } else {
          NotificationManager.error(`Failed to fetch subscription plan. Please refresh or try again later`);
        }
        goBack();
      }
    };

    fetchData();
  }, [queryMap.subscriptionPlanId, goBack]);

  if (subscriptionPlan) {
    return (
      <RemoveSubscriptionPlan
        subscriptionPlan={subscriptionPlan}
        removeSubscriptionPlan={removeSubscriptionPlan}
        cancel={goBack}
      />
    );
  }

  return null;
};

export default RemoveSubscriptionPlanContainer;
