import React from 'react';
import { Field } from 'formik';
import Radio from './Radio';

export const FormikRadio = ({ name, value, ...props }) => (
  <Field name={name}>
    {
      ({ field, form }) => (
        <Radio
          name={field.name}
          value={field.value}
          checked={form.values[name] === value}
          {...field}
          onChange={event => {
            const { checked } = event.target;
            if (checked) {
              form.setFieldValue(field.name, value);
            }
            form.setFieldTouched(field.name);
          }}
          {...props}
        />
      )
    }
  </Field>
);
