import React, { useEffect, useState, useCallback} from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { usePaginate } from '../../../common/hooks/use-paginate';
import Breadcrumb from '../../../ui/Breadcrumb';
import SubscribersList from '../components/SubscribersList';
import { NotificationManager } from 'react-notifications';
import { apiGetSubscribers, apiResetActivities, apiExportSubscribers, apiGetPlans } from '../api';
import useOperation from "../../../common/hooks/use-operation";
import fileDownload from 'js-file-download';
import {
  useAddPropertyToQueryString,
  useAddPropertiesToQueryString,
  useQueryMap
} from '../../../common/hooks/use-query';
import MessageDialog from '../../../ui/MessageDialog';

const SubscribersListContainer = () => {
  const [
    currentPage,
    currentItemsPerPage,
    goToPage
  ] = usePaginate();

  const [
    subscribers,
    setSubscribers
  ] = useState([]);

  const [
    selectedSubscribers,
    setSelectedSubscribers
  ] = useState([]);

  const [
    total,
    setTotal
  ] = useState(0);

  const [
    loading,
    setLoading
  ] = useState(false);

  const [
    confirmResetActivities,
    setConfirmResetActivities
  ] = useState(false);


  const [
    plans,
    setPlans
  ] = useState([]);

  const addPropertiesToQueryString = useAddPropertiesToQueryString();
  const addPropertyToQueryString = useAddPropertyToQueryString();
  const history = useHistory();
  const match = useRouteMatch();

  const queryMap = useQueryMap();

  const { selectedPlan, search: currentSearch, order, orderBy, fromDate, toDate } = queryMap;

  const filterPlan = React.useCallback(planId => {
    history.push(`?${addPropertyToQueryString(['selectedPlan', planId])}`);
  }, [history, addPropertyToQueryString]);

  const onClickResetActivities = useCallback(async () => {
    setConfirmResetActivities(true);
  }, [selectedSubscribers]);

  const resetActivities = useCallback(async () => {
    try {
       setLoading(true);
       await apiResetActivities(selectedSubscribers);
       NotificationManager.success('Success');
    } catch (err) {
        NotificationManager.error('Failed to load subscribers. Please refresh or try again later');
    } finally {
      setLoading(false);
    }
  }, [selectedSubscribers]);

  const selectSubscribers = useCallback( (event) => {
    const { value } = event.target;

    setSelectedSubscribers(selectedSubscribers.includes(value)
      ? selectedSubscribers.filter(subscriber => subscriber !== value)
      : [...selectedSubscribers, value]);

  }, [selectedSubscribers]);

	const [
		exportedSubscriber,
		onExport
  ] = useOperation(apiExportSubscribers);

  useEffect(() => {
		if(exportedSubscriber.success) {
      if(exportedSubscriber.data) {
        fileDownload(exportedSubscriber.data, 'data.csv');
      }
		}
	}, [exportedSubscriber.data, exportedSubscriber.success]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { subscribers, total } = await apiGetSubscribers({
          page: currentPage,
          itemsPerPage: currentItemsPerPage,
          search: currentSearch,
          plan: selectedPlan,
          order,
          orderBy,
          fromDate,
          toDate,
        });

        setSubscribers(subscribers);
        setTotal(total);
      } catch (err) {
        NotificationManager.error('Failed to load subscribers. Please refresh or try again later');
      }
    };

    fetchData();
    return history.listen((location, action) => {
      if (action === 'POP') {
        fetchData();
      }
    });
  }, [currentPage, currentItemsPerPage, currentSearch, selectedPlan, order, orderBy, fromDate, toDate, history]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const result = await apiGetPlans();
        setPlans(result);
      } catch (err) {
        NotificationManager.error('Something went wrong');
      }
    };

    fetch();
  }, []);

  const openSubscriberAssignmentDialog = React.useCallback((subscriberId) => {
    history.push(`${match.url}?${addPropertiesToQueryString([
      ['action', 'assign-subscriber'],
      ['subscriberId', subscriberId],
    ])}`);
  }, [match.url, addPropertiesToQueryString, history]);

  const onSearchChange = React.useCallback((search) => {
    history.push(`${match.url}?${addPropertyToQueryString(['search', search])}`);
  }, [history, match.url, addPropertyToQueryString]);

  return (
    <>
      <Breadcrumb
        items={[
          { title: 'Subscribers' }
        ]}
      />
      <SubscribersList
        plans={plans}
        selectedPlan={selectedPlan}
        onPlanChange={filterPlan}
        subscribers={subscribers}
        total={total}
        onSearchChange={onSearchChange}
        currentItemsPerPage={currentItemsPerPage}
        currentPage={currentPage}
        goToPage={goToPage}
        onClickResetActivities={onClickResetActivities}
        resetActivitiesOnGoing={loading}
        selectSubscribers={selectSubscribers}
        onExport={onExport}
        onAssignSubscriberClick={openSubscriberAssignmentDialog}
        history={history}
      />
      {
        confirmResetActivities && <MessageDialog
          open
          title="Reset search collection"
          centertitle="true"
          closeDialog={ () => setConfirmResetActivities(false) }
          buttons={[
            { text: 'Confirm', type: 'ok', onClick: () => {
              resetActivities();
              setConfirmResetActivities(false);
            } },
            { text: 'Cancel', type: 'cancel' }
          ]}
        >
          You are about to reset the search collection, this action is irreversible are you sure?
        </MessageDialog>

      }
    </>
  );
};

export default SubscribersListContainer;
