import React from 'reactn';
import { FETCH_SYSTEM_FIXES, fetchSystemFixesReducer } from './reducers';

const systemFixesListGState = () => {
  React.setGlobal({
    systemFixes: [],
  });

  React.addReducer(FETCH_SYSTEM_FIXES, fetchSystemFixesReducer);
};

export default systemFixesListGState;
