import React, { useMemo } from "react";
import PropTypes from "prop-types";
import MaterialIcon from "@material/react-material-icon";
import styled from "styled-components";
import PageItemsPerPageSelect from "./PageItemsPerPageSelect";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Icon = styled(MaterialIcon)`
  font-size: 42px;
  padding: 2px;
  color: #333;

  cursor: pointer;
  ${({ isdisabled = false }) => (isdisabled ? "color: #aaa" : "")}
`;

const Page = styled.div`
  cursor: pointer;
  padding: 0 10px;
  ${({ isdisabled = false }) => (isdisabled ? "padding: 0;" : "")}
  ${({ ishidden = false }) =>
    ishidden ? "display: none" : ""}
  
  font-family: "Open Sans", sans-serif;
  transform: scale(0.95);
  color: #aaa;
`;

const ActivePage = styled(Page)`
  color: #333;
  transform: scale(1);
`;

const Pagination = ({
  currentPage,
  currentItemsPerPage,
  onPageClick,
  total
}) => {
  const pagesNumber =
    total > currentItemsPerPage ? Math.ceil(total / currentItemsPerPage) : 1;

  const pageClicked = page => {
    if (page > pagesNumber) {
      onPageClick({
        page: pagesNumber,
        itemsPerPage: currentItemsPerPage
      });
    } else {
      onPageClick({
        page,
        itemsPerPage: currentItemsPerPage
      });
    }
  };

  const displayPages = useMemo(() => {
    return [currentPage - 1, currentPage, currentPage + 1];
  }, [currentPage]);

  const showFirst =
    (currentPage - 2 > 0 && pagesNumber > 3) || currentPage - 3 > 0;
  const showLast = currentPage + 2 < pagesNumber;

  const goToPage = page => {
    if (page > 0 && page <= pagesNumber) {
      pageClicked(page);
    }
  };

  const updateItemsPerPage = itemsPerPage => {
    const pagesNumberAfterUpdate =
      total > itemsPerPage ? Math.ceil(total / itemsPerPage) : 1;

    if (currentPage > pagesNumberAfterUpdate) {
      onPageClick({
        page: pagesNumberAfterUpdate,
        itemsPerPage
      });
    } else {
      onPageClick({
        page: currentPage,
        itemsPerPage
      });
    }
  };

  return (
    <Wrapper>
      <PageItemsPerPageSelect
        currentItemsPerPage={currentItemsPerPage}
        onItemsPerPageSelect={updateItemsPerPage}
      />
      <Icon
        icon="chevron_left"
        onClick={() => goToPage(displayPages[0])}
        isdisabled={pagesNumber === 1 || currentPage === 1 ? 1 : 0}
      />
      <Page
        isdisabled={showFirst}
        ishidden={!showFirst}
        onClick={() => goToPage(1)}
      >
        {showFirst && "1 . . ."}
      </Page>
      <Page
        isdisabled={displayPages[2] <= pagesNumber && displayPages[0] - 1 <= 0}
        onClick={() => goToPage(displayPages[0] - 1)}
      >
        {displayPages[2] > pagesNumber &&
          displayPages[0] - 1 > 0 &&
          displayPages[0] - 1}
      </Page>
      <Page
        isdisabled={displayPages[0] < 1}
        onClick={() => goToPage(displayPages[0])}
      >
        {displayPages[0] > 0 && displayPages[0]}
      </Page>
      <ActivePage>{displayPages[1]}</ActivePage>
      <Page
        isdisabled={displayPages[2] > pagesNumber}
        onClick={() => goToPage(displayPages[2])}
      >
        {displayPages[2] <= pagesNumber && displayPages[2]}
      </Page>
      <Page
        isdisabled={displayPages[0] < 0 && displayPages[2] + 1 > pagesNumber}
        onClick={() => goToPage(displayPages[2] + 1)}
      >
        {displayPages[2] + 1 <= pagesNumber && displayPages[2] + 1}
      </Page>
      <Page
        isdisabled={showLast}
        ishidden={!showLast}
        onClick={() => goToPage(pagesNumber)}
      >
        {showLast && `. . . ${pagesNumber}`}
      </Page>
      <Icon
        icon="chevron_right"
        onClick={() => goToPage(displayPages[2])}
        isdisabled={pagesNumber === 1 || currentPage === pagesNumber ? 1 : 0}
      />
    </Wrapper>
  );
};

Pagination.propTypes = {
  total: PropTypes.number,
  currentItemsPerPage: PropTypes.number,
  currentPage: PropTypes.number,
  onPageClick: PropTypes.func
};

Pagination.defaultProps = {
  currentPage: 1,
  currentItemsPerPage: 6,
  total: 0
};

export default Pagination;
