import React from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import FormDialog from '../../../ui/FormDialog';
import { submitOnce } from '../../../common/lib/formik-helpers';
import FormGroup from '../../../ui/FormGroup';
import Label from '../../../ui/Label';
import TextInput from '../../../ui/formik/TextInput';
import FormikErrorMessage from '../../../ui/FormikErrorMessage';
import SectionLoader from '../../../ui/SectionLoader';
import { useQueryMap } from '../../../common/hooks/use-query';

const validationSchema = yup.object({
  name: yup.string().required('Category name is required'),
});

const EmailTemplateTagFormDialog = ({ title, tag, save, open, cancel, loading }) => {
  const queryMap = useQueryMap();
  const submit = React.useCallback(submitOnce(save), [save]);
  const { id } = queryMap;

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        name: tag?.name || '',
      }}
      enableReinitialize
      onSubmit={submit}>
      {
        ({ handleSubmit }) => (
          <FormDialog
            open={open}
            title={title}
            closeDialog={cancel}
            buttons={[
              { text: 'Save', type: 'ok', onClick: handleSubmit },
              { text: 'Cancel', type: 'cancel' }
            ]}
          >
            {
              loading && (
                <SectionLoader />
              )
            }
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label htmlFor="name">
                  Name
                </Label>
                <TextInput name="name" />
                <FormikErrorMessage name="name" />
              </FormGroup>
            </Form>
            <br />
            <br />
          </FormDialog>
        )
      }
    </Formik>
  );
};

export default EmailTemplateTagFormDialog;
