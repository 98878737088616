import React from 'react';
import MessageDialog from '../../../ui/MessageDialog';

const RemoveSuperUser = ({ superUser, removeSuperUser, cancel }) => (
  <MessageDialog
    open
    title="Remove super user"
    centerTitle
    closeDialog={cancel}
    buttons={[
      { text: 'Confirm', type: 'ok', onClick: removeSuperUser },
      { text: 'Cancel', type: 'cancel' }
    ]}
  >
    You are about to remove {superUser.email}, this action is irreversible are you sure?
  </MessageDialog>
);

export default RemoveSuperUser;
