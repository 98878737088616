import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Heading, { HeadingText } from './Heading';

const Wrapper = styled.div`
  padding: 25px 20px 40px;
  margin: 17px 0;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  background-color: #fefefe;
  position: relative;
`;

const Content = styled.div`
  margin-top: 20px;
`;

const LargeBox = ({ heading, children, ...props }) => (
  <Wrapper {...props}>
    {
      heading && (
        <Heading>
          {
            typeof heading === 'string' ? (
              <HeadingText>{ heading }</HeadingText>
            ) : heading
          }
        </Heading>
      )
    }
    <Content>
      { children }
    </Content>
  </Wrapper>
);

LargeBox.propTypes = {
  heading: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
};


export default LargeBox;
