import React from 'react';
import styled from 'styled-components';
import { ContentNavBar, NavBarActions } from '../../../ui/ContentNavBar';
import { Table, TableActionCell, TableFooter } from '../../../ui/Table';
import LargeBox from '../../../ui/LargeBox';
import Pagination from '../../../ui/Pagination';
import TooltipButton from '../../../ui/TooltipButton';
import TooltipLink from '../../../ui/TooltipLink';
import { useAddPropertiesToQueryString } from '../../../common/hooks/use-query';
import CanSeeUsingRole from '../../../common/components/CanSeeUsingRole';
import SUBSCRIPTION_PLAN_PRODUCT_TYPE from '../../../../src/common/constants';

const printQuota = (quota, unlimited) => unlimited ? 'Unlimited' : quota;

const PlanRow = styled.tr`
  > td {
    font-weight: ${({ isdefault }) => isdefault ? 'bold' : 'normal'};
    color: ${({ isvisible }) => isvisible ? '#000' : '#555'}
  }
`;

const SubscriptionPlansList = ({ subscriptionPlans, onRefresh, currentPage, currentItemsPerPage, goToPage, total }) => {
  const query = useAddPropertiesToQueryString();
  return (
    <LargeBox heading="Subscription Plans">
      <ContentNavBar>
        <NavBarActions>
          <TooltipButton icon="refresh" onClick={onRefresh} />
        </NavBarActions>
        <NavBarActions>
          <CanSeeUsingRole role="ADMIN">
            <TooltipLink icon="add" text="New Subscription Plan" to={`?${query([['action', 'add-subscription-plan']])}`} />
          </CanSeeUsingRole>
        </NavBarActions>
      </ContentNavBar>
      <Table isEmpty={total === 0} isEmptyComponent="There are no subscription Plans. Please click on + to add one">
        <thead>
          <tr>
            <th className="text-cell">Name</th>
            <th className="text-cell">Credit Card</th>
            <th className="number-cell">Position</th>
            <th className="text-cell">Visible</th>
            <th className="text-cell">Default</th>
            <th className="text-cell">Spaces</th>
            <th className="text-cell">Activities</th>
            <th className="text-cell">Workflows</th>
            <th className="text-cell">Team Board Cards</th>
            <th className="text-cell">Reports</th>
            <th className="number-cell">File Export</th>
            <th className="text-cell">Free Files</th>
            <th className="text-cell">Files GB Price</th>
            <th className="text-cell">Price</th>
            <th className="text-cell">Email Campaign</th>
            <CanSeeUsingRole>
              <th>Actions</th>
            </CanSeeUsingRole>
          </tr>
        </thead>
        <tbody>
          {
            subscriptionPlans.map(plan => (
              <PlanRow key={plan._id} isvisible={plan.visible ? 1 : 0} isdefault={plan.isDefault ? 1 : 0}>
                <td className="text-cell">{plan.name}</td>
                <td className="text-cell">{plan.type === 'FREE' ? 'No' : 'Yes'}</td>
                <td className="number-cell">{plan.position}</td>
                <td className="text-cell">{plan.visible ? 'Yes' : 'No'}</td>
                <td className="text-cell">{plan.isDefault ? 'Yes' : 'No'}</td>
                <td className="text-cell">{printQuota(plan.spacesQuota, plan.unlimitedSpaces)}</td>
                <td className="text-cell">{printQuota(plan.activitiesQuota, plan.unlimitedActivities)}</td>
                <td className="text-cell">{printQuota(plan.workflowsQuota, plan.unlimitedWorkflows)}</td>
                <td className="text-cell">{printQuota(plan.teamBoardCardsQuota, plan.unlimitedTeamBoardCards)}</td>
                <td className="text-cell">{printQuota(plan.reportsQuota, plan.unlimitedReports)}</td>
                <td className="number-cell">{plan.defaultProducts.includes(SUBSCRIPTION_PLAN_PRODUCT_TYPE.DATA_EXPORT) ? 'true' : 'false'}</td>
                <td className="text-cell">{plan.defaultProducts.includes(SUBSCRIPTION_PLAN_PRODUCT_TYPE.FILES_STORAGE) ? `${printQuota(plan.freeFilesQuota, plan.unlimitedFreeFiles)} (Gb)`: '-'}</td>
                <td className="text-cell">{plan.type !== 'FREE' && plan.defaultProducts.includes(SUBSCRIPTION_PLAN_PRODUCT_TYPE.FILES_STORAGE) ? plan.filesGbPrice : '-'}</td>
                <td className="text-cell">{plan.type !== 'FREE' ? `${plan.price.toFixed(2)} €` : '-'}</td>
                <td className="text-cell">
                  {plan.emailUnitPrice ? `€${plan.emailUnitPrice}/${plan.emailUnit}` : '-'} <br />
                  {plan.emailUnitPrice ? `Free E-mail: ${plan.freeEmails || '-'}` : ''}
                </td>

                <CanSeeUsingRole role="ADMIN">
                  <TableActionCell>
                    <TooltipLink icon="list" text="subscribers" to={`/subscribers/list/?${query([
                      ['selectedPlan', plan._id],
                    ])}`} />
                    <TooltipLink icon="edit" text="edit" to={`?${query([
                      ['action', 'edit-subscription-plan'],
                      ['subscriptionPlanId', plan._id],
                    ])}`} />
                    <TooltipLink icon="close" text="remove" to={`?${query([
                      ['action', 'remove-subscription-plan'],
                      ['subscriptionPlanId', plan._id],
                    ])}`} />
                  </TableActionCell>
                </CanSeeUsingRole>
              </PlanRow>
            ))
          }
        </tbody>
      </Table>
      <TableFooter>
        <Pagination
          currentPage={currentPage}
          currentItemsPerPage={currentItemsPerPage}
          onPageClick={goToPage}
          total={total}
        />
      </TableFooter>
    </LargeBox>
  );
};

export default SubscriptionPlansList;
