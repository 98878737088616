import React from 'react';
import SubscriberSalesmanAssignmentDialog from './components/SubscriberSalesmanAssignmentDialog';
import { NotificationManager } from 'react-notifications';
import { apiGetSalesmen, apiGetSubscriberById } from '../../common/api';
import { apiAssignSubscriber } from './api';
import { useQueryMap } from '../../common/hooks/use-query';
import useGoBack from '../../common/hooks/use-go-back';

const SubscriberSalesmanAssignment = () => {
  const [salesmen, setSalesmen] = React.useState([]);
  const [oldSalesman, setOldSalesman] = React.useState(null);
  const params = useQueryMap();
  const goBack = useGoBack();

  const assignSubscriber = React.useCallback(async ({ salesman }) => {
    try {
      await apiAssignSubscriber({
        salesman,
        subscriber: params.subscriberId,
      });

      NotificationManager.success('Subscriber has been assigned');
      goBack();
    } catch (err) {
      NotificationManager.error('Failed to assign salesman to subscriber');
    }
  }, [params.subscriberId, goBack]);

  React.useEffect(() => {
    const fetch = async () => {
      try {
        const result = await apiGetSalesmen();
        setSalesmen(result);
      } catch (err) {
        NotificationManager.error('Something wrong happened');
      }
    };

    fetch();
  }, []);

  React.useEffect(() => {
    const fetch = async () => {
      try {
        const result = await apiGetSubscriberById(params.subscriberId);
        setOldSalesman(result.salesman);
      } catch (err) {
        NotificationManager.error('Something went wrong');
      }
    };

    fetch();
  }, [params.subscriberId]);

  if (salesmen) {
    return (
      <SubscriberSalesmanAssignmentDialog
        oldSalesman={oldSalesman?.email}
        cancel={goBack}
        salesmen={salesmen}
        assignSubscriber={assignSubscriber}
      />
    );
  }

  return null;
};

export default SubscriberSalesmanAssignment;
