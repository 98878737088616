import React, { useGlobal } from "reactn";
import Header from "../components/Header";
import { useCallback } from "react";

const HeaderContainer = () => {
  const [loginData] = useGlobal("loginData");

  const [sideMenu] = useGlobal("sideMenu");

  const onLogoutClick = useCallback(() => {
    localStorage.clear();
    window.location.pathname = "/";
  }, []);

  return (
    <Header
      user={loginData.user}
      onLogoutClick={onLogoutClick}
      extended={sideMenu.opened}
    />
  );
};

export default HeaderContainer;
