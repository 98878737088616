import React from 'react';
import { Route } from 'react-router-dom';
import AppAccessSwitch from '../common/components/AppAccessSwitch';
import EmailTemplateCategoriesContainer from '../pages/EmailCampaigns/containers/EmailTemplateCategoriesContainer';
import EmailTemplatesContainer from '../pages/EmailCampaigns/containers/EmailTemplatesContainer';
import EmailTemplateTagsContainer from '../pages/EmailCampaigns/containers/EmailTemplateTagsContainer';


const EmailCampaingsRoutes = () => (
  <AppAccessSwitch role="ADMIN">
      <Route path="/email-campaigns/templates" component={EmailTemplatesContainer} />
      <Route path="/email-campaigns/template-categories" component={EmailTemplateCategoriesContainer} />
      <Route path="/email-campaigns/template-tags" component={EmailTemplateTagsContainer} />
  </AppAccessSwitch>
);

export default EmailCampaingsRoutes;
