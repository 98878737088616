import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import Breadcrumb from '../../../ui/Breadcrumb';
import SubscriptionsList from '../components/SubscriptionsList';
import { usePaginate } from '../../../common/hooks/use-paginate';
import { NotificationManager } from 'react-notifications';
import { apiGetSubscriptionsList } from '../../SubscriptionPlansList/api';
import SubscriptionCancellationContainer from '../../SubscriptionCancellation/containers/SubscriptionCancellationContainer';
import QueryParamRoute from '../../../common/components/QueryParamRoute';
import CreateNewSubscriptionContainer from '../../CreateNewSubscription/containers/CreateNewSubscriptionContainer';
import SendSubscriptionCheckoutEmail from '../../SendSubscriptionCheckoutEmail/SendSubscriptionCheckoutEmail';
import { useAddPropertiesToQueryString } from '../../../common/hooks/use-query';

const SubscriptionsListContainer = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const params = useParams();
  const addPropertiesToQueryString = useAddPropertiesToQueryString();

  const [
    currentPage,
    currentItemsPerPage,
    goToPage
  ] = usePaginate();

  const [
    subscriptions,
    setSubscriptions
  ] = useState([]);

  const [
    total,
    setTotal
  ] = useState(0);

  const openCreateSubscriptionDialog = React.useCallback(() => {
    history.push(`${match.url}?${addPropertiesToQueryString([
      ['action', 'create-subscription'],
      ['subscriberId', params.subscriberId],
    ])}`);
  }, [history, match.url, addPropertiesToQueryString, params.subscriberId]);

  const openSubscriptionTerminationDialog = React.useCallback((subscriptionId) => {
    history.push(`${match.url}?${addPropertiesToQueryString([
      ['action', 'cancel-subscription'],
      ['subscriptionId', subscriptionId],
    ])}`);
  }, [history, match.url, addPropertiesToQueryString]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { subscriptions: data, total } = await apiGetSubscriptionsList(params.subscriberId, {
          page: currentPage,
          itemsPerPage: currentItemsPerPage
        });

        setSubscriptions(data);
        setTotal(total);
      } catch (err) {
        NotificationManager.error('Failed to load users. Please refresh or try again later');
      }
    };

    fetchData();

    return history.listen(function (location, action) {
      if (action === 'POP') {
        fetchData();
      }
    });
    }, [history, currentPage, currentItemsPerPage, params.subscriberId]);

  return (
    <React.Fragment>
      <Breadcrumb
        items={[
          { title: 'Subscriber' },
          { title: 'Subscriptions' }
        ]}
      />
      <SubscriptionsList
        subscriptions={subscriptions}
        total={total}
        currentItemsPerPage={currentItemsPerPage}
        currentPage={currentPage}
        goToPage={goToPage}
        onCreateSubscriptionClick={openCreateSubscriptionDialog}
        onTerminateSubscriptionClick={openSubscriptionTerminationDialog}
      />
      <QueryParamRoute property="action" value="cancel-subscription" requiredParams={['subscriptionId']}>
        <SubscriptionCancellationContainer />
      </QueryParamRoute>
      <QueryParamRoute property="action" value="create-subscription" requiredParams={['subscriberId']}>
        <CreateNewSubscriptionContainer />
      </QueryParamRoute>
      <QueryParamRoute property="action" value="send-subscription-checkout-email" requiredParams={['subscriptionId']}>
        <SendSubscriptionCheckoutEmail />
      </QueryParamRoute>
    </React.Fragment>
  );
};

export default SubscriptionsListContainer;
