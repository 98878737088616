import React from 'react';
import { useHistory } from 'react-router-dom';
import { usePaginate } from '../../../common/hooks/use-paginate';
import { NotificationManager } from 'react-notifications';
import { apiGetSuperUsers } from '../api';
import SuperUsersList from '../components/SuperUsesList';

const SuperUsersListContainer = () => {
  const [
    currentPage,
    currentItemsPerPage,
    goToPage,
  ] = usePaginate();

  const history = useHistory();

  const [superUsers, setSuperUsers] = React.useState({
    data: [],
    total: 0,
  });

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await apiGetSuperUsers({
          page: currentPage,
          itemsPerPage: currentItemsPerPage,
        });

        setSuperUsers({
          data: result.superUsers,
          total: result.total,
        });
      } catch (err) {
        NotificationManager.error('Failed to load users. Please refresh or try again later');
      }
    };

    fetchData();

    return history.listen((location, action) => {
      if (action === 'POP') {
        fetchData();
      }
    });
  }, [currentPage, currentItemsPerPage, history]);

  return (
    <SuperUsersList
      superUsers={superUsers.data}
      goToPage={goToPage}
      currentPage={currentPage}
      currentItemsPerPage={currentItemsPerPage}
      total={superUsers.total}
    />
  )
};

export default SuperUsersListContainer;
