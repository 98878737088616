import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { usePaginate } from '../../../common/hooks/use-paginate';
import Breadcrumb from '../../../ui/Breadcrumb';
import { NotificationManager } from 'react-notifications';
import {
  useAddPropertyToQueryString,
  useQueryMap
} from '../../../common/hooks/use-query';
import {
  apiGetTemplatesCategories,
  apiGetTemplateCategoryById,
  apiSaveTemplateCategories,
  apiDeleteTemplateCategory,
} from '../api';
import EmailTemplateCategoriesList from '../components/EmailTemplateCategoriesList';
import useAsyncOperation from '../../../common/hooks/use-async-operation';

const EmailTemplateCategoriesContainer = () => {

  const [open, setOpen] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [category, setCategory] = React.useState({});
  const [asyncSaveTemplateCategories, templateCategoriesCreation] = useAsyncOperation(apiSaveTemplateCategories);

  const [
    currentPage,
    currentItemsPerPage,
    goToPage
  ] = usePaginate();

  const [
    categories,
    setCategories
  ] = useState([]);

  const [
    total,
    setTotal
  ] = useState(0);

  const addPropertyToQueryString = useAddPropertyToQueryString();
  const history = useHistory();
  const match = useRouteMatch();

  const queryMap = useQueryMap();

  const { search: currentSearch, order, orderBy } = queryMap;

  const fetchData = async () => {
    try {
      const { categories, total } = await apiGetTemplatesCategories({
        page: currentPage,
        itemsPerPage: currentItemsPerPage,
        order,
        orderBy,
        search: currentSearch,
      });

      setCategories(categories);
      setTotal(total);
    } catch (err) {
      NotificationManager.error('Failed to load email template categories. Please refresh or try again later');
    }
  };

  useEffect(() => {
    fetchData();
    return history.listen((location, action) => {
      if (action === 'POP') {
        fetchData();
      }
    });
  }, [currentPage, currentItemsPerPage, currentSearch, order, orderBy, history]);

  const onSearchChange = React.useCallback((search) => {
    history.push(`${match.url}?${addPropertyToQueryString(['search', search])}`);
  }, [history, match.url, addPropertyToQueryString]);

  const onRefresh = React.useCallback(() => {
    fetchData();
  }, []);

  const onEdit = React.useCallback(async(id) => {
    try {
      const fetchedCategory = await apiGetTemplateCategoryById(id);
      setCategory(fetchedCategory);
      setOpen(true);
    } catch (err) {
      NotificationManager.error('Failed to load email template. Please refresh or try again later');
    }
  }, [category]);

  const save = React.useCallback(async (data, { resetForm }) => {
    try {
      await asyncSaveTemplateCategories(data);
      resetForm();
      setCategory({});
      onRefresh();
      setOpen(false);
    } catch (err) {
      NotificationManager.error('Failed to delete Category. Please refresh or try again later');
    }
  }, []);

  const onDelete = React.useCallback((id) => {
    setCategory({ id });
    setOpenDeleteDialog(true);
  }, [category]);

  const deleteCategory = React.useCallback(async () => {
    try {
      await apiDeleteTemplateCategory(category.id);
      setCategory({});
      onRefresh();
      setOpenDeleteDialog(false);
    } catch (err) {
      NotificationManager.error('Failed to delete Category. Please refresh or try again later');
    }
  }, [category]);

  return (
    <>
      <Breadcrumb
        items={[
          { title: 'Email Template Categories' }
        ]}
      />

      <EmailTemplateCategoriesList
        categories={categories}
        category={category}
        setCategory={setCategory}
        currentPage={currentPage}
        currentItemsPerPage={currentItemsPerPage}
        currentSearch={currentSearch}
        total={total}
        goToPage={goToPage}
        onRefresh={onRefresh}
        onSearchChange={onSearchChange}
        onEdit={onEdit}
        save={save}
        onDelete={onDelete}
        deleteCategory={deleteCategory}
        setOpen={setOpen} open={open}
        setOpenDeleteDialog={setOpenDeleteDialog} openDeleteDialog={openDeleteDialog}
        loading={templateCategoriesCreation.loading}
      />

    </>
  );
};

export default EmailTemplateCategoriesContainer;
