import React, { useEffect, useMemo, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import PropTypes from "prop-types";
import { Transition } from "react-transition-group";
import styled from "styled-components";
import logo from "../../../images/logo.png";
import MaterialIcon from "@material/react-material-icon";
import Button from "@material-ui/core/Button";
import SideMenuItem from "./SideMenuItem";
import SideMenuItemDropdown from "./SideMenuDropdown";
import { NavLink as RouterNavLink } from "react-router-dom";
import { MQ_LARGE_DOWN } from '../../../common/breakpoints';

const THIS_YEAR = new Date().getFullYear();

const Wrapper = styled.div`
  background-color: #0078d4;
  z-index: 2;
  height: 100vh;
  box-shadow: 0 0 1px #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  position: relative;
  transition: width ease-in-out 200ms, min-width ease-in-out 200ms;

  @media ${MQ_LARGE_DOWN} {
    position: fixed;
  }

  width: ${({ animationstate }) => {
    switch (animationstate) {
      case "entered":
        return "240px";
      case "exited":
        return "50px";
      case "entering":
        return "50px";
      case "exiting":
        return "240px";
      default:
        return "240px";
    }
  }};
  min-width: ${({ animationstate }) => {
    switch (animationstate) {
      case "entered":
        return "240px";
      case "exited":
        return "0";
      case "entering":
        return "0";
      case "exiting":
        return "240px";
      default:
        return "240px";
    }
  }};
`;

const Logo = styled.img`
  width: 170px;
  height: auto;
  display: ${({ animationstate }) => {
    switch (animationstate) {
      case "entered":
        return "block";
      case "exited":
        return "none";
      case "entering":
        return "none";
      case "exiting":
        return "block";
      default:
        return "block";
    }
  }};
`;

const MenuIcon = styled(MaterialIcon)`
  color: #fff;
  font-size: 18px;
  text-align: center;
`;

const MenuButton = styled(Button)`
  &.menu-button {
    min-width: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    top: 6px;
    padding-top: 9px;
    border: 1px solid #fff;

    right: ${({ animationstate }) => {
      switch (animationstate) {
        case "entered":
          return "-20px";
        case "exited":
          return "5px";
        case "entering":
          return "5px";
        case "exiting":
          return "-20px";
        default:
          return "-20px";
      }
    }};

    &:not(hover) {
      background-color: #0078d4;
    }

    &:hover {
      background-color: #0078d4fe;
    }
  }
`;

const SideMenuItems = styled(Scrollbars)`
  > div {
    display: flex;
    flex-direction: column;
  }

  margin-top: 20px;
  width: 100%;
  overflow-y: scroll;
  padding-bottom: 30px;
`;

const NavLink = styled(RouterNavLink)`
  text-decoration: none;
  &.active {
    .side-menu-item {
      background-color: rgba(0, 0, 0, 0.15) !important;
    }
  }
`;

const Footer = styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  padding-left: 20px;
  color: #fff;
  position: absolute;
  padding-bottom: 5px;
  bottom: 0;
  left: 0;
  transition: display ease-in-out 200ms 200ms;
  display: ${({ opened }) => (opened ? "block" : "none")};
  background-color: inherit;
`;

const SideMenu = ({
  opened,
  sideMenuItems = [],
  userRole,
  selectedSideMenuItem,
  onMenuButtonClick,
  onSideMenuItemClick
}) => {
  const initialSideMenuDropdown = useMemo(() => {
    const result = sideMenuItems
      .filter(sideMenuItem => sideMenuItem.isDropdown)
      .find(dropdown => dropdown.isOpen(selectedSideMenuItem) === true);

    if (result) {
      return result.id;
    }

    return null;
  }, [selectedSideMenuItem, sideMenuItems]);

  const [clickedSideMenuDropdown, setClickedSideMenuDropDown] = useState(
    initialSideMenuDropdown
  );

  useEffect(() => {
    if (clickedSideMenuDropdown !== selectedSideMenuItem) {
      setClickedSideMenuDropDown(initialSideMenuDropdown);
    }
  }, [selectedSideMenuItem, setClickedSideMenuDropDown]);

  return (
    <Transition in={opened} timeout={0}>
      {animationState => (
        <Wrapper animationstate={animationState}>
          <MenuButton
            animationstate={animationState}
            onClick={onMenuButtonClick}
            classes={{
              root: "menu-button"
            }}
          >
            <MenuIcon icon="menu" />
          </MenuButton>
          <Transition in={opened} timeout={opened ? 200 : 0}>
            {animationState => (
              <Logo animationstate={animationState} src={logo} />
            )}
          </Transition>
          <SideMenuItems>
            {sideMenuItems.map(sideMenuItem => {
              if (!sideMenuItem.roles.includes(userRole) && userRole !== 'ADMIN') {
                return null;
              }
              if (sideMenuItem.isDropdown) {
                return (
                  <SideMenuItemDropdown
                    key={sideMenuItem.id}
                    onClick={() => setClickedSideMenuDropDown(sideMenuItem.id)}
                    expanded={
                      animationState === "entered" ||
                      animationState === "exiting"
                    }
                    opened={
                      sideMenuItem.isOpen(selectedSideMenuItem) &&
                      clickedSideMenuDropdown === sideMenuItem.id
                    }
                    icon={sideMenuItem.icon}
                    title={sideMenuItem.title}
                  >
                    {sideMenuItem.subSideMenuItems.map(subSideMenuItem => (
                      <NavLink
                        key={subSideMenuItem.id}
                        activeClassName="active"
                        to={subSideMenuItem.path}
                      >
                        <SideMenuItem
                          small
                          opened={
                            animationState === "entered" ||
                            animationState === "exiting"
                          }
                          selected={subSideMenuItem.isSelected(
                            selectedSideMenuItem
                          )}
                          title={subSideMenuItem.title}
                          onClick={() =>
                            onSideMenuItemClick(subSideMenuItem.id)
                          }
                        />
                      </NavLink>
                    ))}
                  </SideMenuItemDropdown>
                );
              }

              return (
                <NavLink
                  key={sideMenuItem.id}
                  activeClassName="active"
                  to={sideMenuItem.path}
                >
                  <SideMenuItem
                    opened={
                      animationState === "entered" ||
                      animationState === "exiting"
                    }
                    selected={sideMenuItem.isSelected(selectedSideMenuItem)}
                    icon={sideMenuItem.icon}
                    title={sideMenuItem.title}
                    onClick={() => onSideMenuItemClick(sideMenuItem.id)}
                  />
                </NavLink>
              );
            })}
          </SideMenuItems>
          <Footer opened={opened}>
            Prediwell.com © {THIS_YEAR} - ADMIN APP
          </Footer>
        </Wrapper>
      )}
    </Transition>
  );
};

SideMenu.propTypes = {
  selectedSideMenuItem: PropTypes.string,
  onMenuButtonClick: PropTypes.func.isRequired,
  onSideMenuItemClick: PropTypes.func.isRequired,
  hiddenMenuItems: PropTypes.object.isRequired,
  isOpen: PropTypes.func,
  sideMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      isDropdown: PropTypes.bool,
      onClick: PropTypes.func,
      isSelected: PropTypes.func,
      subSideMenuItems: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
          isDropdown: PropTypes.bool,
          onClick: PropTypes.func,
          isSelected: PropTypes.func
        })
      )
    })
  )
};

SideMenu.defaultProps = {
  opened: true,
  hiddenMenuItems: {}
};

export default SideMenu;
