import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { KeyboardDateTimePicker as MuiKeyboardDateTimePicker } from '@material-ui/pickers';

const KeyboardDateTimePicker = styled(MuiKeyboardDateTimePicker)`
  max-width: 450px;
`;

const DatetimePicker = ({ field, form, format = 'YYYY/MM/DD HH:mm A', ...props }) => (
  <KeyboardDateTimePicker
    classes={{
      root: 'root',
    }}
    value={field.value}
    onChange={(event, date) => {
      form.setFieldValue(field.name, moment(date, format).toDate());
      form.setFieldTouched(field.name);
    }}
    format={format}
    {...props}
  />
);

export default DatetimePicker;
