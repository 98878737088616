import React from 'reactn';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MaterialIcon from '@material/react-material-icon';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  position: relative;
  padding: 10px;
  &:hover {
    background-color: #ddd;
    border-radius: 50%;
    
    & > .Tooltip {
      display: block;
    }
  }
`;

const Icon = styled(MaterialIcon)`
  font-size: 18px;
  padding: 5px;
  color: #fff;
  background-color: ${({ color = '#0078D4' }) => color};
  box-shadow: 0 0 1px #333;
  border-radius: 12px;
  cursor: pointer;

  &:active {
    transform: scale(1.05);
  }
`;

const Tooltip = styled.div`
  position: absolute;
  display: none;
  color: #fff;
  top: 41px;
  left: -6px;
  font-size: 10px;
  background-color: #666;
  padding: 2px 5px;
  width: 68px;
  text-align: center;
`;

const TooltipButton = ({ icon, onClick, text, to, isLink, color, ...props }) => {
  const Container = isLink && !onClick ? (props) => (
    <Link to={to} {...props} />
  ) : React.Fragment;
  return (
    <Wrapper {...props}>
      <Container>
        <Link href="#" onClick={onClick} >
          <Icon color={color} icon={icon} />
        </Link>
      </Container>
      <Tooltip className="Tooltip">{text}</Tooltip>
    </Wrapper>
  )
};

TooltipButton.propTypes = {
  onClick: PropTypes.func
};

export default TooltipButton;
