import React from 'react';
import { Route } from 'react-router-dom';
import AppAccessSwitch from '../common/components/AppAccessSwitch';
import ChangeUserPasswordContainer from '../pages/EditSuperUser/containers/ChangeUsePasswordContainer';
import ChangeUserEmailContainer from '../pages/EditSuperUser/containers/ChangeUserEmailContainer';

const ChangePasswordEmailUserRoutes = () => (
    <AppAccessSwitch role="SALESMAN">
        <Route path="/user/change-email">
            <ChangeUserEmailContainer />
        </Route>
        <Route path="/user/change-password">
            <ChangeUserPasswordContainer />
        </Route>
    </AppAccessSwitch>
);

export default ChangePasswordEmailUserRoutes;
