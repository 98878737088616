import React from "react";
import styled from "styled-components";
import MaterialIcon from "@material/react-material-icon";
import Popover from "@material-ui/core/Popover";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import DropDownBox, { DropDownBoxButton } from "../../../ui/DropDownBox";
import UserAvatar from "../../../ui/UserAvatar";
import usePopover from "../../../common/hooks/use-popover";
import MailIcon from '@material-ui/icons/Mail';
import FingerprintIcon from '@material-ui/icons/Fingerprint';

const AccountButton = styled(Button)`
  &.account-button {
    min-width: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 7px;
`;

const AccountUserAvatar = styled(UserAvatar)`
  margin: 0 7px;
`;

const AccountBoxDropDownBox = styled(DropDownBox)`
  width: 210px;
`;

const LogoutButton = styled(DropDownBoxButton)`
  margin: 7px 0 !important;
  color: #fff !important;
  justify-content: center !important;

  &:not(:hover) {
    background-color: #eb5a46 !important;
  }

  &:hover {
    background-color: #eb5a46dd !important;
  }
`;

const Icon = styled(MaterialIcon)`
  color: #fff;
  font-size: 18px;
  margin-right: 7px;
`;


const AccountBox = ({ user, onLogoutClick }) => {
  const history = useHistory();
  const [anchorEl, open, id, handleClick, handleClose] = usePopover();

  if (user) {
    return (
      <Wrapper>
        <AccountButton
          classes={{
            root: "account-button"
          }}
          onClick={handleClick}
        >
          <AccountUserAvatar
            lastName={user.lastName}
            firstName={user.firstName}
          />
        </AccountButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <AccountBoxDropDownBox
            title={`${user.firstName} ${user.lastName}`}
            onCloseClick={handleClose}
          >
            <Button
              onClick={() => history.push('/user/change-email')}
              color="primary"
              startIcon={<MailIcon />}
            >
              Change Email
            </Button>

            <Button
              onClick={() => history.push('/user/change-password')}
              color="primary"
              startIcon={<FingerprintIcon />}
            >
              Change Password
            </Button>
            <LogoutButton onClick={onLogoutClick}>
              <Icon icon="power_settings_new" />
              Log out
            </LogoutButton>
          </AccountBoxDropDownBox>
        </Popover>
      </Wrapper>
    );
  }

  return null;
};

export default AccountBox;
