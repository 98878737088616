import apiClientRequest from '../../common/apiclient';

export const apiDeleteSubscriberData = async (id) =>
    apiClientRequest(`/subscribers/${id}`, {
        method: 'DELETE',
    });


export const apiCheckSubscriberSpaceOwnership = async (id) =>
    apiClientRequest(`/subscribers/${id}/spaces-ownership`, {
        method: 'GET',
    });