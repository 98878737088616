import React from 'react';
import { NotificationManager } from 'react-notifications';
import { apiChangeUserPassword } from '../api';
import useAsyncOperation from '../../../common/hooks/use-async-operation';
import useGoBack from '../../../common/hooks/use-go-back';
import ChangeUserPassword from '../components/ChangeUserPassword';

const ChangeUserPasswordContainer = () => {
  const goBack = useGoBack();
  const [asyncChangeUserPassword, userPasswordAlteration] = useAsyncOperation(apiChangeUserPassword);

  const save = React.useCallback(async ({ oldPassword, newPassword }) => {
    try {
      await asyncChangeUserPassword({ oldPassword, newPassword });
      NotificationManager.success(`Your password has been successfully changed.`);
      localStorage.clear();
      window.location.pathname = "/";
    } catch (err) { console.log(err)
      if (err.status === 403) {
        NotificationManager.error(err.data?.error || err.message);
      } else {
        NotificationManager.error('Failed to change your password. Please refresh or try again later');
      }
    }
  }, [goBack, asyncChangeUserPassword]);

  return (
    <ChangeUserPassword
      cancel={goBack}
      save={save}
      loading={userPasswordAlteration.loading}
    />
  )
};

export default ChangeUserPasswordContainer;

