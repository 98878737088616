import React from 'react';
import styled from 'styled-components';
import Dialog from './Dialog';

const MessageContent = styled.div`
  max-width: 400px;
`;

const MessageDialog = ({ children, ...props }) => (
  <Dialog
    {...props}
  >
    <MessageContent>{ children }</MessageContent>
  </Dialog>
);

export default MessageDialog;
