import styled from 'styled-components';
import { FormControlLabel } from '@material-ui/core';

export const ContentNavBar = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 20px 15px;
`;

export const NavBarActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const NavBarCheckbox = styled(FormControlLabel)`
  margin: 0;
`;
