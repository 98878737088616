import React, { useGlobal } from 'reactn';
import { Route } from 'react-router-dom';
import ForbiddenAccess from '../../pages/ForbiddenAccess/ForbiddenAccess';

const AppAccessRoute = ({ role: routeRole, ...props }) => {
  const [loginData] = useGlobal("loginData");
  const role = [].concat(routeRole);

  if (loginData) {
    const { user } = loginData;

    if (role.includes(user.role) || user.role === 'ADMIN') {
      return (
        <Route {...props} />
      )
    } else {
      return (
        <Route {...props}>
          <ForbiddenAccess />
        </Route>
      )
    }
  }

  return null;
};

export default AppAccessRoute;
