import apiClientRequest from '../../common/apiclient';

export const apiTerminateSubscription = async (id, { reason }) =>
  apiClientRequest(`/terminate-subscription/${id}`, {
    method: 'DELETE',
    data: {
      reason,
    }
  });

export const apiGetSubscriptionById = async id =>
  apiClientRequest(`/terminate-subscription/subscription/${id}`);
