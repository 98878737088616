import React from 'react';
import styled from 'styled-components';
import MuiButton from '@material-ui/core/Button';

const StyledButton = styled(MuiButton)`  
  &.button {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #fff;
    text-transform: none;
    
    &:not(:hover) {
      background-color: #5a5a5a;
    }
    
    &:hover {
      background-color: #5a5a5add;
    }
  }
`;

const Button = ({ ...props }) => (
  <StyledButton type="button" classes={{
    root: 'button'
  }} {...props} />
);

export default Button;
