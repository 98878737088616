import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';

import Breadcrumb from '../../ui/Breadcrumb';
import LargeBox from '../../ui/LargeBox';
import FormGroup from '../../ui/FormGroup';
import Label from '../../ui/Label';
import MuiInput from '@material-ui/core/Input';
import Button from "../../ui/Button";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import useAsyncOperation from '../../common/hooks/use-async-operation';
import { apiGetTawktoChatID, apiCreateOrUpdateTawktoChatSettings } from './api';
import { NotificationManager } from 'react-notifications';
import CircularProgress from "@material-ui/core/CircularProgress";

const ChatSetting = () => {
    const [tawktoId, setTawktoId] = useState(null);
    const [disabled, setDisabled] = useState(null);
    const [getTawktoChatID, tawktoChatID] = useAsyncOperation(apiGetTawktoChatID);
    const [createOrUpdateTawktoChatSettings, createdOrUpdatedTawktoChatSettings] = useAsyncOperation(apiCreateOrUpdateTawktoChatSettings);
    const submit = async ({ tawktoId, disabled }) => {
        try {
            const result = await createOrUpdateTawktoChatSettings({ tawktoId, disabled });
            setTawktoId(result.tawktoId);
            setDisabled(result.disabled);
        } catch (err) {
            throw err;
        }
    };

    useEffect(() => {
        const fetch = async () => {
            try {
                const result = await getTawktoChatID();
                setTawktoId(result.tawktoId);
                setDisabled(result.disabled);
            } catch (err) {
                NotificationManager.error('Something is wrong. Please refresh or try again later');
            }
        };

        fetch();
    }, []);

    useEffect(() => {
        if (createdOrUpdatedTawktoChatSettings.success) {
            NotificationManager.success('Tawk.to settings updated');
        }
    }, [createdOrUpdatedTawktoChatSettings.success]);



    return (
        <div>
            <Breadcrumb
                items={[
                    { title: 'chat settings' },
                ]}
            />
            <LargeBox heading="Chat settings">
                <Formik
                    initialValues={{
                        tawktoId,
                        disabled,
                    }}
                    enableReinitialize
                    onSubmit={submit}
                >
                    {
                        ({ handleSubmit, values, handleChange, handleBlur }) => (
                            <Form onSubmit={handleSubmit}>
                                <FormGroup>
                                    <Label htmlFor="tawktoId">Tawk.to chat ID</Label>
                                    <MuiInput
                                        type="text"
                                        value={values.tawktoId}
                                        name="tawktoId"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox name="disabled" checked={values.disabled}
                                            onChange={handleChange}
                                        />}
                                        label="Disable the chat"
                                    />
                                </FormGroup>
                                <Button type="submit" variant="contained" color="primary">
                                    {!createdOrUpdatedTawktoChatSettings.loading ? (
                                        "Save"
                                    ) : (
                                            <CircularProgress color="inherit" size={19} />
                                        )}
                                </Button>
                            </Form>
                        )
                    }
                </Formik>
            </LargeBox>
        </div>
    );
};

export default ChatSetting;