import React from 'react';
import { Field } from 'formik';
import ClearableInputComponent from '../ClearableInput';

const ClearableInput = ({ name, ...props }) => (
  <Field
    name={name}
  >
    {
      ({ field, form }) => (
        <ClearableInputComponent
          {...field}
          {...props}
          onChange={value => form.setFieldValue(field.name, value)}
        />
      )
    }
  </Field>
);

export default ClearableInput;
