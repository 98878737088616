import React from 'react';
import { NotificationManager } from 'react-notifications';
import { useQueryMap } from '../../common/hooks/use-query';
import useGoBack from '../../common/hooks/use-go-back';
import { apiDeleteSubscriberData, apiCheckSubscriberSpaceOwnership } from './api';
import { submitOnce } from '../../common/lib/formik-helpers';
import FormDialog from '../../ui/FormDialog';
import styled from 'styled-components';

const Warning = styled.div`
  color: red;
`;

const DeleteSubscriber = () => {
    const goBack = useGoBack();
    const query = useQueryMap();
    const [spaceOwnership, setSpaceOwnership] = React.useState(0);

    const handleSubmit = React.useCallback(submitOnce(async () => {
        try {
            await apiDeleteSubscriberData(query.id);
            goBack();
        } catch (err) {
            if (err && err.status === 404 && err.data.name === 'OBJECT_NOT_FOUND_EXCEPTION') {
                NotificationManager.error(`This subscriber is no longer available`);
                goBack();
            } else {
                NotificationManager.error('Something wrong happened. Please refresh or try again later');
            }
        }
    }), [goBack, query.id]);

    React.useEffect(() => {
        const checkSubscriberSpaceOwnership = async () => {
            const result = await apiCheckSubscriberSpaceOwnership(query.id);
            setSpaceOwnership(result);
        }
        checkSubscriberSpaceOwnership();
    }, [query.id]);

    return (
        <FormDialog
            open
            title={'Delete Subscriber'}
            closeDialog={goBack}
            buttons={[
                { text: 'Delete', type: 'ok', onClick: handleSubmit },
                { text: 'Cancel', type: 'cancel' }
            ]}
        >
            <div>
                Do you want delete the selected subscriber ?
            </div>
            <br />
            {
                spaceOwnership > 0 &&
                <Warning>This subscriber is owner of {spaceOwnership} space(s), if you delete it all the spaces will be deleted</Warning>
            }
        </FormDialog>
    );
};

export default DeleteSubscriber;
