import { Field } from 'formik';
import React from 'react';
import MaterialInput from '@material-ui/core/Input';

const TextInput = ({ name, ...props}) => (
  <Field name={name}>
    {({ field, form }) => (
      <MaterialInput {...field} {...props} error={form.touched[name] && !!form.errors[name]} />
    )}
  </Field>
);

export default TextInput;
