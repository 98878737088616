import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import MaterialIcon from "@material/react-material-icon";

const Wrapper = styled.div`
  position: relative;
  padding: 10px;
  &:hover {
    background-color: #ddd;
    border-radius: 50%;
    
    & > .Tooltip {
      display: block;
    }
  }
`;

const LinkWrapper = styled(Link)`
  position: relative;
  padding: 10px;

  &:hover {
    background-color: #ddd;
    border-radius: 50%;

    & > .Tooltip {
      display: block;
    }
  }
`;

const Icon = styled(MaterialIcon)`
  font-size: 18px;
  padding: 5px;
  color: #fff;
  background-color: ${({ color = '#0078D4' }) => color};
  box-shadow: 0 0 1px #333;
  border-radius: 12px;
  cursor: pointer;

  &:active {
    transform: scale(1.05);
  }
`;

const Tooltip = styled.div`
  position: absolute;
  display: none;
  color: #fff;
  top: 41px;
  left: -6px;
  font-size: 10px;
  background-color: #666;
  padding: 2px 5px;
  width: 68px;
  text-align: center;
`;

const TooltipButton = ({ icon, to, disabled, onClick, text, isLink, color, ...props }) => {
  if (!isLink) {
    return (
      <Wrapper {...props}>
        <Icon disabled={disabled} icon={icon} onClick={onClick} color={color}/>
        <Tooltip className="Tooltip">{text}</Tooltip>
      </Wrapper>
    );
  } else {
    return (
      <LinkWrapper to={to}>
        <Icon disabled={disabled} icon={icon} onClick={onClick} color={color}/>
        <Tooltip className="Tooltip">{text}</Tooltip>
      </LinkWrapper>
    )
  }
};

TooltipButton.propTypes = {
  onClick: PropTypes.func
};

TooltipButton.defaultProps = {
  isLink: false
};

export default TooltipButton;
