import apiClientRequest from '../../common/apiclient';

export const apiCreateSubscriber = async ({
  firstName, lastName, email, companyName, phone, line1, line2, city, country, zip,
}) =>
  apiClientRequest('/create-subscriber', {
    method: 'POST',
    data: {
      firstName, lastName, email, companyName, phone, line1, line2, city, country, zip,
    },
  });
