import React, { useCallback, useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import SubscriptionPlanDialog from '../../../common/components/SubscriptionPlanDialog';
import { apiEditSubscriptionPlan, getSubscriptionPlanById } from '../api';
import useGoBack from '../../../common/hooks/use-go-back';
import { useQueryMap } from '../../../common/hooks/use-query';
import useAsyncOperation from '../../../common/hooks/use-async-operation';

const EditSubscriptionPlanContainer = () => {
  const queryMap = useQueryMap();
  const goBack = useGoBack();
  const [asyncEditSubscriptionPlan, subscriptionPlanUpdate] = useAsyncOperation(apiEditSubscriptionPlan);
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);
  const { subscriptionPlanId } = queryMap;

  const handleUpdateSubscriptionPlan = useCallback(async (update) => {
    try {
      await asyncEditSubscriptionPlan(subscriptionPlanId, update);

      NotificationManager.success(`${update.name} has been successfully updated`);
      goBack();
    } catch (err) {
      console.error(err);
      NotificationManager.error('Failed to update subscription plan. Please refresh or try again');
    }
  }, [goBack, subscriptionPlanId, subscriptionPlan, asyncEditSubscriptionPlan]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const result = await getSubscriptionPlanById(subscriptionPlanId);
        let period = 1;
        let unit = 'days';

        if (Array.isArray(result.defaultBillingPlans) && result.defaultBillingPlans) {
          period = result.defaultBillingPlans[0].period;
          unit = result.defaultBillingPlans[0].unit;
        }

        setSubscriptionPlan({
          ...result,
          period,
          unit,
        });
      } catch (err) {
        console.error(err);
        NotificationManager.error('Failed to retrieve subscription plan. Please refresh or try again');
      }
    };

    fetch();
  }, [subscriptionPlanId]);

  return (
    <SubscriptionPlanDialog
      editMode
      title="Edit Subscription Plan"
      loading={subscriptionPlanUpdate.loading}
      initialValues={subscriptionPlan}
      onSave={handleUpdateSubscriptionPlan}
      onCancel={goBack}
    />
  );
};
export default EditSubscriptionPlanContainer;
