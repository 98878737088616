import apiClientRequest from '../../common/apiclient';

export const apiGetGoogleAnalyticsID = () => apiClientRequest(`/settings/google-analytics`, {
    method: 'GET',
});

export const apiCreateOrUpdateGoogleAnalyticsSettings = ({ googleAnalyticsId, disabled }) => apiClientRequest(`/settings/google-analytics`, {
    method: 'POST',
    data: { googleAnalyticsId, disabled },
});

export const apiGetGoogleTagsID = () => apiClientRequest(`/settings/google-tags`, {
    method: 'GET',
});

export const apiCreateOrUpdateGoogleTagsSettings = ({ googleTagsId, disabled }) => apiClientRequest(`/settings/google-tags`, {
    method: 'POST',
    data: { googleTagsId, disabled },
});
