import { useCallback, useRef, useState } from 'react';

export const useDeleteItem = (asyncDeleteItem) => {
  const ref = useRef();
  const [ loading, setLoading ] = useState(false);
  const [ success, setSuccess ] = useState(false);
  const [ error, setError ] = useState(false);
  const [ selectedItem, setSelectedItem ] = useState(null);
  const onDeleteClick = useCallback((data) => {
    ref.current.open();
    setSelectedItem(data);
  }, []);

  const onDeleteItem = async () => {
    try {
      setLoading(true);
      setSuccess(false);
      const result = await asyncDeleteItem({id: selectedItem.id});
      setSuccess(true);
      return result;
    } catch (err) {
      setError(err);
    } finally {
      ref.current.close();
      setLoading(false);
    }
    
  };
  return [
    ref,
    onDeleteClick,
    onDeleteItem,
    selectedItem,
    loading,
    success,
    error
  ];
};

