import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import MaterialIcon from '@material/react-material-icon';

const TableContainer = styled.div`
  background-color: ${({ bordered = false }) => bordered ? '#fdfdfd' : 'inherit'};
  border: ${({ bordered = false }) => bordered ? '1px solid #dedede' : 'none'};
  padding: 10px 0;
`;

export const TableWrapper = styled.table`
  width: 100%;
  background-color: inherit;
  border-collapse: collapse;
  
  thead {
    font-weight: bold;
    background-color: inherit;

    th {
      padding: 15px 7px;
      font-weight: bold;
      font-family: "Open Sans", sans-serif;
      border-bottom: 1px solid #ccc;
      text-align: center;
      color: #0078D4;
      font-size: 14px;
      
      &.text-cell, &.leftAlign{
        text-align: left;
      }
      
      &.date-cell, &.centerAlign {
        text-align: center;
      }
      
      &.number-cell, &.rightAlign {
        text-align: right;
      }
    }
    
    th:first-of-type, td:first-of-type {
      padding-left: 20px;
    }
  
    th:last-of-type, td:last-of-type {
      padding-right: 20px;
    }
  }
   
   tbody {
    td, th {
      padding: 15px 7px;
      font-family: "Open Sans", sans-serif;
      border-bottom: 1px solid #ccc;
      font-size: 14px;
      text-align: center;
            
      &.text-cell, &.leftAlign{
        text-align: left;
      }
      
      &.date-cell, &.centerAlign {
        text-align: center;
      }
      
      &.number-cell, &.rightAlign {
        text-align: right;
      }
    }
    
    tr {
      th:last-of-type, td:last-of-type {
        div {
          justify-content: center; // TODO: remove
        }
      }
    }
    
    ${({ stripped = true }) => stripped ? `
    tr:nth-of-type(even) {
      background-color: #fafafa;
    }`: ''}
    
    th:first-of-type, td:first-of-type {
      padding-left: 20px;
    }
  
    th:last-of-type, td:last-of-type {
      padding-right: 20px;
    }
     
    tr:last-of-type {
      td, th {
        border-bottom: 0;
      }
    }
  }
`;

export const TableEmpty = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
`;

export const Table = ({ children, isEmpty = false, isEmptyComponent = null, bordered = true, stripped = true, ...props }) => {
  const childrenCount = React.Children.count(children);
  const childrenArray = React.Children.toArray(children);
  const tableHead = childrenCount > 0 && childrenArray[0];
  const tableBody = childrenCount > 1 && childrenArray.slice(1);

  return (
    <TableContainer bordered={bordered ? 1 : 0} {...props}>
      <TableWrapper stripped={stripped}>
        { tableHead }
        { isEmpty === false && tableBody }
      </TableWrapper>
      {
        isEmpty === true && (
          <TableEmpty>
            { isEmptyComponent }
          </TableEmpty>
        )
      }
    </TableContainer>
  );
};

export const TableFooter = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	padding: 15px;
`;

const ToggleActionIcon = styled(MaterialIcon)`
  color: #0078D4;
  cursor: pointer;
  font-size: 24px;
`;

const TableActionsWrapper = styled.div`
  position: relative;
  width: 40px;
  margin: 0 auto;
`;

const ActionsWrapper = styled.div`
  position: absolute;
  top: -10px;
  left: -100px;
  display: flex;
  flex-direction: row;
  
  &:after {
    content: ' ';
    background-color: #fff;
    width: 15px;
    height: 15px;
    border: 1px solid #ccc;
    transform: rotate(45deg);
    border-bottom: 0;
    border-left: 0;
    position: relative;
    left: -7px;
    top: 10px;
    z-index: 2;
  }
`;

const ActionsContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #ccc;
  width: 100px;
`;

export const TableActions = ({ children }) => {
  const [showActions, setShowActions ] = useState(false);
  const toggleShowActions = () => {
    setShowActions(!showActions);
  };

  return (
    <TableActionsWrapper>
      <ToggleActionIcon icon="more_vert" onClick={toggleShowActions} />
      {
        showActions && (
          <ActionsWrapper>
            <ActionsContent>
              {
                React.Children.map(children, (child) => (
                  <div onClick={toggleShowActions}>
                    { child }
                  </div>
                ))
              }
            </ActionsContent>
          </ActionsWrapper>
        )
      }
    </TableActionsWrapper>
  )
};

const ActionIcon = styled(MaterialIcon)`
  color: #777;
  font-size: 21px;
  margin-right: 10px;
`;

const ActionWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 3px;
  
  :hover {
    background-color: #efefef;
  }
`;

const ActionTitle = styled.span`
  text-transform: lowercase;
  font-size: 14px;
`;

const ActionLink = styled(Link)`
  color: inherit;
  display: block;
  &:visited {
    color: inherit;
  }
`;

const TableLargeActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const TableActionCell = ({ children, ...props }) => (
  <td {...props}>
    <TableLargeActionsWrapper>
      { children }
    </TableLargeActionsWrapper>
  </td>
);

export const TableAction = ({ icon, title, onClick, isLink, to }) => {
  const Container = isLink ? (props) => (
    <ActionLink to={to} {...props} />
  ) : React.Fragment;

  return (
    <Container>
      <ActionWrapper onClick={onClick}>
        <ActionIcon icon={icon} />
        <ActionTitle>{title}</ActionTitle>
      </ActionWrapper>
    </Container>
  )
};
