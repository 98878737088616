import React from 'react';
import DepSelect2 from 'react-select';

const Select2 = (props) => (
  <DepSelect2
    isClearable
    isSearchable
    {...props}
  />
);

export default Select2;
