import React, { useCallback } from 'react';
import styled from 'styled-components';
import { ErrorMessage as FormikErrorMessage } from 'formik';
import ErrorMessage from '../ErrorMessage';

const Wrapper = styled.div`
  margin: 4px;
  height: 12px;
`;

export const CustomErrorMessage = ({ className, ...props }) => {
  const renderError = useCallback(msg => {
    return (
      <ErrorMessage className={className}>{msg}</ErrorMessage>
    );
  }, [props.className]);

  return (
    <Wrapper>
      <FormikErrorMessage
        {...props} render={renderError}
      />
    </Wrapper>
  );
};
