import React from 'react';
import csc from 'country-state-city';
import Select from './Select';

const allCountries = csc.getAllCountries();

const CountryInput = ({ onChange, value, ...props }) => {
  const handleCountryChange = React.useCallback(evt => {
    const { value } = evt.target;
    onChange(value);
  }, []);

  React.useEffect(() => {
    if (value && !allCountries.find(country => country.sortname === value)) {
      onChange('');
    }
  }, []);

  return (
    <Select onChange={handleCountryChange} defaultValue={value} {...props}>
      <option value="">Select a country</option>
      {
        allCountries.map(country => (
          <option key={country.id} value={country.sortname}>
            { country.name }
          </option>
        ))
      }
    </Select>
  )
};

export default CountryInput;
