import { API_ACCESS_TOKEN } from "../../../common/constants";

export const registerJwt = global => {
  if (
    global.loginData &&
    global.loginData.accessToken !== localStorage.getItem(API_ACCESS_TOKEN)
  ) {
    localStorage.setItem(API_ACCESS_TOKEN, global.loginData.accessToken);
    return null;
  }

  return null;
};
