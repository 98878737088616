import React from 'react';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { submitOnce } from '../../../common/lib/formik-helpers';
import FormGroup from '../../../ui/FormGroup';
import Label from '../../../ui/Label';
import FormikSelect2 from '../../../ui/FormikSelect2';
import FormDialog from '../../../ui/FormDialog';
import FormikTextInput from '../../../ui/FormikTextInput';

const validationSchema = yup.object({
  salesman: yup.string().required('Please choose one')
});

const SubscriberSalesmanAssignmentDialog = ({ assignSubscriber, oldSalesman, salesmen, cancel }) => {
  const submit = React.useCallback(submitOnce((values) => {
    return assignSubscriber({ salesman: values.salesman.value });
  }), [assignSubscriber]);

  const salesmenOptions = React.useMemo(() => {
    return salesmen.map(({ _id, firstName, lastName }) => ({
      label: `${firstName} ${lastName}`,
      value: _id,
    }));
  }, [salesmen]);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        salesman: '',
        oldSalesman: oldSalesman || 'Unassigned',
      }}
      onSubmit={submit}
      enableReinitialize
    >
      {
        ({ handleSubmit }) => (
          <FormDialog
            open
            closeDialog={cancel}
            title="Subscriber"
            buttons={[
              { text: 'Ok', type: 'ok', onClick: handleSubmit },
              { text: 'Cancel', type: 'cancel' },
            ]}
          >
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label htmlFor="oldSalesman">Old Salesman</Label>
                <FormikTextInput name="oldSalesman" readOnly />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="salesman">
                  Salesman
                </Label>
                <FormikSelect2
                  placeholder="Please choose a salesman"
                  name="salesman"
                  menuPortalTarget={document.querySelector('.MuiDialog-container')}
                  options={salesmenOptions}
                />
              </FormGroup>
            </Form>
          </FormDialog>
        )
      }
    </Formik>
  );
};

export default SubscriberSalesmanAssignmentDialog;
