import React from 'react';
import styled from 'styled-components';
import InputPopover from './InputPopover';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  justify-content: flex-start;
  align-items: center;
`;

const StyledSelect = styled.select`
  border: 1px solid #ccc;
  padding: 7px 10px;
  border-radius: 10px;
  color: #333;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;

  ${({ fill = false}) => fill ? 'width: 100%;' : '' }
  ${({ hasError = false }) => hasError ? 'border-color: #cc0000 !important;' : '' }
  ${({ isValid = false }) => isValid ? 'border-color: #00cc00!important;': '' }
  
  &:focus {
    outline: none;
    box-shadow: 0 0 1px #777;
  }
`;

const StyledInputPopover = styled(InputPopover)`
  position: absolute;
  right: 0;
`;

const SelectWithPopover = ({ fill, popoverText, hasError, isValid, ...props }) => (
  <Wrapper>
    <StyledSelect fill={fill ? 1 : 0 } hasError={hasError ? 1 : 0} isValid={isValid ? 1 : 0} {...props} />
    {
      popoverText && (
        <StyledInputPopover popoverText={popoverText} />
      )
    }
  </Wrapper>
);

export default SelectWithPopover;
