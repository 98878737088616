import React from 'react';
import debounce from 'lodash/debounce';
import { ContentNavBar, NavBarActions } from '../../../ui/ContentNavBar';
import { Table, TableFooter, TableActionCell } from '../../../ui/Table';
import LargeBox from '../../../ui/LargeBox';
import Pagination from '../../../ui/Pagination';
import TooltipButton from '../../../ui/TooltipButton';
import TooltipLink from '../../../ui/TooltipLink';
import SearchClearableInput from '../../../common/components/SearchClearableInput';
import moment from 'moment';
import MessageDialog from '../../../ui/MessageDialog';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { NotificationManager } from 'react-notifications';
import { apiChangeOwner } from '../api';
import useGoBack from '../../../common/hooks/use-go-back';
import {
    useAddPropertiesToQueryString,
} from '../../../common/hooks/use-query';
import {
    useQueryMap
} from '../../../common/hooks/use-query';


const SpaceUsersList = ({ users, onUnsubscribeUserFromSpace, match }) => {

    const [open, setOpen] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState(false);
    const query = useQueryMap();
    const { owner } = query;
    const spaceUsers = users.filter(user => user.email !== owner);

    const OnUnsubscribeOpenDialog = (user) => {
        setOpen(true);
        setSelectedUser(user);
    }

    const OnUnsubscribeAction = () => {
        if (match.params.spaceId && selectedUser) {
            onUnsubscribeUserFromSpace(match.params.spaceId, selectedUser);
            setOpen(false);
            setSelectedUser(false);
        }
    }

    return (
        <LargeBox heading="Spaces users">
            <ContentNavBar></ContentNavBar>
            <Table isEmpty={spaceUsers.length === 0} isEmptyComponent="There are no users in space.">
                <thead>
                    <tr>
                        <th className="text-cell">First name</th>
                        <th className="text-cell">Last name</th>
                        <th className="text-cell">Email</th>
                        <th className="text-cell">Created At</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        spaceUsers.map((user, key) => (
                            <tr key={key}>
                                <td className="text-cell">{user.firstName}</td>
                                <td className="text-cell">{user.lastName}</td>
                                <td className="text-cell">{user.email}</td>
                                <td className="text-cell">{moment(user.createdAt).format('YYYY-DD-MM HH:mm')}</td>
                                <TableActionCell>
                                    <TooltipButton
                                        icon="unsubscribe"
                                        text="Unsubscribe user from space"
                                        onClick={() => OnUnsubscribeOpenDialog(user._id)}
                                    />
                                </TableActionCell>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
            <MessageDialog
                open={open}
                title="Unsubscribe the user from space"
                centertitle="true"
                closeDialog={() => {
                    setOpen(false);
                }}
                buttons={[
                    { text: 'Yes', type: 'ok', onClick: OnUnsubscribeAction },
                    { text: 'Cancel', type: 'cancel' },
                ]}
            >
                Do you want unsubscribe the selected user from this space ?
            </MessageDialog>
        </LargeBox>
    );
};

export default SpaceUsersList;
