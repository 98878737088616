import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import AppBar from "@material-ui/core/AppBar";
import AccountBox from "./AccountBox";
import { Transition } from "react-transition-group";
import { MQ_LARGE_DOWN } from '../../../common/breakpoints';

const Wrapper = styled(AppBar)`
  &.header {
    height: 50px;
    z-index: 1;
    width: ${({ animationstate }) => {
      switch (animationstate) {
        case "entered":
          return "calc(100% - 240px)";
        case "exited":
          return "calc(100% - 50px)";
        case "entering":
          return "calc(100% - 50px)";
        case "exiting":
          return "calc(100% - 240px)";
        default:
          return "calc(100% - 240px)";
      }
    }};

    @media ${MQ_LARGE_DOWN} {
      width: calc(100% - 50px);
    }
    position: fixed;
    top: 0;
    right: 0;
    background-color: #fefefe;
    box-shadow: 1px 0 2px #999;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-left: 40px;
  }
`;

const Header = ({ extended, onLogoutClick, user }) => (
  <Transition in={extended} timeout={0}>
    {animationState => (
      <Wrapper
        classes={{
          root: "header"
        }}
        animationstate={animationState}
      >
        <AccountBox user={user} onLogoutClick={onLogoutClick} />
      </Wrapper>
    )}
  </Transition>
);

Header.propTypes = {
  extended: PropTypes.bool
};

Header.defaultProps = {
  extended: false
};

export default Header;
