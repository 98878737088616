import React from 'react';
import MessageDialog from '../../ui/MessageDialog';
import useSubmitOnce from '../../common/hooks/use-submit-once';
import FIX_NAME from '../SystemFixesList/models/fix-name';
import SectionLoader from '../../ui/SectionLoader';

const ConfirmFixExecution = ({ fix, loading, submit, cancel }) => (
  <MessageDialog
    open
    title={`${FIX_NAME[fix.name]} Fix`}
    closeDialog={cancel}
    buttons={[
      { text: 'Yes', type: 'ok', onClick: useSubmitOnce(submit) },
      { text: 'No', type: 'cancel' }
    ]}
  >
    { loading && <SectionLoader /> }
    Are you sure you want to run the {FIX_NAME[fix.name]} Fix ?
  </MessageDialog>
);

export default ConfirmFixExecution;
